import { useEffect, useRef, memo } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import chroma from "chroma-js";
import womanMask from "../assets/newScenes/textures/pixels2pixelsgirl.webp";
import { EffectComposer } from "three/addons/postprocessing/EffectComposer.js";
import { RenderPass } from "three/addons/postprocessing/RenderPass.js";
import { isPortrait, isPhone } from "../util/DeviceType";

export let womanMaskMesh;
export let threeManager;
export default memo(
  ({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, handleMaskFade, ...rest }) => {
    const reactCanvas = useRef(null);

    let renderer;
    let scene;
    let camera;
    let mousePosition = new THREE.Vector3();
    let cameraCtrl;
    let mouse = new THREE.Vector2();
    let raycaster = new THREE.Raycaster();
    let mixerarr = [];
    let geometries = [];
    let clock = new THREE.Clock();
    let geometryDodecahedronGeometry;
    let composer, iMesh;
    let pointLight;
    let cscale = chroma.scale(["#dd3e1b", "#0b509c"]);
    let mousePlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
    let textureAtlas1;
    let geometryGlow1;
    let materialGlow1;
    let mouseOver;
    let NUM_INSTANCES = 1000;
    let instances = [];
    let { randFloat: rnd, randFloatSpread: rndFS } = THREE.MathUtils;
    let unmounted = true;
    let materialShader;
    let meshphongFragBody, meshphongFragHead;

    useEffect(() => {
      const { current: canvas } = reactCanvas;

      sceneOptions = {
        useGeometryUniqueIdsMapSearch: true,
        useMaterialMeshMapSearch: true,
        useClonedMeshMap: true,
      };
      if (!canvas) return;
      unmounted = false;
      // create a new WebGL renderer
      renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x00ff00, 0);
      renderer.alpha = true;
      renderer.antialias = true;
      scene = new THREE.Scene();
      // scene.background = null;

      // create a new camera
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, 0, 5);
      creatingAssets();
      cameraConfig();
      threeManager = {
        activeScene: scene,
        transitionScene: null,
      };
      function animate() {
      }

      animate();

      const resize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        if (isPhone()) {
          renderer.setSize(window.outerWidth, window.outerHeight);
        } else {
          renderer.setSize(width, height);
        }
      };

      function randomColors() {
        const c1 = chroma.random(),
          c2 = chroma.random();
        cscale = chroma.scale([c1, c2]);
        updateColors();
      }

      function updateColors() {
        const colors = [];
        for (let i = 0; i < NUM_INSTANCES; i++) {
          const color = new THREE.Color(cscale(rnd(0, 1)).hex());
          colors.push(color.r, color.g, color.b);
        }
        iMesh.geometry.setAttribute("color", new THREE.InstancedBufferAttribute(new Float32Array(colors), 3));
      }

      function creatingAssets() {
        textureAtlas1 = new THREE.TextureLoader().load(womanMask);
        geometryGlow1 = new THREE.PlaneGeometry(2.048, 3.456);
        materialGlow1 = new THREE.MeshBasicMaterial({ map: textureAtlas1, transparent: true, opacity: 1 });
        womanMaskMesh = new THREE.Mesh(geometryGlow1, materialGlow1);
        womanMaskMesh.scale.set(80, 80, 0);
        womanMaskMesh.position.set(0, -90, 120);

        if (isPortrait()) {
          womanMaskMesh.scale.set(80, 80, 0);
          womanMaskMesh.position.set(0, -90, 120);
        } else {
          womanMaskMesh.scale.set(80, 80, 0);
          womanMaskMesh.position.set(0, -90, 120);
        }
        scene.add(womanMaskMesh);

        cometsFlying(camera, scene, renderer);
      }

      function cometsFlying(p_camera, p_scene, p_renderer) {
        let camera = p_camera;
        let scene = p_scene;
        let renderer = p_renderer;
        mouseOver = false;


        let target = new THREE.Vector3();
        const dummy = new THREE.Object3D();
        const dummyV = new THREE.Vector3();

        init();
        function init() {
          initScene();
          initPostprocessing();
          animate();
        }
        function initScene() {
          pointLight = new THREE.PointLight(0xffc0c0);
          scene.add(pointLight);
          geometryDodecahedronGeometry = new THREE.DodecahedronGeometry(5);
          materialShader = new SubSurfaceMaterial();
          iMesh = new THREE.InstancedMesh(geometryDodecahedronGeometry, materialShader, NUM_INSTANCES);
          scene.add(iMesh);

          for (let i = 0; i < NUM_INSTANCES; i++) {
            instances.push({
              position: new THREE.Vector3(rndFS(200), rndFS(200), rndFS(200)),
              scale: rnd(0.6, 1.2),
              velocity: new THREE.Vector3(rndFS(2), rndFS(2), rndFS(2)),
              attraction: 0.0025 + rnd(0, 0.01),
              vlimit: 0.3 + rnd(0, 0.2),
            });
          }

          for (let i = 0; i < NUM_INSTANCES; i++) {
            const { position, scale } = instances[i];
            dummy.position.copy(position);
            dummy.scale.set(scale, scale, scale);
            dummy.updateMatrix();
            iMesh.setMatrixAt(i, dummy.matrix);
          }

          iMesh.instanceMatrix.needsUpdate = true;
          handleMaskFade(false);

          updateColors();
          document.body.addEventListener("mousemove", onMouseMove, false);
          document.body.addEventListener("click", randomColors);
          document.body.addEventListener("mouseleave", function (event) {
            mousePosition = new THREE.Vector3();
            mouseOver = false;
          });
        }

        function initPostprocessing() {
          composer = new EffectComposer(renderer);

          const renderPass = new RenderPass(scene, camera);
          composer.addPass(renderPass);
        }

        function animate() {
          if (pointLight && renderer) {
            requestAnimationFrame(animate);
            if (mouseOver && mouse) {
              target.copy(mousePosition);
              pointLight.position.copy(target);
            } else {
              target = new THREE.Vector3();
              pointLight.position.copy(target);
            }

            for (let i = 0; i < NUM_INSTANCES; i++) {
              const { position, scale, velocity, attraction, vlimit } = instances[i];

              dummyV
                .copy(target)
                .sub(position)
                .normalize()
                .multiplyScalar(!mouseOver ? attraction : attraction * 4);
              velocity.add(dummyV).clampScalar(!mouseOver ? -vlimit : -vlimit * 3, !mouseOver ? vlimit : vlimit * 3);
              position.add(velocity);

              dummy.position.copy(position);
              dummy.scale.set(scale, scale, scale);
              dummy.lookAt(dummyV.copy(position).add(velocity));
              dummy.updateMatrix();
              iMesh.setMatrixAt(i, dummy.matrix);
            }
            iMesh.instanceMatrix.needsUpdate = true;

            // if (cameraCtrl) cameraCtrl.update();
            composer.render();
          }
        }

        function SubSurfaceMaterial() {
          meshphongFragHead = THREE.ShaderChunk.meshphong_frag.slice(
            0,
            THREE.ShaderChunk.meshphong_frag.indexOf("void main() {")
          );
          meshphongFragBody = THREE.ShaderChunk.meshphong_frag.slice(
            THREE.ShaderChunk.meshphong_frag.indexOf("void main() {")
          );

          return new THREE.ShaderMaterial({
            lights: true,
            vertexColors: true,
            flatShading: true,
            uniforms: THREE.UniformsUtils.merge([
              THREE.ShaderLib.phong.uniforms,
              {
                // diffuse: { value: new Color(0xffffff) },
                thicknessColor: { value: new THREE.Color(0x808080) },
                thicknessDistortion: { value: 0.4 },
                thicknessAmbient: { value: 0.01 },
                thicknessAttenuation: { value: 0.7 },
                thicknessPower: { value: 2.0 },
                thicknessScale: { value: 4.0 },
              },
            ]),

            vertexShader: `
                  #define USE_UV
                  ${THREE.ShaderChunk.meshphong_vert}
                `,

            fragmentShader:
              `
                  #define USE_UV
                  #define SUBSURFACE
            
                  ${meshphongFragHead}
            
                  uniform float thicknessPower;
                  uniform float thicknessScale;
                  uniform float thicknessDistortion;
                  uniform float thicknessAmbient;
                  uniform float thicknessAttenuation;
                  uniform vec3 thicknessColor;
            
                  void RE_Direct_Scattering(const in IncidentLight directLight, const in vec2 uv, const in GeometricContext geometry, inout ReflectedLight reflectedLight) {
                    #ifdef USE_COLOR
                      vec3 thickness = vColor * thicknessColor;
                    #else
                      vec3 thickness = thicknessColor;
                    #endif
                    vec3 scatteringHalf = normalize(directLight.direction + (geometry.normal * thicknessDistortion));
                    float scatteringDot = pow(saturate(dot(geometry.viewDir, -scatteringHalf)), thicknessPower) * thicknessScale;
                    vec3 scatteringIllu = (scatteringDot + thicknessAmbient) * thickness;
                    reflectedLight.directDiffuse += scatteringIllu * thicknessAttenuation * directLight.color;
                  }
                ` +
              meshphongFragBody.replace(
                "#include <lights_fragment_begin>",
                replaceAll(
                  THREE.ShaderChunk.lights_fragment_begin,
                  "RE_Direct( directLight, geometry, material, reflectedLight );",
                  `
                      RE_Direct( directLight, geometry, material, reflectedLight );
                      #if defined( SUBSURFACE ) && defined( USE_UV )
                        RE_Direct_Scattering(directLight, vUv, geometry, reflectedLight);
                      #endif
                    `
                )
              ),
          });

          function replaceAll(string, find, replace) {
            return string.split(find).join(replace);
          }
        }
      }

      function onMouseMove(event) {
        if (unmounted) {
          document.body.removeEventListener("mousemove", onMouseMove, false);
          document.body.removeEventListener("click", randomColors);
          document.body.removeEventListener("mouseleave", function (event) {
            mousePosition = new THREE.Vector3();
            mouseOver = false;
          });
        }
        if (mousePlane) {
          mouseOver = true;

          var v = new THREE.Vector3();
          camera.getWorldDirection(v);
          v.normalize();
          mousePlane.normal = v;

          mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
          mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
          raycaster.setFromCamera(mouse, camera);
          raycaster.ray.intersectPlane(mousePlane, mousePosition);
        }
      }

      function cameraConfig() {
        camera.position.z = 220;
        cameraCtrl = new OrbitControls(camera, renderer.domElement);
        cameraCtrl.enabled = false;
        cameraCtrl.enableDamping = true;
        cameraCtrl.dampingFactor = 0.1;
        cameraCtrl.autoRotate = true;
        cameraCtrl.autoRotateSpeed = 0.5;
        cameraCtrl.rotateSpeed = 0.5;
        cameraCtrl.enableZoom = false;
        cameraCtrl.enableRotate = true;
        cameraCtrl.enablePan = false;
        if (cameraCtrl) cameraCtrl.update();
        if (renderer) renderer.render(scene, camera);
      }
      if (window) {
        window.addEventListener("resize", resize);
      }

      return () => {
        unmounted = true;

        if (renderer.attributes) renderer.attributes.remove(iMesh.instanceMatrix)
        if (geometryDodecahedronGeometry) geometryDodecahedronGeometry.dispose();
        materialShader.dispose();
        instances = [];
        scene.remove(iMesh);
        scene.remove(pointLight);
        scene.remove(womanMaskMesh);
        iMesh = null;

        if (geometryGlow1) geometryGlow1.dispose();
        if (textureAtlas1) textureAtlas1.dispose();
        if (materialGlow1) materialGlow1.dispose();
        if (materialShader) materialShader.dispose();

        if (composer) composer.dispose();
        if (pointLight) pointLight.dispose();
        if (scene.current && camera.current) {
          scene.current.dispose();
          camera.current.dispose();
        }
        if (renderer.current) {
          renderer.current.dispose();
        }

        if (window) {
          window.removeEventListener("resize", resize);
        }

        document.body.removeEventListener("click", randomColors);
        document.body.removeEventListener("mousemove", onMouseMove, false);

        renderer = null;
        scene = null;
        camera = null;
        pointLight = null;
        womanMaskMesh = null;
        materialGlow1 = null;
        mousePlane = null;

        reactCanvas.current = null;

      };
    }, []);

    return (
      <div>
        <canvas ref={reactCanvas} {...rest}></canvas>
      </div>
    );

  }
);
