import { useEffect, useRef, memo } from "react";
import { isPhone } from "../util/DeviceType";

export default memo(({}) => {
  const canvasRef2D = useRef(null);
  let animate = true;
  let stars = [];
  let star;
  let i;
  let canvas;
  let c;

  useEffect(() => {
    canvas = canvasRef2D.current;
    if (!canvas) {
      return;
    }

    createStarrySky();

    function createStarrySky() {
      c = canvas.getContext("2d");

      let numStars = 1900;
      let radius = "0." + Math.floor(Math.random() * 9) + 1;
      let focalLength = canvas.width * 2;
      let warp = 0;
      let centerX, centerY;

      let isWarping = false;

      initializeStars();

      function executeFrame() {
        if (animate) requestAnimationFrame(executeFrame);
        moveStars();
        drawStars();
      }

      function initializeStars() {
        centerX = canvas.width / 2;
        centerY = canvas.height / 2;

        stars = [];
        for (i = 0; i < numStars; i++) {
          star = {
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            z: Math.random() * canvas.width,
            o: "0." + Math.floor(Math.random() * 99) + 1,
          };
          stars.push(star);
        }
      }

      function moveStars() {
        if (!star) return;
        for (i = 0; i < numStars; i++) {
          star = stars[i];
          star.z--;

          if (star.z <= 0) {
            star.z = canvas.width;
          }
        }
      }

      function drawStars() {
        var pixelX, pixelY, pixelRadius;
        if (!canvas) return;

        // Resize to the screen
        if (canvas.width != window.innerWidth || canvas.width != window.innerWidth) {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
          initializeStars();
        }
        if (isWarping == 0) {
          c.fillStyle = "rgba(0,10,20,1)";
          c.fillRect(0, 0, canvas.width, canvas.height);
        }
        c.fillStyle = "rgba(209, 255, 255, " + radius + ")";
        for (i = 0; i < numStars; i++) {
          star = stars[i];

          pixelX = (star.x - centerX) * (focalLength / star.z);
          pixelX += centerX;
          pixelY = (star.y - centerY) * (focalLength / star.z);
          pixelY += centerY;
          pixelRadius = (isPhone() ? 0.5 : 1) * (focalLength / star.z);

          c.fillRect(pixelX, pixelY, pixelRadius, pixelRadius);
          c.fillStyle = "rgba(209, 255, 255, " + star.o + ")";
          //c.fill();
        }
      }

      executeFrame();
    }
    return () => {
      animate = null;
      canvasRef2D.current = null;
      stars = [];
      star = null;
      i = null;
      canvas = null;
      c = null;
    };
  }, []);

  return (
    <>
      <div className="reactCanvas" style={{ position: "absolute", top: "0vh", zIndex: "-50" }}>
        <canvas id="space" ref={canvasRef2D}></canvas>
        <div id="w"></div>
      </div>
    </>
  );
});
