
import { useEffect, useRef, memo } from "react";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import sceneBack from '../assets/newScenes/textures/solutionnew-1.webp';
import back_asset_0 from '../assets/newScenes/textures/changeme.webp'
import back_asset_1 from '../assets/newScenes/textures/hover.webp'
import { isLandscape, isLaptop, isPhone, isPortrait } from "../util/DeviceType";

export let threeManager;
export default memo (({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, textures, ...rest }) => {
    const reactCanvas = useRef(null);


    let renderer;
    let scene;
    let camera;
    let width, height;
    let planet;
    let staticPlanet;
    let material;
    let materialStaticPlanet;
    let earthGlow;
    let cameraCtrl;
    let sphere;
    let pointLight;
    let pointLight2;
    const geometries = [];
    let textureLoaderBack, textureAtlas1, textureAtlas2, textureAtlas3, textureAtlas, textureLoader;
    let materialGlow1, materialGlow2, materialGlow3, materialGlow;
    let geometryGlow1, geometryGlow2, geometryGlow3, geometryGlow;
    let sphereG, sphereY, loaderFbx;
    let earthMenu;
    let earthMenu1;
    let earthMenu2;
    useEffect(() => {
        const { current: canvas } = reactCanvas;

        sceneOptions = {
            useGeometryUniqueIdsMapSearch: true,
            useMaterialMeshMapSearch: true,
            useClonedMeshMap: true,
        };
        if (!canvas) return;

        // create a new WebGL renderer
        renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x00ff00, 0);
        renderer.alpha = true;
        renderer.antialias = true;

        // create a new scene
        scene = new THREE.Scene();
        // scene.background = null;

        // create a new camera
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set(0, 0, 5);

        // add objects to the scene

        creatingAssets();
        cameraConfig();

        threeManager = {
            activeScene: scene,
            transitionScene: null
        };

        function render() {
            if(renderer)renderer.render(scene, camera);
        }

        function animate() {
            if (renderer) {
                requestAnimationFrame(animate);
                render();
            }
        }

        animate();

        const resize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            if(isPhone()){
                renderer.setSize(window.outerWidth, window.outerHeight);
            }else{
                renderer.setSize(width, height);
            }


            if(isPhone){
                if(isPortrait()){
                    // earthMenu.scale.set(550, 550, 0);
                    // earthMenu.position.set(0, 260, -20);
                    scene.background = null;
                    scene.background = textures.sceneBackSolutionsPortraitP;
                    earthMenu2.position.set(950, 20, 20);
                    earthMenu2.position.set(950, 20, 20);
                }else{
                    earthMenu.scale.set(590, 590, 0);
                    earthMenu.position.set(0, 260, -20);
                    earthMenu2.position.set(680, 200, 20);
                    scene.background = null;
                }
            }else{
                scene.background = null;
                earthMenu2.position.set(680, 200, 20);
                earthMenu.scale.set(460, 460, 0);
                earthMenu.position.set(0, 260, -20);
            }
        };

        function creatingAssets() {


            geometryGlow1 = new THREE.PlaneGeometry(5, 1.5);
            materialGlow1 = new THREE.MeshBasicMaterial({ map: textures.sceneBackSolutionsP });
            earthMenu = new THREE.Mesh(geometryGlow1, materialGlow1);
    
            geometryGlow2 = new THREE.PlaneGeometry(4, 2);
            materialGlow2 = new THREE.MeshBasicMaterial({ map: textures.back_asset_0SolutionsP , transparent: true, opacity: 1 });
            earthMenu1 = new THREE.Mesh(geometryGlow2, materialGlow2);
            earthMenu1.scale.set(80, 80, 0);
            earthMenu1.position.set(485, 400, 20);
            scene.add(earthMenu1);
    
            geometryGlow3 = new THREE.PlaneGeometry(4, 1);
            materialGlow3 = new THREE.MeshBasicMaterial({ map: textures.back_asset_1SolutionsP, transparent: true, opacity: 1 });
            earthMenu2 = new THREE.Mesh(geometryGlow3, materialGlow3);
    
            if(isPhone){
                if(isPortrait()){
                    // earthMenu.scale.set(550, 550, 0);
                    // earthMenu.position.set(0, 260, -20);
                    scene.background = textures.sceneBackSolutionsPortraitP;
                    earthMenu2.position.set(950, 20, 20);
                    earthMenu2.position.set(950, 20, 20);
                }else{
                    scene.background = null;
                    earthMenu.scale.set(590, 590, 0);
                    earthMenu.position.set(0, 260, -20);
                    earthMenu2.position.set(680, 200, 20);
                }
            }else{
                earthMenu2.position.set(680, 200, 20);
                earthMenu.scale.set(460, 460, 0);
                earthMenu.position.set(0, 260, -20);
            }
            earthMenu2.scale.set(40, 40, 0);
            scene.add(earthMenu2);
            scene.add(earthMenu);
    
            if (renderer) renderer.render(scene, camera);
    
    
    
        }
    
        function cameraConfig() {
            camera.position.z = 715;
            cameraCtrl = new OrbitControls(camera, renderer.domElement);
            cameraCtrl.enabled = false;
            cameraCtrl.enableDamping = true;
            cameraCtrl.dampingFactor = 0.1;
            cameraCtrl.autoRotate = true;
            cameraCtrl.autoRotateSpeed = 0.5;
            cameraCtrl.rotateSpeed = 0.5;
            if (cameraCtrl) cameraCtrl.update();
            if (renderer) renderer.render(scene, camera);
    
            pointLight = new THREE.PointLight(0xffffff);
            pointLight.position.set(0, 0, 1000);
            scene.add(pointLight);
    
            pointLight2 = new THREE.PointLight(0xffffff);
            pointLight2.position.set(0, 0, -1000);
            scene.add(pointLight2);
            scene.add(new THREE.AmbientLight(0xffffff));
        }


        if (window) {
            window.addEventListener("resize", resize);
        }

        return () => {

            if (materialGlow) materialGlow.dispose();
            if (materialGlow1) materialGlow1.dispose();
            if (materialGlow2) materialGlow2.dispose();
            if (materialGlow3) materialGlow3.dispose();
            if (geometryGlow1) geometryGlow1.dispose();
            if (geometryGlow2) geometryGlow2.dispose();
            if (geometryGlow3) geometryGlow3.dispose();
            if (geometryGlow) geometryGlow.dispose();
            if (loaderFbx) loaderFbx.dispose();
            if (sphereY) sphereY.dispose();
            if (sphereG) sphereG.dispose();

            if (textureLoaderBack) textureLoaderBack.dispose();
            if (textureAtlas) textureAtlas.dispose();
            if (textureAtlas1) textureAtlas1.dispose();
            if (textureAtlas2) textureAtlas2.dispose();
            if (textureAtlas3) textureAtlas3.dispose();
            if (textureLoader) textureLoader.dispose();

            if (scene.current) scene.current.dispose();
            if (camera.current) camera.current.dispose();
            
            if (pointLight2.current && pointLight.current) {
                pointLight.current.dispose();
                pointLight2.current.dispose();
            }
            if (renderer.current) {
                renderer.current.dispose();
            }

            if (window) {
                window.removeEventListener("resize", resize);
            }

            earthMenu = null; earthMenu1 = null; earthMenu2 = null; 
            materialGlow = null; materialGlow1 = null; 
            materialGlow2 = null; materialGlow3 = null; 
            loaderFbx = null; sphereY = null; sphereG = null;
            renderer = null; scene = null; camera = null; pointLight = null; pointLight2 = null;
        };


    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

    return (
        <div>
            <canvas ref={reactCanvas} {...rest} >
            </canvas>
        </div>
    );



})