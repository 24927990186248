import { useState, useEffect, useContext } from "react";

import BabylonManager from "./pages/BabylonManager";
import { TextureContext } from "./context/OnceContext";
import { Fps } from "./pages/Fps";
import { FullscreenHelp } from "./components/FullscreenHelp";
import { Stage, Container, Sprite, Text, useApp, AnimatedSprite, useTick } from "@pixi/react";
import { useMediaQuery } from "@react-hook/media-query";
import * as PIXI from "pixi.js";
import NewContact from "./components/NewContact";
import NewLoadingPage from "./components/NewLoadingPage";
import SoundsPlayer from "./components/SoundsPlayer";
import SoundButton from "./components/SoundButton";
import { className } from "@babylonjs/core";
import { useLocation } from "react-router-dom";

const LoadingBar = ({ handleShowHomepage, setShowPixiCanvas }) => {
  const [textures, setTextures] = useState([]);
  const [sprite, setSprite] = useState(null);
  const app = useApp();

  function setup() {
    app.ticker.add((delta) => fadeOut(delta));
  }
  function fadeOut(delta) {
    sprite.alpha -= 0.01;

    if (sprite.alpha < 0.1) {
      handleShowHomepage();
    }

    if (sprite.alpha < 0) {
      app.ticker.stop();
      setShowPixiCanvas(false);
    }
  }

  useEffect(() => {
    async function loadAssets() {
      const bar = await PIXI.Assets.load("/assets/loadingbar/loadingbar.json");
      let a = [];

      bar.linkedSheets.forEach((element) => {
        a = [...a, ...Object.values(element.textures)];
      });

      setTextures([...Object.values(bar.textures), ...a]);
    }
    loadAssets();
  }, []);

  useEffect(() => {
    if (textures.length) {
      setSprite(app.stage?.children[0]);
    }
  }, [textures]);

  if (textures.length === 0) {
    return null;
  }

  return (
    <AnimatedSprite
      anchor={[0.5, 0.5]}
      textures={textures}
      x={window.outerWidth / 2}
      y={window.outerHeight / 2}
      isPlaying={true}
      initialFrame={0}
      animationSpeed={0.7}
      onFrameChange={(f) => {
        if (f === 157) {
          sprite.stop();
          setTimeout(() => {
            setup();
          }, 1000);
        }
      }}
    />
  );
};

function App() {
  const [showHomepage, setShowHomepage] = useState(true);
  const [showPixiCanvas, setShowPixiCanvas] = useState(true);
  const [textures, setTextures] = useState([]);
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [soundId, setsoundId] = useState({ id: "base" });
  const [stopSoundId, setStopSoundId] = useState({ id: "nan" });
  const [volume, setVolume] = useState(0);

  const handleVolumeChange = (volume) => {
    setVolume(volume);
  };

  const handleSoundIdChange = (newSoundId) => {
    setsoundId({ id: newSoundId });
  };

  const handleStopSoundIdChange = (stopSoundId) => {
    setStopSoundId({ id: stopSoundId });
  };

  const handleShowHomepage = () => {
    setShowHomepage(true);
  };

  const handleClick = () => {
    setClicked(true);
  };

  return (
    <>
      <div onClick={handleClick}>
        <Fps />
        <BabylonManager
          setSounds={handleSoundIdChange}
          setStopSounds={handleStopSoundIdChange}
          setSoundsVolume={handleVolumeChange}
          isClicked={clicked}
        />
        {/* {clicked && <SoundsPlayer soundId={soundId} stopSound={stopSoundId} handleVolume={volume} />} */}
      </div>

      {/* <div className="vignette"></div> */}

      <div className="background-black"></div>
    </>
  );
}

export default App;
