import React from "react"

export class Fps extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fps: window.myFps
        }
        setInterval(() => {
            this.update()
        }, 200);
    }

    update() {
        this.setState({ fps: window.myFps });
    }

    render() {
        return (
            <p id='fps'>
                {this.state.fps} 
            </p>
        );
    }
}
