import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import "../style/textbox.scss";
import { useMediaQuery } from "@react-hook/media-query";

export default function TextBox({
  activeText,
  activeTextContent,
  toggleActiveText,
  noGlassBackground,
  showInterface = true,
}) {
  const [displayAllBlock, setDisplayAllBlock] = useState(false);
  const [displayText, setDisplayText] = useState(false);
  const [startAnimation, setStartAnimation] = useState(false);
  const [emptyArray, setEmptyArray] = useState([...Array(50)]);
  const phoneQuery = useMediaQuery("only screen and (max-width: 481px)");
  const [biggerBox, setNeedsBiggerBox] = useState(false);
  const location = useLocation();

  const tim = useRef(null);
  useEffect(() => {
    if (activeText) {
      calculateBiggerTextbox();
      if (tim.current) {
        clearTimeout(tim.current);
      }
      tim.current = setTimeout(() => {
        setDisplayText(false);
        setStartAnimation(false);
        setTimeout(() => {
          setDisplayAllBlock(false);
        }, 200);
        toggleActiveText(0);
      }, 7000);
      if (!displayAllBlock) {
        setDisplayAllBlock(true);
        setTimeout(() => {
          setStartAnimation(true);
        }, 10);
        setTimeout(() => {
          setDisplayText(true);
          if (showInterface) {
            showCircles();
          }
        }, 500);
      }
    } else if (!activeText && displayAllBlock) {
      if (tim.current) {
        clearTimeout(tim.current);
      }
      setDisplayText(false);
      setStartAnimation(false);
      if (showInterface) {
        hideCircles();
      }

      setTimeout(() => {
        setDisplayAllBlock(false);
      }, 200);
    }
  }, [activeText]);

  function calculateBiggerTextbox() {
    if (phoneQuery) {
      if (location.pathname === "/solutions" && activeText === 3) {
        setNeedsBiggerBox(true);
      } else if (location.pathname === "/business-consulting" && activeText === 2) {
        setNeedsBiggerBox(true);
      } else if (location.pathname === "/business-consulting" && activeText === 4) {
        setNeedsBiggerBox(true);
      } else if (location.pathname === "/solutions" && activeText === 5) {
        setNeedsBiggerBox(true);
      } else {
        setNeedsBiggerBox(false);
      }
    }
  }

  function showCircles() {
    let delay1 = 0;
    let delay2 = 0;
    const circles = document.querySelectorAll(".side-circles");
    const circle1 = circles[0].children;
    const circle2 = circles[1].children;

    Array.from(circle1).forEach((kid) => {
      setTimeout(() => {
        kid.style.visibility = "visible";
      }, delay1 + 100);
      delay1 += 100;
    });
    Array.from(circle2).forEach((kid) => {
      setTimeout(() => {
        kid.style.visibility = "visible";
      }, delay2 + 100);
      delay2 += 100;
    });
  }

  function hideCircles() {
    const circles = document.querySelectorAll(".side-circles");
    const circle1 = circles[0].children;
    const circle2 = circles[1].children;
    Array.from(circle1).forEach((kid) => {
      kid.style.visibility = "hidden";
    });
    Array.from(circle2).forEach((kid) => {
      kid.style.visibility = "hidden";
    });
  }

  return (
    <div
      className={
        displayAllBlock ? (biggerBox ? "textbox textbox-bigger showTextBox" : "textbox showTextBox") : "textbox"
      }
    >
      {showInterface && (
        <>
          <div className={biggerBox ? "dots-wrapper-top dots-wrapper-top-bigger" : "dots-wrapper-top"}>
            {emptyArray &&
              emptyArray.map((el, index) => {
                return (
                  <div key={index} className="dot">
                    {el}
                  </div>
                );
              })}
          </div>
          <div className={biggerBox ? "dots-wrapper-bottom dots-wrapper-bottom-bigger" : "dots-wrapper-bottom "}>
            {emptyArray &&
              emptyArray.map((el, index) => {
                return (
                  <div key={index} className="dot">
                    {el}
                  </div>
                );
              })}
          </div>

          <div className={startAnimation ? "center-line growCenterLine" : "center-line"}></div>
          <div className={startAnimation ? "sideline-left growSideLines" : "sideline-left"}>
            <div className="center-stick"></div>
          </div>
          <div className={startAnimation ? "sideline-right growSideLines" : "sideline-right"}>
            <div className="center-stick"></div>
          </div>
          <div
            className={
              startAnimation
                ? biggerBox
                  ? "left-line left-line-bigger animateLeftLine"
                  : "left-line   animateLeftLine"
                : "left-line"
            }
          ></div>
          <div
            className={
              startAnimation
                ? biggerBox
                  ? "right-line right-line-bigger animateRightLine"
                  : "right-line animateRightLine"
                : "right-line"
            }
          ></div>
          <div className={startAnimation ? "top-line animateHorisontalLine" : "top-line"}></div>
          <div className={startAnimation ? "bottom-line animateHorisontalLine" : "bottom-line"}></div>

          <div className="side-circles side-circles-left">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          <div className="side-circles side-circles-right">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </>
      )}

      {!biggerBox && (
        <div
          className={
            !startAnimation
              ? "textbox-wrapper"
              : noGlassBackground
              ? "textbox-wrapper  growTextBox"
              : "textbox-wrapper growTextBox glass-background"
          }
        >
          <div className="corner-left-top-top"></div>
          <div className="corner-left-top-bottom"></div>
          <div className="corner-left-bottom-top"></div>
          <div className="corner-left-bottom-bottom"></div>
          <div className="corner-right-top-top"></div>
          <div className="corner-right-top-bottom"></div>
          <div className="corner-right-bottom-top"></div>
          <div className="corner-right-bottom-bottom"></div>
        </div>
      )}

      {biggerBox && (
        <div
          className={
            !startAnimation
              ? "textbox-wrapper"
              : noGlassBackground
              ? "textbox-wrapper textbox-wrapper-bigger  growTextBox"
              : "textbox-wrapper textbox-wrapper-bigger growTextBox glass-background"
          }
        >
          <div className="corner-left-top-top"></div>
          <div className="corner-left-top-bottom"></div>
          <div className="corner-left-bottom-top"></div>
          <div className="corner-left-bottom-bottom"></div>
          <div className="corner-right-top-top"></div>
          <div className="corner-right-top-bottom"></div>
          <div className="corner-right-bottom-top"></div>
          <div className="corner-right-bottom-bottom"></div>
        </div>
      )}
      <div className={displayText ? "displayed-text showText" : "displayed-text"}>
        {activeTextContent}
        {displayText && activeText === 2 && location.pathname === "/academy" && !phoneQuery && (
          <ul className="academy-certificates">
            <li className="academy-certificate">
              <img src="/assets/academyCertificates/Autodesk.png" />
            </li>
            <li className="academy-certificate">
              <img src="/assets/academyCertificates/CP_Director.png" />
            </li>
            <li className="academy-certificate">
              <img src="/assets/academyCertificates/CP_Master.png" />
            </li>
            <li className="academy-certificate">
              <img src="/assets/academyCertificates/CP_Officer.png" />
            </li>
            <li className="academy-certificate">
              <img src="/assets/academyCertificates/CP_Professional.png" />
            </li>
            <li className="academy-certificate">
              <img src="/assets/academyCertificates/CP_Trainer.png" />
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}
