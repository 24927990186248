
import { useEffect, useRef, memo } from "react";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { gsap, Power1, Power2, Elastic } from 'gsap';
import fbxPathBuilding from '../assets/newScenes/buildings.fbx';
import { isLandscape, isLaptop, isPhone, isPortrait } from "../util/DeviceType";


export let threeManager;
export default memo(({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, handleMaskFade, textures, models, ...rest }) => {
  const reactCanvas = useRef(null);

  let setCanvasWidth = 1920 / 1.65;
  let setCanvasHeight = 1080 / 1.65;

  let renderer;
  let scene;
  let camera;
  const mouse = new THREE.Vector2();
  const raycaster = new THREE.Raycaster();
  let width, height;
  let planet;
  let staticPlanet;
  let material;
  let materialStaticPlanet;
  let earthGlow;
  let cameraCtrl,ambientLight;

  let startMovingPlanet = true;
  let endMovingPlanet = false;
  let isdragging = false;
  let letUserInteractWithPlanet = false;
  let assetsCreated = false;
  let meshBuildings;
  let meshBuildingsArray = [];
  let sphere;
  let videoTexture, pointLight, pointLight2;
  let geometries = [];

  useEffect(() => {
    const { current: canvas } = reactCanvas;

    sceneOptions = {
      useGeometryUniqueIdsMapSearch: true,
      useMaterialMeshMapSearch: true,
      useClonedMeshMap: true,
    };
    if (!canvas) return;

    // create a new WebGL renderer
    renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setClearColor(0x00ff00, 0);
    renderer.alpha = true;
    renderer.antialias = true;

    // create a new scene
    scene = new THREE.Scene();
    // scene.background = null;

    // create a new camera
    camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(0, 0, 5);

    // add objects to the scene

    creatingAssets();
    cameraConfig();
    addPointerListeners(renderer);

    threeManager = {
      activeScene: scene,
      transitionScene: null
    };

    function render() {
      if (renderer) renderer.render(scene, camera);
    }

    function animate() {
      if (renderer && planet) {
        requestAnimationFrame(animate);
        render();
        planet.rotation.y -= 0.002;
        staticPlanet.rotation.y -= 0.002;
      }
    }

    animate();

    const resize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      camera.aspect = width / height;
      camera.updateProjectionMatrix();

      if (isPhone()) {
        renderer.setSize(window.outerWidth, window.outerHeight);
      } else {
        renderer.setSize(width, height);
      }

    };

    if (window) {
      window.addEventListener("resize", resize);
    }

    function creatingAssets() {

      CrazyPlanet(camera, renderer, scene);
    }
    function CrazyPlanet(camera_p, renderer_p, scene_p) {
      const conf = {
        el: 'canvas',
        fov: 50,
        cameraZ: 5,
        background: 0xffff00
      };
      let camera = camera_p;
      let renderer = renderer_p;
      let scene = scene_p;

      // const randomFloat = THREE.MathUtils.randFloat(-1, 1);
      // const randomInt = THREE.MathUtils.randInt(0, 10);
      const randomFloat = THREE.MathUtils.randFloat(-1, 1);
      const randomInt = THREE.MathUtils.randInt(0, 10);
      const { PI } = Math;

      init(scene);

      function init(scene) {
        //   renderer = new THREE.WebGLRenderer({ canvas: document.getElementById(conf.el), antialias: true, alpha: true });
        //   camera = new THREE.PerspectiveCamera(conf.fov);
        //   camera.position.set(0, conf.cameraZ, conf.cameraZ);
        updateSize();
        // renderer.domElement.addEventListener('resize', updateSize, false);

        initScene(scene);
        animate();
      }

      function initScene(scene) {

        planet = new THREE.Object3D();
        planet.scale.set(0.02, 0.02, 0.02);
        //   planet.position.set(1.55, 0.05, -8.05);
        planet.position.set(0, 0, 0);
        scene.add(planet);

        //   let material  = new THREE.MeshStandardMaterial({ color: 0xcccccc});
        material = new THREE.MeshPhysicalMaterial({ color: 0x3f7b9d });
        //   material.roughness = 0.333;
        material.roughness = 0.65;
        material.metalness = 0.5;
        material.reflectivity = 0;
        material.clearcoat = 0.5;
        material.clearcoatRoughness = 1;

        // const textureLoader = new THREE.TextureLoader().load(planetTextureTest);
        materialStaticPlanet = new THREE.MeshPhysicalMaterial({ color: 0xffffff });
        materialStaticPlanet.map = textures.planetRnD;
        materialStaticPlanet.roughness = 0.65;
        materialStaticPlanet.metalness = 0.5;
        materialStaticPlanet.reflectivity = 0;
        materialStaticPlanet.clearcoat = 0.5;
        materialStaticPlanet.clearcoatRoughness = 1;
        const sphereG = new THREE.SphereGeometry(86, 32, 32);
        sphere = new THREE.Mesh(sphereG, materialStaticPlanet);
        planet.add(sphere);


        // const textureLoaderBack = new THREE.TextureLoader().load(sceneBack);
        // textureLoaderBack.wrapS = THREE.RepeatWrapping;
        // textureLoaderBack.wrapT = THREE.RepeatWrapping;
        // textureLoaderBack.anisotropy = renderer.capabilities.getMaxAnisotropy();
        // textureLoaderBack.magFilter = THREE.NearestFilter;
        // textureLoaderBack.minFilter = THREE.NearestFilter;
        // textureLoaderBack.minFilter = THREE.LinearMipMapLinearFilter;
        // scene.background = textureLoaderBack;


        // const textureAtlas = new THREE.TextureLoader().load(planetGlowTextureTestAtlas);
        const materialGlow = new THREE.MeshBasicMaterial({ map: textures.planetGlowRnD, transparent: true, opacity: 1 });
        const geometryGlow = new THREE.PlaneGeometry(380, 380);
        earthGlow = new THREE.Mesh(geometryGlow, materialGlow);
        earthGlow.position.set(-10, 10, 80);
        scene.add(earthGlow);

        // const textureAtlas2v = new THREE.TextureLoader().load(RND_ASSETS_0);
        // const geometryGlow2v = new THREE.PlaneGeometry(550, 400);
        // const materialGlow2v = new THREE.MeshBasicMaterial({ map: textureAtlas2v, transparent: true, opacity: 1 });
        // let earthMenuc = new THREE.Mesh(geometryGlow2v, materialGlow2v);
        // earthMenuc.position.set(-350, 150, 0);
        // earthMenuc.scale.set(12,12,0);
        // scene.add(earthMenuc);
        //scene.add(earthMenu);

        const sphereY = new THREE.SphereGeometry(120, 32, 32);
        staticPlanet = new THREE.Mesh(sphereY, materialStaticPlanet);
        staticPlanet.position.set(0, 0, 0);
        staticPlanet.scale.set(1, 1, 1);
        scene.add(staticPlanet);

        // if (models.modelBuildings) {
        const loader = new FBXLoader();
        loader.load(fbxPathBuilding, object => {
          // let object = models.modelBuildings
          object.traverse(child => {
            if (child.isMesh) {
              child.geometry.scale(0.15, 0.3, 0.15);
              child.geometry.rotateX(-PI / 2);
              geometries.push(child.geometry);
            }
          });
        });

          handleMaskFade(false);

        // };
      }

      function animate() {
      }
      function updateSize() {
        width = window.innerWidth;
        height = window.innerHeight;

        if (isPhone()) {
          renderer.setSize(window.outerWidth, window.outerHeight);
        } else {
          renderer.setSize(width, height);
        }

        camera.aspect = width / height;
        camera.updateProjectionMatrix();
      }


    }

    function cameraConfig() {
      camera.position.z = 715;
      cameraCtrl = new OrbitControls(camera, renderer.domElement);
      cameraCtrl.enabled = false;
      cameraCtrl.enableDamping = true;
      cameraCtrl.dampingFactor = 0.1;
      cameraCtrl.enableZoom = false;
      cameraCtrl.autoRotate = true;
      cameraCtrl.autoRotateSpeed = 0.5;
      cameraCtrl.rotateSpeed = 0.5;
      if (cameraCtrl) cameraCtrl.update();
      if (renderer) renderer.render(scene, camera);

      pointLight = new THREE.PointLight(0xffffff);
      pointLight.position.set(0, 0, 1000);
      scene.add(pointLight);

      pointLight2 = new THREE.PointLight(0xffffff);
      pointLight2.position.set(0, 0, -1000);
      scene.add(pointLight2);
      ambientLight = new THREE.AmbientLight(0xffffff)
      scene.add(ambientLight);

    }

    function addPointerListeners(renderer) {
      // Add pointer event listeners to the renderer
      renderer.domElement.addEventListener('pointerdown', onPointerDown);
      renderer.domElement.addEventListener('pointermove', onPointerMove);
      renderer.domElement.addEventListener('pointerup', onPointerUp);
    }

    function removePointerListeners(renderer) {
      // Add pointer event listeners to the renderer
      renderer.domElement.removeEventListener('pointerdown', onPointerDown);
      renderer.domElement.removeEventListener('pointermove', onPointerMove);
      renderer.domElement.removeEventListener('pointerup', onPointerUp);
    }

    function onPointerDown(event) {
      // Handle pointer down event
      mouse.x = (event.clientX / width) * 2 - 1;
      mouse.y = - (event.clientY / height) * 2 + 1;
      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, false);
      for (let i = 0; i < intersects.length; i++) {
        const object = intersects[i].object;
        if (object === staticPlanet) {
          if (startMovingPlanet && !letUserInteractWithPlanet && !assetsCreated) {
            assetsCreated = true;
            endMovingPlanet = false;
            const points = getFibonacciSpherePoints(200, 80);
            let p, mesh;

            for (let i = 0; i < points.length; i++) {
              p = points[i];
              //material  = new THREE.MeshStandardMaterial({ color: 0xcccccc });
              if (meshBuildingsArray.length < 200) {
                meshBuildings = new THREE.Mesh(geometries[Math.floor(Math.random() * geometries.length)], material);
                meshBuildings.position.set(p.x, p.y, p.z);
                meshBuildings.lookAt(0, 0, 0);
                meshBuildings.scale.z = 0.01;
                meshBuildings.building = true;
                // meshBuildings.tween = gsap.to(meshBuildings.scale, 3, { z: Math.random()*(1.1-1)+1.1, ease:  Elastic.easeOut.config(1, 0.2), delay: Math.random()*(2)});
                meshBuildings.tween = gsap.to(meshBuildings.scale, 3, { z: Math.random() * (1.3 - 1) + (isPortrait() ? 0.7 : 1.3), ease: Elastic.easeOut.config(1, 0.2), delay: Math.random() * (2) });
                meshBuildingsArray.push(meshBuildings);
                planet.add(meshBuildings);
              } else {
                meshBuildingsArray[i].geometry.dispose();
                meshBuildingsArray[i].material.color.setHex(0x3f7b9d);
                meshBuildingsArray[i].geometry = geometries[Math.floor(Math.random() * geometries.length)];
                meshBuildingsArray[i].position.set(p.x, p.y, p.z);
                meshBuildingsArray[i].lookAt(0, 0, 0);
                meshBuildingsArray[i].scale.z = 0.01;
                meshBuildingsArray[i].building = true;
                meshBuildingsArray[i].tween = gsap.to(meshBuildingsArray[i].scale, 3, { z: Math.random() * (1.1 - 1) + (isPortrait() ? 0.7 : 1.1), ease: Elastic.easeOut.config(1, 0.2), delay: Math.random() * (2) });
              }
            }
            
            // sphere.material.color.setHex(0x3f7b9d);
            // gsap.to(planet.scale, 0.2, { x: 0.025, y:0.025, z:0.025, ease: Power1.easeOut, onComplete: ()=>{
            //     letUserInteractWithPlanet = true;
            //     endMovingPlanet = true;
            // }  })

            startMovingPlanet = true;

            planet.tween = gsap.to(planet.scale, 3, {
              x: 1, y: 1, z: 1, ease: Power1.easeOut, onComplete: () => {
                endMovingPlanet = true;
                letUserInteractWithPlanet = true;
                if (cameraCtrl) cameraCtrl.enabled = true;
                if (earthGlow) {
                  earthGlow.tween = gsap.to(earthGlow.scale, 3, {
                    x: 0, y: 0, z: 0, ease: Power1.easeOut, onComplete: () => {
                      endMovingPlanet = true;
                      letUserInteractWithPlanet = true;
                    }
                  });
                }
              }
            });
          }
        }
      }
    }

    function onPointerMove(e) {
      if (e.buttons === 1) {
        isdragging = true;
      } else if (e.buttons === 0) {
        isdragging = false;
      }
      if (letUserInteractWithPlanet && endMovingPlanet/*&& e.buttons === 1*/) {
        mouse.x = (e.clientX / width) * 2 - 1;
        mouse.y = - (e.clientY / height) * 2 + 1;
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(planet.children, false);
        if (intersects.length > 0 && intersects[0].object.building) {
          const meshBuilding = intersects[0].object;
          if (meshBuilding.tween && meshBuilding.tween.isActive()) return;
          meshBuilding.material.color.setHex(0xbc29c1);
          meshBuilding.tween = gsap.to(meshBuilding.scale, 0.1, {
            z: 0.01, ease: Power2.easeOut, onComplete: () => {
              meshBuilding.material.color.setHex(0xc00c84);
              meshBuilding.geometry.dispose();
              meshBuilding.geometry = geometries[Math.floor(Math.random() * geometries.length)];
              meshBuilding.tween = gsap.to(meshBuilding.scale, 2, { z: Math.random() * (1.1 - 1) + (isPortrait() ? 0.7 : 1.1), ease: Elastic.easeOut.config(0.6, 0.2) });
            }
          });
        }
      }
    }

    function onPointerUp(event) {
      // Handle pointer up event
    }

    function getFibonacciSpherePoints(samples, radius, randomize) {
      samples = samples || 1;
      radius = radius || 1;
      randomize = randomize || true;
      let random = 1;
      if (randomize) {
        random = Math.random() * samples;
      }
      let points = []
      let offset = 2 / samples
      let increment = Math.PI * (3 - Math.sqrt(5));
      for (let i = 0; i < samples; i++) {
        let y = ((i * offset) - 1) + (offset / 2);
        let distance = Math.sqrt(1 - Math.pow(y, 2));
        let phi = ((i + random) % samples) * increment;
        let x = Math.cos(phi) * distance;
        let z = Math.sin(phi) * distance;
        x = x * radius;
        y = y * radius;
        z = z * radius;
        points.push({ x, y, z });
      }
      return points;
    }
    return () => {
      removePointerListeners(renderer);

      if(meshBuildings)gsap.killTweensOf(meshBuildings.scale)
      if(planet)gsap.killTweensOf(planet.scale)

      
      if(material)material.dispose();
      if(materialStaticPlanet)materialStaticPlanet.dispose();
      meshBuildingsArray = [];
      geometries = [];

      
      if(pointLight2)scene.remove(pointLight2);
      if(ambientLight)scene.remove(ambientLight);
      if(pointLight)scene.remove(pointLight);
      if(staticPlanet)scene.remove(staticPlanet);
      if(earthGlow)scene.remove(earthGlow);
      if(planet)scene.remove(planet);

      if (scene.current && camera.current) {
        scene.current.dispose();
        camera.current.dispose();
      }
      if (renderer.current) {
        renderer.current.dispose();
      }

      if (window) {
        window.removeEventListener("resize", resize);
      }

      geometries = [];
      materialStaticPlanet = null; material = null; staticPlanet = null;
      sphere = null; meshBuildingsArray = null; meshBuildings = null; cameraCtrl = null; earthGlow = null;
      renderer = null; scene = null; camera = null; pointLight = null; pointLight2 = null;

    };
  }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

  return (
    <div>
      <canvas ref={reactCanvas} {...rest} >
      </canvas>
    </div>
  );


})
