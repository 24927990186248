import React, { useState, useEffect } from 'react';
import "../style/newLoading2.scss";

export let setnewLoadingToFade;

export default function NewLoadingPage() {

    return (
        
        <div className={'newLoading'}>
            <section className="section">
                <div className="containerX">
                    <div className="circleX">
                        {[...Array(21)].map((_, i) => (
                            <span key={i} style={{ '--i': i }}></span>
                        ))}
                    </div>
                    <div className="circleX">
                        {[...Array(21)].map((_, i) => (
                            <span key={i} style={{ '--i': i }}></span>
                        ))}
                    </div>
                </div>
            </section>

        </div>
    );
}
