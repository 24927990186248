// import React from "react";
// import { Link } from "react-router-dom";
// import "../style/research-and-development.scss";
// import rdAnimationMp4 from "../assets/videos/R&D.mp4";
// import rdAnimationMp4R from "../assets/videos/R&D_r.mp4";
// import { uiVisibility, scrollTo, handleSwipeVisibility, handleScrollIconVisibility } from "../util/UIHelper.js";
// import pix2pixPng from "./../assets/images/our-organisation-logos/Harmonity_Pixels2Pixels_Logo_White_Transparent.png";
// import Logo from "./Logo";
// import { OnceContext } from "../context/OnceContext";
// import { HorisontalScrollbar } from "./HorisontalScrollbar";
// import Swipe from "./swipe";
// import Hand from "./hand";
// import NewScrollbar from "./newscrollbar";
import history from "../routes/history";
// import { isDesktop, isPhone } from "../util/DeviceType";
// import VerticalScrollIcon from "./verticalscrollicon";
import React from "react";

import "../style/business-consulting.scss";
import "../style/newAcademy.scss";
import "../style/NewSlider.scss";

import Particles from "react-tsparticles";

import AcademyParticlesConfig3 from "./AcademyParticlesConfig3";

// import ResearchAndDevelopmentThree from "./ResearchAndDevelopmentThree";
import AcademyFront from "./AcademyFront";
//import { changeScene, pointerWheelEventPaused } from "./../scenes/ResearchAndDevelopmentScene";
import { isDesktop, isPortrait, isLaptop, isPhone } from "../util/DeviceType";
import { tsParticles } from "tsparticles";
import Logo from "./Logo";
import NewContact from "./NewContact";

import { academyPictogram } from "../copy/pictograms";
import { academyTetxts } from "../copy/videotexts";
import Pictograms from "./Pictograms";
import TextBox from "./TextBox";
import { throttle } from "../util/UIHelper";

const VIDEO_PAUSE_TIME = {
  RD: 3,
  NFT: 4.6,
  IOT: 6.9,
  BS: 9.33,
  ES: 12,
  CC: 15.9,
};
const VIDEO_PAUSE_TIME_REVERT = {
  CC: 1.9,
  ES: 5.8,
  BS: 8.4,
  IOT: 10.9,
  NFT: 13.2,
  RD: 14.8,
};
const VIDEO_DELAY = 50;
let videoRD;
let videoRDRevert;
let scrollPaused = true;

export class Academy extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      showContact: false,
      activeId: 0,
      isMobile: false,
      showSwipe: true,
      swipeStart: 0,
      swipeEnd: 0,
      currentScreenState: 1,
      isText1Animated: false,
      isText2Animated: false,
      isText3Animated: false,
      text2AnimationCheck: false,
      text3AnimationCheck: false,
      disableScrolling: null,
      value: 0,
      startY: null,
      direction: null,
      pageId: "academy",
      activeText: 1,
      texts: {
        1: `Harmonity\u00A0Academy\u00A0is\u00A0an\u00A0accredited\u00A0part\u00A0of\u00A0Harmonity\u00A0Group\u00A0\nallowed\u00A0to\u00A0provide\u00A0courses\u00A0and\u00A0certificates\u00A0to\u00A0professionals.`,
        2: `We\u00A0offer\u00A0a\u00A0Project\u00A0Management\u00A0Trainer\u00A0certificate\u00A0accredited\u00A0by\u00A0IMP\u00A0\nand\u00A0Autodesk\u00A0software\u00A0training\u00A0by\u00A0experienced\u00A0certified\u00A0professionals.`,
        3: `Our\u00A0team\u00A0of\u00A0experts\u00A0has\u00A0years\u00A0of\u00A0experience\u00A0in\u00A0their\u00A0respective\u00A0fields,\u00A0\nand\u00A0they're\u00A0always\u00A0eager\u00A0to\u00A0share\u00A0their\u00A0insights.\u00A0\nReady\u00A0to\u00A0learn?`,
      },
      shouldFadeOut1: false,
      shouldFadeOut2: false,
      shouldFadeOut3: false,
      isText1AnimatedTimeout: false,
    };

    this.handleChange = (event) => {
      this.setState({ value: event.target.value });
    };

    this.handleScroll = (event) => {
      const direction = event.deltaY > 0 ? 1 : -1; // Positive value means scrolling down, negative means scrolling up

      if (!isPhone()) {
        if (direction > 0) {
          this.handleOpeningNextPage();
        } else if (direction < 0) {
          this.handleOpeningNextPageUP();
        }
      }

      // if (isDesktop()) {
      //   this.handleMoveScroll(event.wheelDelta > 0);
      // } else {
      //   this.handleMoveScroll(event.touches[0].clientY < this.lastY);
      //   this.lastY = event.touches[0].clientY;
      // }
    };

    this.throttledScroll = throttle(this.handleScroll, 1500);

    if (!isPhone()) {
      window.addEventListener("wheel", this.throttledScroll);
      // window.addEventListener("scroll", this.handleScroll);
    }
    // window.addEventListener("touchmove", this.handleScroll, { passive: false });
  }

  toggleActiveText = (id) => {
    this.setState({ activeText: id });
  };

  fadeOutText = (whichOne) => {
    if (whichOne == 1) {
      this.setState({ shouldFadeOut1: true });
    } else if (whichOne == 2) {
      this.setState({ shouldFadeOut2: true });
    } else if (whichOne == 3) {
      this.setState({ shouldFadeOut3: true });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.disableScrolling !== this.state.disableScrolling) {
      console.log("trigered");
      setTimeout(() => {
        this.setState((prevState, prevProps) => {
          if (prevState.disableScrolling) {
            return { disableScrolling: false };
          }
          return null;
        });
      }, 1000);
    }

    if (prevState.value !== this.state.value) {
      if (this.state.value === 100) {
        this.setState({ activeText: 0 });
        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          //window.removeEventListener("wheel", this.handleScroll);
          // window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

          setTimeout(() => {
            history.push("/research-and-development");
          }, 1501);
        }, 1000);
      }
    }
  }
  handleMoveScroll(direction) {
    if (!this.state.disableScrolling) {
      let nextScreenState = this.state.currentScreenState;
      if (direction) {
        nextScreenState--;
      } else {
        nextScreenState++;
      }
      if (nextScreenState > 5) {
        nextScreenState = 5;
      } else if (nextScreenState < 1) {
        nextScreenState = 1;
      }
      if (true) {
        // if (nextScreenState == 2 && !this.state.isText1Animated) {
        //   // const element = document.querySelector(".academyNewN-paragraph");
        //   // this.state.isText1Animated = true;
        //   // this.animateLetters(element, 0.03);
        //   // this.state.disableScrolling = true;
        //   // setTimeout(() => {
        //   //   this.state.disableScrolling = false;
        //   // }, 6000);
        // } else if (nextScreenState == 3 && !this.state.isText2Animated) {
        //   const element2 = document.querySelector(".academyNewN-paragraph2");
        //   this.animateLetters(element2, 0.03);
        //   this.state.disableScrolling = true;
        //   this.state.isText2Animated = true;
        //   this.fadeOutText(1)
        //   setTimeout(() => {
        //     this.state.disableScrolling = false;
        //   }, 6000);
        // } else if (nextScreenState == 4 && !this.state.isText3Animated) {
        //   const element3 = document.querySelector(".academyNewN-paragraph3");
        //   this.animateLetters(element3, 0.03);
        //   this.state.disableScrolling = true;
        //   this.state.isText3Animated = true;
        //   this.fadeOutText(2)
        //   setTimeout(() => {
        //     this.state.disableScrolling = false;
        //   }, 6000);
        // } else
        if (nextScreenState == 5) {
          this.handleOpeningNextPage();
        }
      }
      this.state.currentScreenState = nextScreenState;
    }
  }

  animateLetters(element, delay) {
    // Get the text content of the element and split it into an array of characters
    if (element) {
      const text = element.textContent;
      const letters = text.split("");

      // Replace the text content of the element with an array of span elements, one for each letter
      const html = letters
        .map((letter) => (letter === "\n" ? `<br />` : `<span class="letter">${letter}</span>`))
        .join("");
      element.innerHTML = html;

      // Loop through the span elements and add the "animated" class with a delay to each one
      const letterElements = element.querySelectorAll(".letter");
      letterElements.forEach((letter, index) => {
        letter.style.animationDelay = `${index * delay}s`;
        letter.classList.add("animated");
      });
    }
  }

  modifyTextsForMobile() {
    // change the text for mobile portrait mode
    const newTexts = {
      1: `Harmonity\u00A0Academy\u00A0is\u00A0an\u00A0accredited\u00A0part\nof\u00A0Harmonity\u00A0Group\u00A0allowed\u00A0to\u00A0provide\ncourses\u00A0and\u00A0certificates\u00A0to\u00A0professionals.`,
      2: `We\u00A0offer\u00A0a\u00A0Project\u00A0Management\u00A0Trainer\ncertificate\u00A0accredited\u00A0by\u00A0IMP\nand\u00A0Autodesk\u00A0software\u00A0training\u00A0by\nexperienced\u00A0certified\u00A0professionals.`,
      3: `Our\u00A0team\u00A0of\u00A0experts\u00A0has\u00A0years\nof\u00A0experience\u00A0in\u00A0their\u00A0respective\u00A0fields,\nand\u00A0they're\u00A0always\u00A0eager\u00A0to\nshare\u00A0their\u00A0insights.\u00A0\nReady\u00A0to\u00A0learn?`,
    };
    this.setState({ texts: newTexts });
  }

  componentDidMount() {
    // this.playSound();
    this.setState({ isMobile: this.context.matches });

    if (isPortrait()) {
      this.setState({ isMobile: true });
      this.modifyTextsForMobile();
    }

    this.props.handleMaskFade(false);

    // if (!this.state.isText1Animated && !this.state.isText1AnimatedTimeout) {
    //   this.state.isText1AnimatedTimeout = true;
    //   setTimeout(() => {
    //     this.state.isText1Animated = true;
    //     const element = document.querySelector(".academyNewN-paragraph");
    //     this.animateLetters(element, 0.03);
    //     this.state.disableScrolling = true;
    //     setTimeout(() => {
    //       this.state.disableScrolling = false;
    //     }, 6000);
    //   }, 3000);
    // }
  }
  componentWillUnmount() {
    this.setState({
      disableScrolling: null,
    });

    // this.stopSound();
    window.removeEventListener("wheel", this.throttledScroll);
    // window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("touchmove", this.handleScroll, { passive: false });
  }

  handleRouteNextPage = () => {
    setTimeout(() => {
      history.push("/selection-page");
    }, 500);
  };

  handleShowContact = () => {
    this.setState(function (prevState, props) {
      return { showContact: !prevState.showContact };
    });
  };

  ///////////////
  handleMouseDown = (e) => {
    this.setState({
      startY: e.clientY,
    });
  };

  handleMouseMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down") {
        this.handleOpeningNextPage();
      } else if (direction == "up") {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleMouseUp = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };

  handleTouchStart = (e) => {
    this.setState({
      startY: e.touches[0].clientY,
    });
  };

  handleTouchMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.touches[0].clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down") {
        this.handleOpeningNextPage();
      } else if (direction == "up") {
        this.handleOpeningNextPageUP();
      }
    }
  };
  handleOpeningNextPage() {
    console.log(this.state.disableScrolling);
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 0) {
        this.setBulletValue(50);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 50) {
        this.setBulletValue(100);
        this.setState({
          activeText: 0,
        });
        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          window.removeEventListener("wheel", this.throttledScroll);
          // window.removeEventListener("scroll", this.handleScroll);
        }, 1000);
        setTimeout(() => {
          history.push("/research-and-development");
        }, 2501);
      }
    }
  }
  handleOpeningNextPageUP() {
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });
      if (this.state.value == 50) {
        this.setBulletValue(0);
        this.setState({
          activeText: 1,
        });
      }
    }
  }

  handleTouchEnd = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };
  //////////

  // playSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("academy");
  // }

  // stopSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("nan");
  //   setSounds.setStopSounds("academy");
  // }

  setBulletValue = (val) => {
    this.setState((prevState, prevProps) => {
      if ((!prevState.value != val || val == 0) && !prevState.disableScrolling) {
        return { value: val };
      }
      return null;
    });

    this.setState((prevState, prevProps) => {
      if (!prevState.disableScrolling) {
        return { disableScrolling: true };
      }
      return null;
    });
  };

  handleClickCircle(bulletValue, activeText) {
    if (isPhone()) {
      if (!this.state.disableScrolling) {
        this.setBulletValue(bulletValue);
        this.setState({
          activeText: activeText,
        });
        // if (this.state.value === 50) {
        //   this.setState({ activeText: 0 });
        //   setTimeout(() => {
        //     this.state.currentScreenState = 1;

        //     // this.props.handleMaskFade(true);
        //     this.props.handleMaskFade(true);
        //     //window.removeEventListener("wheel", this.handleScroll);
        //     // window.removeEventListener("scroll", this.handleScroll);
        //     // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

        //     setTimeout(() => {
        //       history.push("/research-and-development");
        //     }, 1501);
        //   }, 1000);
        // }
      }
    }
  }

  render() {
    const { text2, isText2Animated, shouldFadeOut1, shouldFadeOut2, shouldFadeOut3, value } = this.state;
    return (
      <div className="research-and-development" id="research-and-development">
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <div className="pipe-container">
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={value}
                onChange={this.handleChange}
                className="pipe-input"
              />
              <div className="pipe"></div>
              <div className="fill" style={{ height: `${value}%` }} />
              <div
                className="pulsating-circle"
                style={{ top: `${value}%` }}
                // onMouseDown={this.handleMouseDown}
                // onMouseMove={this.handleMouseMove}
                // onMouseUp={this.handleMouseUp}
                // onTouchStart={this.handleTouchStart}
                // onTouchMove={this.handleTouchMove}
                // onTouchEnd={this.handleTouchEnd}
              />
              <div className="input-start">
                <p>Start</p>
              </div>
              <div className="input-end">
                <p>Next</p>
              </div>
              <div className="line-zero-academy" onClick={() => this.handleClickCircle(0, 1)}></div>
              <div className="line-first-academy" onClick={() => this.handleClickCircle(50, 2)}></div>
              <div className="line-third-academy" onClick={() => this.handleClickCircle(100, 0)}></div>
            </div>
          </div>
        </div>

        <NewContact />

        <Logo
          passedFunction={this.handleRouteNextPage}
          url={"/assets/sajtLogos/Harmonity_Academy_Logo_White_Transparent.png"}
        />
        <AcademyFront antialias id="my-canvas" textures={this.props.textures} />
        <div
          id="tsparticles"
          style={{
            zIndex: 4,
            height: "30%",
            width: "30%",
            marginTop: "12%",
            marginLeft: "35%",
            position: "absolute",
            pointerEvents: "auto",
          }}
        >
          <Particles params={AcademyParticlesConfig3} />
        </div>

        <TextBox
          activeText={this.state.activeText}
          activeTextContent={academyTetxts[this.state.activeText]}
          toggleActiveText={this.toggleActiveText}
        />

        <Pictograms
          toggleActiveText={this.toggleActiveText}
          activeText={this.state.activeText}
          pictograms={academyPictogram}
          bulletValue={this.setBulletValue}
          pageId={this.state.pageId}
        />

        {/* <AcademyThree antialias id="my-canvas" textures={this.props.textures} /> */}

        {/* <div id="tsparticles" style={{ zIndex: 4, height:'55%', width:'30%',  marginTop: '20%', marginLeft:'35%', position: 'absolute', pointerEvents: 'auto'}}>
          <Particles style={{height:'500px', width:'100%' }}  params={AcademyParticlesConfig2}/>
       </div> */}
        {/* <div style={{ zIndex: 4, height:'30%', width:'30%',  marginTop: '67%', marginLeft:'35%', position: 'absolute', pointerEvents: 'auto', background:"transparent"}}>
          <Particles params={AcademyParticlesConfig3}/>
       </div> */}
        {/* <Globe ref={this.ref}/>  */}
        {/* <ResearchAndDevelopment2Three antialias id="my-canvas"/> */}
        {/* <TestPageGrass antialias id="my-canvas"/> */}
        {/* <div style={{
          position: "relative"
        }}> 
          <div className="academyNewN-texts">
            <div className="academyNewN-box">
              <p className={`academyNewN-paragraph ${shouldFadeOut1 ? 'fade-out-paragraph' : ''}`}>
                {this.state.texts[1].split("").map((char, index) => (

                  <span key={index} className={`letter ${this.state.isText1Animated ? 'animated' : ''}`}>{char}</span>
                ))}
              </p>
            </div>
          </div>
          <div className="academyNewN-texts2">
            <div className="academyNewN-box2">
              <p className={`academyNewN-paragraph2 ${shouldFadeOut2 ? 'fade-out-paragraph' : ''}`}>{
                this.state.texts[2].split("").map((char, index) => (
                  <span key={index} className={`letter ${this.state.isText2Animated ? 'animated' : ''}`}>{char}</span>
                ))}</p>
            </div>
          </div>
          <div className="academyNewN-texts3">
            <div className="academyNewN-box3">
              <p className={`academyNewN-paragraph3 ${shouldFadeOut3 ? 'fade-out-paragraph' : ''}`}>{
                this.state.texts[3].split("").map((char, index) => (
                  <span key={index} className={`letter ${this.state.isText3Animated ? 'animated' : ''}`}>{char}</span>
                ))}</p>
            </div>
          </div>
        </div> */}

        {/* <div>
          <div className="academyNewN-texts">
            <div className="academyNewN-box">
            {this.state.currentScreenState == 1 && <p className="academyNewN-paragraph">{this.texts[1]}</p>}
            </div>
          </div>
          <div className="academyNewN-texts2">
            <div className="academyNewN-box2">
              {this.state.currentScreenState == 2 && <p className="academyNewN-paragraph2">{this.texts[2]}</p>}
            </div>
          </div>
          <div className="academyNewN-texts3">
            <div className="academyNewN-box3">
              {this.state.currentScreenState == 3 && <p className="academyNewN-paragraph3">{this.texts[3]}</p>}
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

//ResearchAndDevelopment.contextType = OnceContext;
