
import { useEffect, useRef } from "react";
import * as THREE from 'three';
import { gsap, Power1, Power2, Elastic } from 'gsap';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { createNoise3D } from 'simplex-noise';
import { isPortrait, isPhone } from "../util/DeviceType";

let cubes = new THREE.Object3D();
let cubesSmal = [];
let setTimeoutCubesAnimatiuon;
export let threeManager;
export default ({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, activeId, usedFromHome, ...rest }) => {
    const reactCanvas = useRef(null);

    const cubesRef = useRef(null);

    const canvasRef2D = useRef(null);

    let renderer;
    let scene;
    let camera;
    let pointLight;
    let pointLight2;
    let noise3D;
    let color;
    let cameraCtrl;
    let geomCubes, materialCubes;
    let baseColors = 240, light;

    useEffect(() => {
        const canvas = canvasRef2D.current;
        if (!canvas) {
          return;
        }
        // createStarrySky(canvas);
      }, []);

    useEffect(() => {
        if (!usedFromHome) {
            if(isPhone()){
                if(isPortrait()){
                    cubes.position.x = 0;
                }else{
                    cubes.position.x = 100;
                }
            }else{
                cubes.position.x = 100;
            }
        } else {
            cubes.position.x = 0;
        }

        switch (activeId) {
            case 0:
                baseColors = 300;
                swichColors();
                break;
            case 1:
                baseColors = 91;
                swichColors();
                break;
            case 2:
                baseColors = 30;
                swichColors();
                break;
            case 3:
                baseColors = 55;
                swichColors();
                break;
            case 4:
                baseColors = 0;
                swichColors();
                break;
            case 5:
                baseColors = 185;
                swichColors();
                break;
            default:
                baseColors = 240;
                swichColors();
                break;
        }


        function swichColors(){
            if(!cubesRef.current || isPhone()){
                return;
            }
            const size = 10;
            let count = 0;
            for (let x = -size * 0.5; x < size * 0.5; x++) {
                for (let y = -size * 0.5; y < size * 0.5; y++) {
                    for (let z = -size * 0.5; z < size * 0.5; z++) {
                        materialCubes = new THREE.MeshPhongMaterial({
                            color: createColor(x, y, z, baseColors),
                            emissive: createColor(x, y, z, baseColors),
                            emissiveIntensity: 0.5,
                            shininess: 50,
                        });
                        const cube = cubesRef.current.children[count]//= new THREE.Mesh(geomCubes, materialCubes);
                        cube.material = materialCubes;
                        cube.position.x = x * 15;
                        cube.position.y = y * 15;
                        cube.position.z = z * 15;

                        // cube.tween = gsap.to(cube.scale, {
                        //     duration: 2,
                        //     x: 0.1,
                        //     y: 0.1,
                        //     z: 0.1,
                        //     yoyo: true,
                        //     repeat: -1,
                        //     ease: Power2.easeInOut,
                        //     delay: -Math.round(noise3D(x * 0.08, y * 0.08, z * 0.08)) - 1.5,
                        // });
                        // cubes.add(cube);
                        count++;
                    }
                }
            }
        }
        function createColor(x, y, z, baseColor) {
            let noise3D = createNoise3D(Math.random);
            x *= 0.08;
            y *= 0.08;
            z *= 0.08;
            let satur = ",65%,";
            if (baseColor == 55){
                satur = ",70%,";
            }
            var l = Math.round(Math.abs(noise3D(x, y, z)) * 50);
            return "hsl(" + baseColor + satur + l + "%)";
        }
        return () => {
            if(materialCubes)materialCubes.dispose();
        }
    }, [activeId]);

    useEffect(() => {
        const { current: canvas } = reactCanvas;

        sceneOptions = {
            useGeometryUniqueIdsMapSearch: true,
            useMaterialMeshMapSearch: true,
            useClonedMeshMap: true,
        };
        if (!canvas) return;

        // create a new WebGL renderer
        renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x00ff00, 0);
        renderer.alpha = true;
        renderer.antialias = true;

        // create a new scene
        scene = new THREE.Scene();
        // scene.background = null;

        // create a new camera
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        if (!usedFromHome) {
            camera.position.set(0, 200, 200);
        } else {
            camera.position.set(0, 200, 200);
        }

        // add objects to the scene

        cameraConfig();
        creatingAssets(false);

        threeManager = {
            activeScene: scene,
            transitionScene: null
        };

        function render() {
            renderer.render(scene, camera);
            // if (cameraCtrl) cameraCtrl.update();

            // cubes.rotation.y += 0.004;
            // cubes.rotation.needsUpdate = true;
        }

        function animate() {
            if (renderer) {
                requestAnimationFrame(animate);
                render();
            }
        }

        animate();

        function cameraConfig() {
            cameraCtrl = new OrbitControls(camera, renderer.domElement);
            cameraCtrl.enabled = usedFromHome ? true : false;
            cameraCtrl.enableDamping = true;
            cameraCtrl.dampingFactor = 1;
            cameraCtrl.autoRotate = true;
            cameraCtrl.enableZoom = false;
            cameraCtrl.autoRotateSpeed = 1.5;
            cameraCtrl.rotateSpeed = 1.5;
            if (cameraCtrl) cameraCtrl.update();
    
            pointLight = new THREE.PointLight(0xffffff);
            pointLight.position.set(0, 0, 1000);
            scene.add(pointLight);
    
            pointLight2 = new THREE.PointLight(0xffffff);
            pointLight2.position.set(0, 0, -1000);
            scene.add(pointLight2);
            scene.add(new THREE.AmbientLight(0xffffff));
        }
        
        const resize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderer.setSize(width, height);
            
            if(isPhone()){
                if(isPortrait()){
                    if (!usedFromHome) {
                        cubes.position.x = 0;
                        cubes.position.y = -100;
                        cubes.rotation.x = -Math.PI/5;
                        cubes.rotation.y = Math.PI/4;
                        cubes.scale.set(0.8, 0.8, 0.8);
                    }else{
                        cubes.position.x = 5;
                        cubes.position.y = 0;
                        cubes.rotation.x = -Math.PI/9;
                        cubes.rotation.y = Math.PI/4;
                        cubes.scale.set(0.7, 0.7, 0.7);
                    }
                }else{
                    if (!usedFromHome) {
                        cubes.position.x = 100;
                        cubes.position.y = 50;
                        cubes.rotation.x = -Math.PI/10;
                        cubes.rotation.y = Math.PI/6;
                        cubes.scale.set(1, 1, 1);
                    }else{
                        cubes.position.x = 0;
                        cubes.position.y = 0;
                        cubes.rotation.x = -Math.PI/9;
                        cubes.rotation.y = Math.PI/4;
                        cubes.scale.set(1, 1, 1);
                    }
                }
            }else{
                if (!usedFromHome) {
                    cubes.position.x = 100;
                    cubes.rotation.x = -Math.PI/9;
                    cubes.rotation.y = Math.PI/5;
                }else{
                    cubes.rotation.x = -Math.PI/9;
                    cubes.rotation.y = Math.PI/4;
                    cubes.scale.set(0.8, 0.8, 0.8);
                }
            }
        };

        if (window) {
            window.addEventListener("resize", resize);
        }

        function creatingAssets(paramIsUpdating) {
            if (paramIsUpdating) {
                updateScene();
                return;
            }
            camera.lookAt(new THREE.Vector3(0, 0, 0));
    
            light = new THREE.PointLight(0xffffff, 1, 500);
            light.position.y = 0;
            light.position.z = 0;
            scene.add(light);
    
    
            function updateScene() {
                /*if (!setTimeoutCubesAnimatiuon) {
                    setTimeoutCubesAnimatiuon = true;
                    let counter = 0;
                    cubes.children.forEach(cube => {
                        cube.tween = gsap.to(cube.position, {
                            duration: 0.6,
                            x: 0,
                            y: 0,
                            z: 0,
                            ease: Power2.easeIn,
                            delay: Math.random(),
                            onComplete: () => {
                                counter++;
                                if (counter == 999) {
                                    if (geomCubes) geomCubes.dispose();
                                    if (materialCubes) materialCubes.dispose();
                                    cubesSmal.length = 0;
                                    cubes.children.length = 0;
                                    createScene();
                                    setTimeoutCubesAnimatiuon = false;
                                }
                            }
                        });
                    });
                }*/
            }
    
            if (usedFromHome) {
                window.addEventListener("mouseup", updateScene);
                window.addEventListener("touchend", updateScene);
            }
    
            function createScene() {
                if (usedFromHome) {
                    // color = Math.floor(Math.random() * 180) + 180;
                    // renderer.setClearColor(new THREE.Color("hsl(" + color + ",50%,50%)"), 0.1);
                    // color = Math.floor(Math.random() * 180) + 180;
                    // renderer.setClearColor(new THREE.Color(`hsl(${color},50%,50%)`), 0.1);
                }
    
                noise3D = createNoise3D(Math.random);
                geomCubes = new THREE.BoxGeometry(10, 10, 10);
                const size = 10;
    
                for (let x = -size * 0.5; x < size * 0.5; x++) {
                    for (let y = -size * 0.5; y < size * 0.5; y++) {
                        for (let z = -size * 0.5; z < size * 0.5; z++) {
                            materialCubes = new THREE.MeshPhongMaterial({
                                color: createColor(x, y, z, baseColors),
                                emissive: createColor(x, y, z, baseColors),
                                emissiveIntensity: 0.5,
                                shininess: 50,
                            });
    
                            const cube = new THREE.Mesh(geomCubes, materialCubes);
                            cube.position.x = x * 15;
                            cube.position.y = y * 15;
                            cube.position.z = z * 15;
    
                            cube.tween = gsap.to(cube.scale, {
                                duration: 2,
                                x: 0.1,
                                y: 0.1,
                                z: 0.1,
                                yoyo: true,
                                repeat: -1,
                                ease: Power2.easeInOut,
                                delay: -Math.round(noise3D(x * 0.08, y * 0.08, z * 0.08)) - 1.5,
                            });
                            cubes.add(cube);
                        }
                    }
                }
            }
    
            function createColor2(x, y, z) {
                x *= 0.08;
                y *= 0.08;
                z *= 0.08;
                var l = Math.round((Math.abs(noise3D(x, y, z))) * 60);
                return "hsl(" + color + ",50%," + l + "%)";
            }
            function createColor(x, y, z, baseColor) {
                x *= 0.08;
                y *= 0.08;
                z *= 0.08;
                let satur = ",65%,";
                if (baseColor == 55){
                    satur = ",70%,";
                }
                var l = Math.round(Math.abs(noise3D(x, y, z)) * 50);
                return "hsl(" + baseColor + satur + l + "%)";
            }
    
            if (geomCubes) geomCubes.dispose();
            if (materialCubes) materialCubes.dispose();
            cubesSmal.length = 0;
            cubes.children.length = 0;
            createScene();
            setTimeoutCubesAnimatiuon = false;
    
            if(isPhone()){
                if(isPortrait()){
                    if (!usedFromHome) {
                        cubes.position.x = 0;
                        cubes.position.y = -100;
                        cubes.rotation.x = -Math.PI/5;
                        cubes.rotation.y = Math.PI/4;
                        cubes.scale.set(0.8, 0.8, 0.8);
                    }else{
                        cubes.position.x = 5;
                        cubes.position.y = 0;
                        cubes.rotation.x = -Math.PI/9;
                        cubes.rotation.y = Math.PI/4;
                        cubes.scale.set(0.7, 0.7, 0.7);
                    }
                }else{
                    if (!usedFromHome) {
                        cubes.position.x = 100;
                        cubes.position.y = 50;
                        cubes.rotation.x = -Math.PI/10;
                        cubes.rotation.y = Math.PI/6;
                        cubes.scale.set(1, 1, 1);
                    }else{
                        cubes.position.x = 0;
                        cubes.position.y = 0;
                        cubes.rotation.x = -Math.PI/9;
                        cubes.rotation.y = Math.PI/4;
                        cubes.scale.set(1, 1, 1);
                    }
                }
            }else{
                if (!usedFromHome) {
                    cubes.position.x = 100;
                    cubes.rotation.x = -Math.PI/9;
                    cubes.rotation.y = Math.PI/5;
                }else{
                    cubes.rotation.x = -Math.PI/9;
                    cubes.rotation.y = Math.PI/4;
                    cubes.scale.set(0.8, 0.8, 0.8);
                }
            }
    
            scene.add(cubes);
            cubesRef.current = cubes;

        }
        
        return () => {
            cubesRef.current = null;
            if (geomCubes) geomCubes.dispose();
            if (materialCubes) materialCubes.dispose();
            cubesSmal.length = 0;
            if (cubes) cubes.children.length = 0;

            if (scene.current && camera.current && pointLight2.current && pointLight.current) {
                scene.current.dispose();
                camera.current.dispose();
                pointLight.current.dispose();
                pointLight2.current.dispose();
            }
            if (renderer.current) {
                renderer.current.dispose();
            }

            if (window) {
                window.removeEventListener("resize", resize);
            }
            if (usedFromHome) {
                // window.removeEventListener("mouseup", updateScene);
                // window.removeEventListener("touchend", updateScene);
            }
            window.removeEventListener("resize", function () {
                let wwa = window.innerWidth;
                let wha = window.innerHeight;
                camera.aspect = wwa / wha;
                camera.updateProjectionMatrix();
                renderer.setSize(wwa, wha);
            });

            materialCubes = null;
            renderer = null; scene = null; camera = null; pointLight = null; pointLight2 = null; light = null;
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, usedFromHome]);


    return (
        <div>
        <canvas ref={reactCanvas} {...rest}></canvas>
        
        </div>
    );

    

}