import React, { useState, useEffect } from 'react';
import "../style/maskTransitionNew.scss";
import { isPortrait, isPhone } from "../util/DeviceType";

export let setMaskToFade;

export default function MaskTransitionNew(props) {
    const [fades, setFade] = useState(false);
    let counterIndex = 0;
    let counterIndexShow = 0;
    let raf;

    useEffect(() => {
        const circles = document.querySelectorAll(".big-circle");

        let delay1 = 0;
        let delay2 = 0;

        //This function is used to slow down the execution of requestAnimationFrames to desired time
        let Timer = function (callback, fp) {
            let now = 0;
            let delta = 0;
            let then = Date.now();
            let frames = 0;
            let oldtime = 0;
            let fps = 1000 / (fp || 60);

            return requestAnimationFrame(function loop(time) {
                raf = requestAnimationFrame(loop);
                now = Date.now();
                delta = now - then;

                if (delta > fps) {
                    // Update time stuffs
                    then = now - (delta % fps);

                    // Calculate the frames per second.
                    frames = 1000 / (time - oldtime);
                    oldtime = time;

                    // Call the callback-function and pass our current frame into it.
                    callback(frames);
                }
            });
        };

        if (props.triggerFade === true) {
            if (circles.length > 0) { counterIndexShow = circles.length-1; Timer(showElements, 20); }
            //    setFade(true);
        }

        else if (props.triggerFade === null) {
            // setFade(null);
        }

        else if (props.triggerFade === false) {
            // setFade(false);
            if (circles.length > 0) { counterIndexShow = circles.length-1; Timer(hideElements, 20); }

        }

        function showElements() {
            circles[counterIndexShow ].style.visibility = "visible";
            circles[counterIndexShow ].style.opacity = "1"; 
            counterIndexShow--;

            if (counterIndexShow < 0) {
                window.cancelAnimationFrame(raf);
            }
        }
        function hideElements() {
            if(counterIndexShow == circles.length-1){
                circles.forEach(element => {
                    element.style.opacity = "1";
                });
            }
            // for (let index = 1; index <= circles.length; index++) {
            //     if(index = counterIndexShow - 1){circles[index-1].style.visibility = "hidden";}
            // }
            // circles[counterIndexShow].style.visibility = "hidden";
            if(circles[counterIndexShow-2 ])circles[counterIndexShow-2 ].style.opacity = "0.7";
            if(circles[counterIndexShow-1 ])circles[counterIndexShow-1 ].style.opacity = "0.3";
            circles[counterIndexShow ].style.opacity = "0";
            
            counterIndexShow--;

            if (counterIndexShow < 0) {
                window.cancelAnimationFrame(raf);
            }
        }

    }, [props.triggerFade]);

    return (

        // <div className={'overlay'}>
        //   <div className={'big-circle circle2'} ></div>
        // </div>
        <div className={'overlay'}>
            {!isPhone() && (<div className={` ${fades === false ? 'big-circle circle20 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle20'}`} ></div>)}
            {!isPhone() && (<div className={` ${fades === false ? 'big-circle circle19 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle19'}`} ></div>)}
            {!isPhone() && (<div className={` ${fades === false ? 'big-circle circle18 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle18'}`} ></div>)}
            {!isPhone() && (<div className={` ${fades === false ? 'big-circle circle17 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle17'}`} ></div>)}
            {!isPhone() && (<div className={` ${fades === false ? 'big-circle circle16 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle16'}`} ></div>)}
            <div className={` ${fades === false ? 'big-circle circle15 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle15'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle14 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle14'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle13 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle13'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle12 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle12'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle11 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle11'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle10 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle10'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle9 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle9'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle8 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle8'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle7 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle7'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle6 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle6'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle5 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle5'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle4 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle4'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle3 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle3'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle2 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle2'}`} ></div>
            <div className={` ${fades === false ? 'big-circle circle1 circleFadeOut circle-hidden' : fades === null ? '' : 'big-circle circle1'}`} ></div>
        </div>
    );
}
