import React from "react";

import "../style/logo.scss";

export default function Logo({ passedFunction, url }) {
  return (
    <div className="mainLogo" onClick={passedFunction}>
      <img className="logoImg" src={url} />
      {/* {isHomePage && <p className="interactive">interactive</p>} */}
    </div>
  );
}
