
import { useEffect, useRef, memo } from "react";
import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import RND_ASSETS_0 from '../assets/newScenes/textures/r-d-uputstvo.webp';
import { isLandscape, isLaptop, isPhone, isPortrait, isDesktop } from "../util/DeviceType";
import { neonCursor } from 'threejs-toys';

export let threeManager;
export default memo(({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, textures, ...rest }) => {
    const reactCanvas = useRef(null);


    let renderer;
    let scene;
    let camera;
    let width, height;
    let planet;
    let staticPlanet;
    let material;
    let materialStaticPlanet;
    let earthGlow;
    let cameraCtrl;
    let sphere;
    let backgroundMesh, neonCursorX;
    const geometries = [];
    let textureAtlas1, textureAtlas2;
    let geometryGlow1, geometryGlow2;
    let materialGlow1, materialGlow2;
    let loader;
    let sphereY;
    let geometryGlow;
    let sphereG;
    let materialGlowCc;
    let textureAtlasCc;
    let textureLoader, pointLight, pointLight2;

    let textureAtlas1Portrait;
    let geometryGlow1Portrait;
    let materialGlow1Portrait;
    let backgroundMeshPortrait;

    let textureAtlas1Landscape;
    let geometryGlow1Landscape;
    let materialGlow1Landscape;
    let backgroundMeshLandscape;
    let earthMenu, reqAnimFrame, animReq;

    useEffect(() => {
        const { current: canvas } = reactCanvas;

        sceneOptions = {
            useGeometryUniqueIdsMapSearch: true,
            useMaterialMeshMapSearch: true,
            useClonedMeshMap: true,
        };
        if (!canvas) return;

        // create a new WebGL renderer
        renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x00ff00, 0);
        renderer.alpha = true;
        renderer.antialias = true;

        // create a new scene
        scene = new THREE.Scene();
        // scene.background = null;

        // create a new camera
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set(0, 0, 5);

        // add objects to the scene

        creatingAssets();
        cameraConfig();

        threeManager = {
            activeScene: scene,
            transitionScene: null
        };

        function render() {
            if (renderer) renderer.render(scene, camera);
        }

        function animate() {
            if (renderer) {
                animReq = requestAnimationFrame(animate);
                render();
            }
        }

        animate();

        const resize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            if (isPhone()) {
                renderer.setSize(window.outerWidth, window.outerHeight);
            } else {
                renderer.setSize(width, height);
            }

            if (isPortrait()) {
                earthMenu.scale.set(0.5, 0.5, 0.5);
                earthMenu.position.set(-120, 200, 0);
            } else {
                earthMenu.scale.set(1, 1, 1);
                earthMenu.position.set(-350, 180, 0);
            }

            const meshPC = scene.getObjectByName('backRnD');
            const meshPortrait = scene.getObjectByName('backRnDPortrait');
            const meshLandscape = scene.getObjectByName('backRnDLandscape');
            if (isPhone()) {
                if (isPortrait()) {
                    backgroundMeshPortrait.position.set(0, 0, -20);
                    backgroundMeshPortrait.scale.set(400, 400, 0);
                    if (meshLandscape) scene.remove(backgroundMeshLandscape);
                    if (meshPC) scene.remove(backgroundMesh);
                    scene.add(backgroundMeshPortrait);
                } else if (isLandscape()) {
                    backgroundMeshLandscape.position.set(0, 0, -20);
                    backgroundMeshLandscape.scale.set(560, 560, 0);
                    if (meshPC) scene.remove(backgroundMesh);
                    if (meshPortrait) scene.remove(backgroundMeshPortrait);
                    scene.add(backgroundMeshLandscape);
                }
            } else {
                backgroundMesh.scale.set(1100, 1100, 0);
                if (meshPortrait) scene.remove(backgroundMeshPortrait);
                if (meshLandscape) scene.remove(backgroundMeshLandscape);
                backgroundMesh.position.set(0, 0, -20);
                // scene.add(backgroundMesh);
                scene.background = textures.academyHiresTextureP;
            }

        };


        function creatingAssets() {
        
            neonCursorX = neonCursor({
                el: document.getElementById('cursorShow'),
                shaderPoints: 10,
                curvePoints: 80,
                curveLerp: 0.7,
                radius1: 1,
                radius2: 5,
                velocityTreshold: 10,
                sleepRadiusX: 100,
                sleepRadiusY: 100,
                sleepTimeCoefX: 0.0025,
                sleepTimeCoefY: 0.0025,
                color1: '#ffffff',
                color2: '#ffffff'
            })
    
            // textureAtlas1 = new THREE.TextureLoader().load(sceneBack);
            geometryGlow1 = new THREE.PlaneGeometry(2.048, 1.152);
            materialGlow1 = new THREE.MeshBasicMaterial({ map: textures.academyHiresTextureP });
            backgroundMesh = new THREE.Mesh(geometryGlow1, materialGlow1);
            backgroundMesh.name = "backRnD";
    
            // textureAtlas1Portrait = new THREE.TextureLoader().load(sceneBackPortrait);
            geometryGlow1Portrait = new THREE.PlaneGeometry(1.555, 3.456);
            materialGlow1Portrait = new THREE.MeshBasicMaterial({ map: textures.academyHiresTexturePortraitP });
            backgroundMeshPortrait = new THREE.Mesh(geometryGlow1Portrait, materialGlow1Portrait);
            backgroundMeshPortrait.name = "backRnDPortrait";
    
            // textureAtlas1Landscape = new THREE.TextureLoader().load(sceneBackLandscape);
            geometryGlow1Landscape = new THREE.PlaneGeometry(5.000, 2.250);
            materialGlow1Landscape = new THREE.MeshBasicMaterial({ map: textures.academyBackMobileLandscape });
            backgroundMeshLandscape = new THREE.Mesh(geometryGlow1Landscape, materialGlow1Landscape);
            backgroundMeshLandscape.name = "backRnDLandscape";
    
    
            const meshPC = scene.getObjectByName('backRnD');
            const meshPortrait = scene.getObjectByName('backRnDPortrait');
            const meshLandscape = scene.getObjectByName('backRnDLandscape');
            if (isPhone()) {
                if (isPortrait()) {
                    backgroundMeshPortrait.position.set(0, 0, -20);
                    backgroundMeshPortrait.scale.set(400, 400, 0);
                    if (meshLandscape) scene.remove(backgroundMeshLandscape);
                    if (meshPC) scene.remove(backgroundMesh);
                    scene.add(backgroundMeshPortrait);
                } else if (isLandscape()) {
                    backgroundMeshLandscape.position.set(0, 0, -20);
                    backgroundMeshLandscape.scale.set(560, 560, 0);
                    if (meshPC) scene.remove(backgroundMesh);
                    if (meshPortrait) scene.remove(backgroundMeshPortrait);
                    scene.add(backgroundMeshLandscape);
                }
            } else {
                backgroundMesh.scale.set(1100, 1100, 0);
                if (meshPortrait) scene.remove(backgroundMeshPortrait);
                if (meshLandscape) scene.remove(backgroundMeshLandscape);
                backgroundMesh.position.set(0, 0, -20);
                // scene.add(backgroundMesh);
                scene.background = textures.academyHiresTextureP;
            }
    
            geometryGlow2 = new THREE.PlaneGeometry(550, 380);
            materialGlow2 = new THREE.MeshBasicMaterial({ map: textures.RND_ASSETS_0RnDpP, transparent: true, opacity: 1 });
            earthMenu = new THREE.Mesh(geometryGlow2, materialGlow2);
            if (isPortrait()) {
                earthMenu.scale.set(0.5, 0.5, 0.5);
                earthMenu.position.set(-120, 200, 0);
            } else {
                earthMenu.scale.set(1, 1, 1);
                earthMenu.position.set(-350, 180, 0);
            }
            // scene.add(earthMenu);
        }
    
        function cameraConfig() {
            camera.position.z = 715;
            // cameraCtrl = new OrbitControls(camera, renderer.domElement);
            // cameraCtrl.enabled = false;
            // cameraCtrl.enableDamping = true;
            // cameraCtrl.enableZoom = false;
            // cameraCtrl.dampingFactor = 0.1;
            // cameraCtrl.autoRotate = true;
            // cameraCtrl.autoRotateSpeed = 0.5;
            // cameraCtrl.rotateSpeed = 0.5;
            // if (cameraCtrl) cameraCtrl.update();
    
            pointLight = new THREE.PointLight(0xffffff);
            pointLight.position.set(0, 0, 1000);
            scene.add(pointLight);
    
            pointLight2 = new THREE.PointLight(0xffffff);
            pointLight2.position.set(0, 0, -1000);
            scene.add(pointLight2);
            scene.add(new THREE.AmbientLight(0xffffff));
        }
        if (window) {
            window.addEventListener("resize", resize);
        }

        return () => {
            if (sphereG) sphereG.dispose();
            if (textureLoader) textureLoader.dispose();
            if (materialGlowCc) materialGlowCc.dispose();
            if (textureAtlasCc) textureAtlasCc.dispose();
            if (planet) planet.dispose();
            if (geometryGlow) geometryGlow.dispose();
            if (sphereY) geometryGlow.dispose();
            if (loader) loader.dispose();
            if (materialGlow1) materialGlow1.dispose();
            if (materialGlow2) materialGlow2.dispose();
            if (geometryGlow1) geometryGlow1.dispose();
            if (geometryGlow2) geometryGlow2.dispose();
            if (textureAtlas1) textureAtlas1.dispose();
            if (textureAtlas2) textureAtlas2.dispose();

            if (scene.current && camera.current) {
                scene.current.dispose();
                camera.current.dispose();
            }
            if (renderer.current) {
                renderer.current.dispose();
            }

            if (window) {
                window.removeEventListener("resize", resize);
            }
            earthMenu = null;
            materialGlow1 = null;
            sphereY = null; sphereG = null; textureLoader = null; materialGlowCc = null; materialGlow2 = null;
            renderer = null; scene = null; camera = null; pointLight = null; pointLight2 = null;
            neonCursorX = null;

            cancelAnimationFrame(animReq);
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

    return (
        
        <div style={{
            WebkitUserSelect: 'none',
            MozUserSelect: 'none',
            msUserSelect: 'none',
            userSelect: 'none'
          }}>
            <div id="cursorShow" style={{ opacity: "0.3", backgroundColor: "transparent", marginTop: '0%', marginLeft: '0%', width: "100%", height: "100%", zIndex: 5, position: 'absolute', pointerEvents: 'auto' }} />

            <canvas ref={reactCanvas} {...rest} >
            </canvas>
        </div>
    );


})