// import React from "react";
// import { Link } from "react-router-dom";
// import "../style/research-and-development.scss";
// import rdAnimationMp4 from "../assets/videos/R&D.mp4";
// import rdAnimationMp4R from "../assets/videos/R&D_r.mp4";
// import { uiVisibility, scrollTo, handleSwipeVisibility, handleScrollIconVisibility } from "../util/UIHelper.js";
// import pix2pixPng from "./../assets/images/our-organisation-logos/Harmonity_Pixels2Pixels_Logo_White_Transparent.png";
// import Logo from "./Logo";
// import { OnceContext } from "../context/OnceContext";
// import { HorisontalScrollbar } from "./HorisontalScrollbar";
// import Swipe from "./swipe";
// import Hand from "./hand";
// import NewScrollbar from "./newscrollbar";
// import history from "../routes/history";
// import { isDesktop, isPhone } from "../util/DeviceType";
// import VerticalScrollIcon from "./verticalscrollicon";
import React from "react";

import "../style/business-consulting.scss";

import Logo from "./Logo";
import NewContact from "./NewContact";

import "../style/NewSlider.scss";

// import ResearchAndDevelopmentThree from "./ResearchAndDevelopmentThree";
import busin from "./ResearchAndDevelopment2Three";
import BusinessConsultingFrontThree from "./BusinessConsultingFrontThree";
import BusinessConsultingBackThree, { scrollState } from "./BusinessConsultingBackThree";

//import { changeScene, pointerWheelEventPaused } from "./../scenes/ResearchAndDevelopmentScene";
import history from "../routes/history";

import { isDesktop, isLaptop, isPhone } from "../util/DeviceType";
import TextBox from "./TextBox";
import Pictograms from "./Pictograms";
import { businessConsultingPictograms } from "../copy/pictograms";
import { businessconsultingTetxts } from "../copy/videotexts";
import { throttle } from "../util/UIHelper";

let setCanvasWidth = 1920 / 1.65;
let setCanvasHeight = 1080 / 1.65;

const VIDEO_PAUSE_TIME = {
  RD: 3,
  NFT: 4.6,
  IOT: 6.9,
  BS: 9.33,
  ES: 12,
  CC: 15.9,
};
const VIDEO_PAUSE_TIME_REVERT = {
  CC: 1.9,
  ES: 5.8,
  BS: 8.4,
  IOT: 10.9,
  NFT: 13.2,
  RD: 14.8,
};
const VIDEO_DELAY = 50;
let videoRD;
let videoRDRevert;
let scrollPaused = true;

export class BusinessConsulting extends React.Component {
  texts = {
    1: `Our Business consulting entity provides expert guidance in enterprise and project structure setup for companies seeking to improve their performance, operations, and profitability.`,
    2: `We offer Projects, Consulting, and Digital Transformation services to help businesses achieve their goals and improve their operations.`,
    3: `With knowledge and experience, our highly skilled consultants will ensure that you lay the foundations for a thriving enterprise. Trust us with your success.`,
  };
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      activeText: 1,
      showContact: false,
      activeId: 0,
      isMobile: false,
      showSwipe: true,
      swipeStart: 0,
      swipeEnd: 0,
      currentScreenState: 1,
      value: 0,
      startY: null,
      direction: null,
      disableScrolling: null,
      pageId: "business",
    };

    this.handleScroll = (event) => {
      const direction = event.deltaY > 0 ? 1 : -1; // Positive value means scrolling down, negative means scrolling up

      if (direction > 0) {
        this.handleOpeningNextPage();
      } else if (direction < 0) {
        this.handleOpeningNextPageUP();
      }
    };

    this.throttledScroll = throttle(this.handleScroll, 1500);

    this.handleChange = (event) => {
      this.setState({ value: event.target.value });
    };
    if (!isPhone()) {
      window.addEventListener("wheel", this.throttledScroll);
      // window.addEventListener("scroll", this.handleScroll);
    }
  }
  handleMoveScroll(direction) {
    let nextScreenState = this.state.currentScreenState;
    if (direction) {
      nextScreenState--;
    } else {
      nextScreenState++;
    }
    if (nextScreenState > 3) {
      nextScreenState = 3;
    } else if (nextScreenState < 1) {
      nextScreenState = 1;
    }
    this.setState({
      currentScreenState: nextScreenState,
    });
  }

  // setMaskToFadeOut() {
  //   this.props.handleMaskFade(false);
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.disableScrolling !== this.state.disableScrolling) {
      console.log("trigered");
      setTimeout(() => {
        this.setState((prevState, prevProps) => {
          if (prevState.disableScrolling) {
            return { disableScrolling: false };
          }
          return null;
        });
      }, 1000);
    }

    if (prevState.value !== this.state.value) {
      if (this.state.value === 100) {
        this.setState({ activeText: 0 });
        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          //window.removeEventListener("wheel", this.handleScroll);
          // window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

          setTimeout(() => {
            history.push("/academy");
          }, 1501);
        }, 1000);
      }
    }
  }
  componentDidMount() {
    this.setState({ isMobile: this.context.matches });
    // this.playSound();

    // this.setState({ activeText: 0 });
  }

  componentWillUnmount() {
    this.setState({
      disableScrolling: null,
    });

    // this.stopSound();
    window.removeEventListener("wheel", this.throttledScroll);
    // window.removeEventListener("scroll", this.handleScroll);
  }

  handleShowContact = () => {
    this.setState(function (prevState, props) {
      return { showContact: !prevState.showContact };
    });
  };

  ///////////////
  handleMouseDown = (e) => {
    this.setState({
      startY: e.clientY,
    });
  };

  handleMouseMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down" && this.state.startY) {
        this.handleOpeningNextPage();
      } else if (direction == "up" && this.state.startY) {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleMouseUp = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };

  handleTouchStart = (e) => {
    this.setState({
      startY: e.touches[0].clientY,
    });
  };

  handleTouchMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.touches[0].clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down" && this.state.startY) {
        this.handleOpeningNextPage();
      } else if (direction == "up" && this.state.startY) {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleOpeningNextPage() {
    console.log(this.state.disableScrolling);
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 0) {
        this.setBulletValue(25);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 25) {
        this.setBulletValue(50);
        this.setState({
          activeText: 3,
        });
      } else if (this.state.value == 50) {
        this.setBulletValue(75);
        this.setState({
          activeText: 4,
        });
      } else if (this.state.value == 75) {
        this.setBulletValue(100);
        this.setState({
          activeText: 0,
        });

        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          if (isDesktop() || isLaptop()) {
            window.removeEventListener("wheel", this.handleScroll);
            window.removeEventListener("scroll", this.handleScroll);
          }
        }, 1000);
        setTimeout(() => {
          history.push("/academy");
        }, 2501);
      }
    }
  }

  handleOpeningNextPageUP() {
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 25) {
        this.setBulletValue(0);
        this.setState({
          value: 0,
          activeText: 1,
        });
      } else if (this.state.value == 50) {
        this.setBulletValue(25);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 75) {
        this.setBulletValue(50);
        this.setState({
          activeText: 3,
        });
      } else if (this.state.value == 100) {
        this.setBulletValue(75);
        this.setState({
          activeText: 4,
        });
      }
    }
  }

  handleTouchEnd = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };
  //////////

  // playSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("business");
  // }

  // stopSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("nan");
  //   setSounds.setStopSounds("business");
  // }

  handleRouteNextPage = () => {
    setTimeout(() => {
      history.push("/selection-page");
    }, 500);
  };

  toggleActiveText = (id) => {
    this.setState({ activeText: id });
  };

  setBulletValue = (val) => {
    this.setState((prevState, prevProps) => {
      if ((!prevState.value != val || val == 0) && !prevState.disableScrolling) {
        return { value: val };
      }
      return null;
    });

    this.setState((prevState, prevProps) => {
      if (!prevState.disableScrolling) {
        return { disableScrolling: true };
      }
      return null;
    });
  };

  handleClickCircle(bulletValue, activeText) {
    if (isPhone()) {
      if (!this.state.disableScrolling) {
        this.setBulletValue(bulletValue);
        this.setState({
          activeText: activeText,
        });
        // if (this.state.value === 75) {
        //   this.setState({ activeText: 0 });
        //   setTimeout(() => {
        //     this.state.currentScreenState = 1;

        //     // this.props.handleMaskFade(true);
        //     this.props.handleMaskFade(true);
        //     //window.removeEventListener("wheel", this.handleScroll);
        //     // window.removeEventListener("scroll", this.handleScroll);
        //     // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

        //     setTimeout(() => {
        //       history.push("/academy");
        //     }, 1501);
        //   }, 1000);
        // }
      }
    }
  }

  render() {
    const { isMobile, activeId, value } = this.state;
    return (
      <div className="research-and-development" id="research-and-development">
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <div className="pipe-container">
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={value}
                onChange={this.handleChange}
                className="pipe-input"
              />
              <div className="pipe"></div>
              <div className="fill" style={{ height: `${value}%` }} />
              <div
                className="pulsating-circle"
                style={{ top: `${value}%` }}
                // onMouseDown={this.handleMouseDown}
                // onMouseMove={this.handleMouseMove}
                // onMouseUp={this.handleMouseUp}
                // onTouchStart={this.handleTouchStart}
                // onTouchMove={this.handleTouchMove}
                // onTouchEnd={this.handleTouchEnd}
              />
              <div className="input-start">
                <p>Start</p>
              </div>
              <div className="input-end">
                <p>Next</p>
              </div>
              <div className="line-zero-bc" onClick={() => this.handleClickCircle(0, 1)}></div>
              <div className="line-first-bc" onClick={() => this.handleClickCircle(25, 2)}></div>
              <div className="line-second-bc" onClick={() => this.handleClickCircle(50, 3)}></div>
              <div className="line-third-bc" onClick={() => this.handleClickCircle(75, 4)}></div>
              <div className="line-fourth-bc" onClick={() => this.handleClickCircle(100, 0)}></div>
            </div>
          </div>
        </div>

        <TextBox
          activeText={this.state.activeText}
          activeTextContent={businessconsultingTetxts[this.state.activeText]}
          toggleActiveText={this.toggleActiveText}
        />

        <Pictograms
          toggleActiveText={this.toggleActiveText}
          activeText={this.state.activeText}
          pictograms={businessConsultingPictograms}
          bulletValue={this.setBulletValue}
          pageId={this.state.pageId}
        />

        <NewContact />

        <Logo
          url={"/assets/sajtLogos/Harmonity_BusinessConsulting_Logo_White_Transparent.png"}
          passedFunction={this.handleRouteNextPage}
        />

        <BusinessConsultingBackThree
          antialias
          id="my-canvas"
          handleMaskFade={this.props.handleMaskFade}
          sliderStatusValue={this.state.value}
          textures={this.props.textures}
        />

        <BusinessConsultingFrontThree
          antialias
          id="my-canvas"
          handleMaskFade={this.props.handleMaskFade}
          textures={this.props.textures}
          models={this.props.models}
        />

        <div className="bconsulting-box">
          {scrollState == 1 && <p className="bconsulting-paragraph"></p>}
          {scrollState == 2 && <p className="bconsulting-paragraph"></p>}
          {scrollState == 3 && <p className="bconsulting-paragraph"></p>}
          {/* <p className="bconsulting-paragraph">{this.texts[1]}</p>
            <p className="bconsulting-paragraph">{this.texts[2]}</p>
            <p className="bconsulting-paragraph">{this.texts[3]}</p> */}
        </div>
      </div>
    );
  }
}

//ResearchAndDevelopment.contextType = OnceContext;

export function startScreen() {
  videoRD = document.getElementById("video-r-and-d");
  videoRDRevert = document.getElementById("video-r-and-d-r");

  if (!videoRD || !videoRDRevert || videoRD.readyState !== 4 || videoRDRevert.readyState !== 4) {
    throw Error("Problem with video resource");
  }
  if (isDesktop()) {
  }
}
