import { Scene, ArcRotateCamera, Vector3, FreeCamera } from "@babylonjs/core";
// import { createPlatonicScene } from "../scenes/PlatonicScene";
// import { createHomeScene } from "../scenes/HomeScene";
// import { createSolutionsScene } from "../scenes/SolutionsScene";
// import { createAcademyScene } from "../scenes/AcademyScene";
// import { createBusinessConsultingScene } from "../scenes/BusinessConsultingScene";
// import { createPixels2PixelsScene } from "../scenes/Pixels2PixelsScene";
//import { createResearchAndDevelopmentScene } from "../scenes/ResearchAndDevelopmentScene";
// import SceneHook from "../hooks/SceneHook/SceneHook";
import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Home from "../components/Home";
import { WhoWeAre } from "../components/WhoWeAre";
import { Pixels2Pixels } from "../components/Pixels2Pixels";
import { BusinessConsulting } from "../components/BusinessConsulting";
import { Solutions } from "../components/Solutions";
import { Academy } from "../components/Academy";
import { ResearchAndDevelopment } from "../components/ResearchAndDevelopment";
import CustomRouter from "../routes/CustomRouter";
import history from "../routes/history";
import { DeviceType, getDeviceType } from "../util/DeviceType";
import Selection from "../components/Selection";
import OurWork from "../components/OurWork";
// import MaskTransition from "../components/MaskTransition";
import MaskTransitionNew from "../components/MaskTransitionNew";
import Mask from "../components/mask";
import * as THREE from "three";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import SoundButton from "../components/SoundButton";

//Textures
//Pixels
import sceneBackPixels from "../assets/newScenes/textures/pixels2pixels.webp";
import sceneBackPORTRAITPixels from "../assets/newScenes/textures/mobile/pixeliPortrait.webp";
import sceneBackLANDSCAPEPixels from "../assets/newScenes/textures/mobile/pixeliLandscape.webp";

//RnD
import sceneBackRnD from "../assets/newScenes/textures/r-d-new.webp";
import sceneBackPortraitRnD from "../assets/newScenes/textures/mobile/randdPortrait.webp";
import sceneBackLandscapeRnD from "../assets/newScenes/textures/mobile/randdLandscape.webp";
import planetTextureTestRnD from "../assets/newScenes/textures/planetar-d.webp";
import planetGlowTextureTestAtlasRnD from "../assets/newScenes/textures/earthGlow.webp";
import RND_ASSETS_0RnD from "../assets/newScenes/textures/r-d-uputstvo.webp";

import fbxPathFish from "../assets/newScenes/Fish1.fbx";
import fbxPathDiver from "../assets/newScenes/Ronilac.fbx";
import fishTextureWebp from "../assets/newScenes/textures/WebPFish.webp";
import NewLoadingPage2 from "../components/NewLoadingPage2";

//Academy
import sceneBackMobileLandscape from "../assets/newScenes/textures/mobile/academyMLandscape.webp";
import academyHiresTexture from "../assets/newScenes/textures/akademijahires.webp";
import academyHiresTexturePortrait from "../assets/newScenes/textures/mobile/academyPortrait.webp";

//Solutions
import planetGlowTextureTestCastleSolutions from "../assets/newScenes/textures/maskazamak.webp";
import sceneBackSolutions from "../assets/newScenes/textures/solutionnew-1.webp";
import solutionportret from "../assets/newScenes/textures/mobile/solutionportret.webp";
import back_asset_0Solutions from "../assets/newScenes/textures/changeme.webp";
import back_asset_1Solutions from "../assets/newScenes/textures/hover.webp";

//Business
import sceneBackBusiness from "../assets/newScenes/textures/biznisbg.webp";
import sceneBackMobileBusiness from "../assets/newScenes/textures/mobile/natinlepiBiznis.webp";
import sceneBackMobileLandscapeBusiness from "../assets/newScenes/textures/mobile/nataBiznisLandscape.webp";
import fishTextureWebpBusiness from "../assets/newScenes/textures/WebPFish.webp";
import bublesx from "../assets/newScenes/textures/bubles.png";
import SoundsPlayer from "../components/SoundsPlayer";
import TestAudioPlayer from "../components/TestAudioPlayer";

export default function BabylonManager({ isClicked, handleSoundIdChange }) {
  const [textures, setTextures] = useState({});
  const [models, setModels] = useState({});
  const [resourcesLoaded, setResourcesLoaded] = useState(false);

  const [showComponentMask, setShowComponentMask] = useState(null);
  const [playSound, setPlaySound] = useState(true);

  function handleToggleComponentMask(isTrue = false) {
    setShowComponentMask(isTrue);
    // setShowComponentMask(showComponentMask => !showComponentMask);
  }
  useEffect(() => {
    if (resourcesLoaded) {
      console.log("Resources Loaded!");
    }
  }, [resourcesLoaded]);

  useEffect(() => {
    const textureLoader = new THREE.TextureLoader();
    const texturePromises = [
      new Promise((resolve) => {
        textureLoader.load(fishTextureWebp, (texture) => {
          setTextures((textures) => ({ ...textures, texture1: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackPixels, (texture) => {
          setTextures((textures) => ({ ...textures, texturePixelsBack: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackPORTRAITPixels, (texture) => {
          setTextures((textures) => ({ ...textures, texturePixelsPortrait: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackLANDSCAPEPixels, (texture) => {
          setTextures((textures) => ({ ...textures, texturePixelsLandscape: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackRnD, (texture) => {
          setTextures((textures) => ({ ...textures, textureRnDBack: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackPortraitRnD, (texture) => {
          setTextures((textures) => ({ ...textures, textureRnDPortrait: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackLandscapeRnD, (texture) => {
          setTextures((textures) => ({ ...textures, textureRnDLandscape: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(planetTextureTestRnD, (texture) => {
          setTextures((textures) => ({ ...textures, planetRnD: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(planetGlowTextureTestAtlasRnD, (texture) => {
          setTextures((textures) => ({ ...textures, planetGlowRnD: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackMobileLandscape, (texture) => {
          setTextures((textures) => ({ ...textures, academyBackMobileLandscape: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(planetGlowTextureTestCastleSolutions, (texture) => {
          setTextures((textures) => ({ ...textures, planetGlowTextureTestCastleSolutionsP: texture }));
          resolve();
        });
      }),
      //solutions
      new Promise((resolve) => {
        textureLoader.load(sceneBackSolutions, (texture) => {
          setTextures((textures) => ({ ...textures, sceneBackSolutionsP: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(solutionportret, (texture) => {
          setTextures((textures) => ({ ...textures, sceneBackSolutionsPortraitP: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(back_asset_0Solutions, (texture) => {
          setTextures((textures) => ({ ...textures, back_asset_0SolutionsP: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(back_asset_1Solutions, (texture) => {
          setTextures((textures) => ({ ...textures, back_asset_1SolutionsP: texture }));
          resolve();
        });
      }),
      //Business
      new Promise((resolve) => {
        textureLoader.load(sceneBackBusiness, (texture) => {
          setTextures((textures) => ({ ...textures, sceneBackBusinessP: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackMobileBusiness, (texture) => {
          setTextures((textures) => ({ ...textures, sceneBackMobileBusinessP: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(sceneBackMobileLandscapeBusiness, (texture) => {
          setTextures((textures) => ({ ...textures, sceneBackMobileLandscapeBusinessP: texture }));
          resolve();
        });
      }),
      //Academy
      new Promise((resolve) => {
        textureLoader.load(academyHiresTexture, (texture) => {
          setTextures((textures) => ({ ...textures, academyHiresTextureP: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(academyHiresTexturePortrait, (texture) => {
          setTextures((textures) => ({ ...textures, academyHiresTexturePortraitP: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(fishTextureWebpBusiness, (texture) => {
          setTextures((textures) => ({ ...textures, fishTextureWebpBusinessPp: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(RND_ASSETS_0RnD, (texture) => {
          setTextures((textures) => ({ ...textures, RND_ASSETS_0RnDpP: texture }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        textureLoader.load(bublesx, (texture) => {
          setTextures((textures) => ({ ...textures, bublesxP: texture }));
          resolve();
        });
      }),
    ];

    // Load models
    const fbxLoader = new FBXLoader();
    const modelPromises = [
      new Promise((resolve) => {
        fbxLoader.load(fbxPathFish, (model) => {
          setModels((models) => ({ ...models, modelFish: model }));
          resolve();
        });
      }),
      new Promise((resolve) => {
        fbxLoader.load(fbxPathDiver, (model) => {
          setModels((models) => ({ ...models, modelDiver: model }));
          resolve();
        });
      }),
    ];

    // Wait for all resources to load
    Promise.all([...texturePromises, ...modelPromises]).then(() => {
      setResourcesLoaded(true);
    });
  }, []);

  useEffect(() => {
    // Load textures
    let containerWrap = document.querySelector(".container-wrap");
    function onMouseMove(event) {
      if (containerWrap) {
        containerWrap.style.cssText = `
          left: calc(50vw - ${10 * (event.x / window.innerWidth - 0.5)}px);
          top : calc(50vh - ${10 * (event.y / window.innerHeight - 0.5)}px);`;
      } else {
        containerWrap = document.querySelector(".container-wrap");
      }
    }
    // window.addEventListener("mousemove", onMouseMove);
    return () => {
      //  window.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  function handlePlaySound() {
    setPlaySound((playSound) => !playSound);
  }

  return (
    <div className="container-wrap">
      {/* <Mask triggerFade={showComponentMask} /> */}
      <div className="scene-wrap">
        {resourcesLoaded ? (
          <div className="scene-wrap">
            <SoundButton handlePlaySound={handlePlaySound} activate={isClicked} />
            <MaskTransitionNew triggerFade={showComponentMask} />
            {/* <Mask triggerFade={showComponentMask} /> */}
            <CustomRouter history={history}>
              {isClicked && <TestAudioPlayer playSound={playSound} />}
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route
                  exact
                  path="/selection-page"
                  element={<Selection handleMaskFade={handleToggleComponentMask} />}
                />
                <Route exact path="/who-we-are" element={<WhoWeAre handleMaskFade={handleToggleComponentMask} />} />
                <Route exact path="/our-work" element={<OurWork />} />
                <Route
                  exact
                  path="/pixels-2-pixels"
                  element={
                    <Pixels2Pixels
                      handleMaskFade={handleToggleComponentMask}
                      textures={textures}
                      models={models}
                      setSounds={handleSoundIdChange}
                    />
                  }
                />
                <Route
                  exact
                  path="/business-consulting"
                  element={
                    <BusinessConsulting
                      handleMaskFade={handleToggleComponentMask}
                      textures={textures}
                      models={models}
                      setSounds={handleSoundIdChange}
                    />
                  }
                />
                <Route
                  exact
                  path="/solutions"
                  element={
                    <Solutions
                      handleMaskFade={handleToggleComponentMask}
                      textures={textures}
                      models={models}
                      setSounds={handleSoundIdChange}
                    />
                  }
                />
                <Route
                  exact
                  path="/academy"
                  element={
                    <Academy
                      handleMaskFade={handleToggleComponentMask}
                      textures={textures}
                      setSounds={handleSoundIdChange}
                    />
                  }
                />
                <Route
                  exact
                  path="/research-and-development"
                  element={
                    <ResearchAndDevelopment
                      handleMaskFade={handleToggleComponentMask}
                      textures={textures}
                      models={models}
                      setSounds={handleSoundIdChange}
                    />
                  }
                />
              </Routes>
            </CustomRouter>
          </div>
        ) : (
          <div style={{ position: "absolute", height: "100%", width: "100%" }}>
            <NewLoadingPage2 />
          </div>
        )}
      </div>
    </div>
  );
}

const Scenes = {
  Home: "home",
  WhoWeAre: "who-we-are",
  OurOrganization: "our-organization",
  // Contact: "contact",
  Solutions: "solutions",
  BusinessConsulting: "business-consulting",
  Pixels2Pixels: "pixels-2-pixels",
  Academy: "academy",
  ResearchAndDevelopment: "research-and-development",
};
