import React, { useState, useEffect, useContext, useRef } from "react";
import history from "../routes/history";
import "../style/home.scss";
import KockaHomePage from "./KockaHomePage";
import Logo from "./Logo";
import NewContact from "./NewContact";
import NewOurWork from "./NewOurWork";
import { Stage } from "@pixi/react";
import { TextureContext } from "../context/OnceContext";
import New3dCube from "./New3dCube";
import { isPhone } from "../util/DeviceType";

export default function Home({}) {
  const [hideUI, setHideUI] = useState(false);
  // const [textures, setTextures] = useState([]);
  const [contactButtonTop, setContactButtonTop] = useState(0);
  const [showContact, setShowContact] = useState(false);
  const [usedFromHomeP, setUsedFromHomeP] = useState(true);
  const [clicked, setClicked] = useState(false);

  const canvasRef2D = useRef(null);
  let animate = true;
  let stars = [];
  let star;
  let i;
  let canvas;
  let c;

  //  const tc = useContext(TextureContext);

  const handleRouteNextPage = () => {
    document.getElementById("home").style.opacity = "0";
    setTimeout(() => {
      history.push("/selection-page");
    }, 1500);
  };

  useEffect(() => {
    const home = document.getElementById("home");
    home.style.opacity = "1";

    setUsedFromHomeP(true);
    canvas = canvasRef2D.current;
    if (!canvas) {
      return;
    }

    createStarrySky();

    function createStarrySky() {
      c = canvas.getContext("2d");

      let numStars = 1900;
      let radius = "0." + Math.floor(Math.random() * 9) + 1;
      let focalLength = canvas.width * 2;
      let warp = 0;
      let centerX, centerY;

      let isWarping = false;

      initializeStars();

      function executeFrame() {
        if (animate) requestAnimationFrame(executeFrame);
        moveStars();
        drawStars();
      }

      function initializeStars() {
        centerX = canvas.width / 2;
        centerY = canvas.height / 2;

        stars = [];
        for (i = 0; i < numStars; i++) {
          star = {
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            z: Math.random() * canvas.width,
            o: "0." + Math.floor(Math.random() * 99) + 1,
          };
          stars.push(star);
        }
      }

      function moveStars() {
        if (!star) return;
        for (i = 0; i < numStars; i++) {
          star = stars[i];
          star.z--;

          if (star.z <= 0) {
            star.z = canvas.width;
          }
        }
      }

      function drawStars() {
        var pixelX, pixelY, pixelRadius;
        if (!canvas) return;

        // Resize to the screen
        if (canvas.width != window.innerWidth || canvas.width != window.innerWidth) {
          canvas.width = window.innerWidth;
          canvas.height = window.innerHeight;
          initializeStars();
        }
        if (isWarping == 0) {
          c.fillStyle = "rgba(0,10,20,1)";
          c.fillRect(0, 0, canvas.width, canvas.height);
        }
        c.fillStyle = "rgba(209, 255, 255, " + radius + ")";
        for (i = 0; i < numStars; i++) {
          star = stars[i];

          pixelX = (star.x - centerX) * (focalLength / star.z);
          pixelX += centerX;
          pixelY = (star.y - centerY) * (focalLength / star.z);
          pixelY += centerY;
          pixelRadius = (isPhone() ? 0.5 : 1) * (focalLength / star.z);

          c.fillRect(pixelX, pixelY, pixelRadius, pixelRadius);
          c.fillStyle = "rgba(209, 255, 255, " + star.o + ")";
          //c.fill();
        }
      }

      document.getElementById("warp").addEventListener("click", function (e) {
        isWarping = isWarping == 1 ? 0 : 1;
        c.clearRect(0, 0, canvas.width, canvas.height);
        executeFrame();
      });

      executeFrame();
    }
    return () => {
      console.log("return");
      // console.log(animate)
      animate = null;
      canvasRef2D.current = null;
      stars = [];
      star = null;
      i = null;
      canvas = null;
      c = null;
    };
  }, []);

  const handleShowContact = () => {
    setShowContact((prevState) => !prevState);
  };

  return (
    <div className="home" id="home">
      <div id="homepage-content">
        <Logo url={"/assets/sajtLogos/TalasonLogo.png"} />
        <button
          id="warp"
          className="explore-button"
          onClick={() => {
            handleRouteNextPage();
          }}
        >
          explore
        </button>
        <div className="homepage-slogan-wrapper" style={{ top: `${window.outerHeight / 2}`, pointerEvents: "none" }}>
          <img src="/assets/parola.webp" />
        </div>

        {/* <Stage width={window.innerWidth} height={window.innerHeight}>
          <KockaHomePage textures={textures} />
        </Stage> */}

        {/* <button
          className="show-contact-button"
          style={{ top: tc.landscapePhoneQuery ? "0" : `${contactButtonTop}px` }}
          onClick={handleShowContact}
        >
          Contact
        </button> */}

        <NewOurWork />
        <NewContact />
        {/* <New3dCube usedFromHome={usedFromHomeP} />
         */}

        <div>
          <div className="reactCanvas" style={{ position: "absolute", top: "0vh", zIndex: "-50" }}>
            <canvas id="space" ref={canvasRef2D}></canvas>
            <div id="w"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
