export const whowearePictograms = [
  {
    id: 1,
    url: "/assets/pictograms/vision.svg",
  },
  { id: 2, url: "/assets/pictograms/mision.svg" },
  { id: 3, url: "/assets/pictograms/strategy.svg" },
];

export const solutionsPictograms = [
  {
    id: 1,
    url: "/assets/pictograms/Home.svg",
  },
  {
    id: 2,
    url: "/assets/pictograms/managed_service.svg",
  },
  { id: 3, url: "/assets/pictograms/cybersecurity_services.svg" },
  { id: 4, url: "/assets/pictograms/professional_services.svg" },
  {
    id: 5,
    url: "/assets/pictograms/web_development.svg",
  },
  {
    id: 6,
    url: "/assets/pictograms/software_engineering.svg",
  },
  {
    id: 7,
    url: "/assets/pictograms/game_development.svg",
  },
  {
    id: 8,
    url: "/assets/pictograms/custom_made_ar_and_vr_solutions.svg",
  },
];

export const pixels2pixelsPictograms = [
  {
    id: 1,
    url: "/assets/pictograms/Home.svg",
  },
  {
    id: 2,
    url: "/assets/pictograms/mixed_reality.svg",
  },
  { id: 3, url: "/assets/pictograms/3d_visualization.svg" },
  { id: 4, url: "/assets/pictograms/3d_animation.svg" },
  { id: 5, url: "/assets/pictograms/graphic_design.svg" },
  { id: 6, url: "/assets/pictograms/digital_marketing.svg" },
];

export const businessConsultingPictograms = [
  {
    id: 1,
    url: "/assets/pictograms/Home.svg",
  },
  {
    id: 2,
    url: "/assets/pictograms/projects.svg",
  },
  { id: 3, url: "/assets/pictograms/consulting.svg" },
  { id: 4, url: "/assets/pictograms/ditigal_transformation.svg" },
];

export const academyPictogram = [
  {
    id: 1,
    url: "/assets/pictograms/Home.svg",
  },

  { id: 2, url: "/assets/pictograms/academycertificatepictogram.svg" },
];

export const rndPictogram = [
  { id: 1, url: "/assets/pictograms/Home.svg" },

  { id: 2, url: "/assets/pictograms/blockchainpictogramfor-r-and-d.svg" },
];
