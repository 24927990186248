import React from "react";
import { isPhone } from "../util/DeviceType.js";

export class FullscreenHelp extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isMobile: false,
            isFullscreen: false,
        };
    }

    componentDidMount() {
        this.setState({ isMobile: isPhone() });
    }

    fullscreen() {
        if (this.state.isFullscreen) {
            this.closeFullscreen()
            return
        }
        this.openFullscreen()
        window.screen.orientation.lock('portrait');
    }

    openFullscreen() {
        let elemToFullscreen = document.documentElement;
        if (elemToFullscreen.mozRequestFullScreen) {
            /* Firefox */
            elemToFullscreen.mozRequestFullScreen();
            this.setState({ isFullscreen: true });
        } else if (elemToFullscreen.webkitRequestFullscreen) {
            /* Chrome, Safari and Opera */
            elemToFullscreen.webkitRequestFullscreen();
            this.setState({ isFullscreen: true });
        } else if (elemToFullscreen.msRequestFullscreen) {
            /* IE/Edge */
            elemToFullscreen.msRequestFullscreen();
            this.setState({ isFullscreen: true });
        } else if (elemToFullscreen.requestFullscreen) {
            elemToFullscreen.requestFullscreen();
            this.setState({ isFullscreen: true });
        }
    }

    closeFullscreen() {
        if (document.mozCancelFullScreen) {
            /* Firefox */
            document.mozCancelFullScreen();
            this.setState({ isFullscreen: false });
        } else if (document.webkitExitFullscreen) {
            /* Chrome, Safari and Opera */
            document.webkitExitFullscreen();
            this.setState({ isFullscreen: false });
        } else if (document.msExitFullscreen) {
            /* IE/Edge */
            document.msExitFullscreen();
            this.setState({ isFullscreen: false });
        } else if (document.exitFullscreen) {
            document.exitFullscreen();
            this.setState({ isFullscreen: false });
        }
    }

    render() {
        const { isMobile, isFullscreen } = this.state;

        return (
            <div className="frontpagetext">
                <button className="fullscreen-button" onClick={() => this.fullscreen('portrait')}>
                    {isMobile && isFullscreen &&
                        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                            <g id="SVGRepo_iconCarrier"> <g id="style=stroke"> <g id="windowed"> <path id="vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M7.75 5C7.75 6.51878 6.51878 7.75 5 7.75H2C1.58579 7.75 1.25 7.41421 1.25 7C1.25 6.58579 1.58579 6.25 2 6.25H5C5.69036 6.25 6.25 5.69036 6.25 5V2C6.25 1.58579 6.58579 1.25 7 1.25C7.41421 1.25 7.75 1.58579 7.75 2V5Z" fill="#ffffff" /> <path id="vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M19 7.75C17.4812 7.75 16.25 6.51878 16.25 5L16.25 2C16.25 1.58579 16.5858 1.25 17 1.25C17.4142 1.25 17.75 1.58579 17.75 2L17.75 5C17.75 5.69036 18.3096 6.25 19 6.25L22 6.25C22.4142 6.25 22.75 6.58579 22.75 7C22.75 7.41421 22.4142 7.75 22 7.75L19 7.75Z" fill="#ffffff" /> <path id="vector (Stroke)_3" fillRule="evenodd" clipRule="evenodd" d="M16.25 19C16.25 17.4812 17.4812 16.25 19 16.25L22 16.25C22.4142 16.25 22.75 16.5858 22.75 17C22.75 17.4142 22.4142 17.75 22 17.75L19 17.75C18.3096 17.75 17.75 18.3096 17.75 19L17.75 22C17.75 22.4142 17.4142 22.75 17 22.75C16.5858 22.75 16.25 22.4142 16.25 22L16.25 19Z" fill="#ffffff" /> <path id="vector (Stroke)_4" fillRule="evenodd" clipRule="evenodd" d="M7.75 19C7.75 17.4812 6.51878 16.25 5 16.25L2 16.25C1.58579 16.25 1.25 16.5858 1.25 17C1.25 17.4142 1.58579 17.75 2 17.75L5 17.75C5.69036 17.75 6.25 18.3096 6.25 19L6.25 22C6.25 22.4142 6.58579 22.75 7 22.75C7.41421 22.75 7.75 22.4142 7.75 22L7.75 19Z" fill="#ffffff" /> </g> </g> </g>
                        </svg>
                    }
                    {isMobile && !isFullscreen &&
                        <svg width="40px" height="40px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#ffffff">
                            <g id="SVGRepo_iconCarrier"> <g id="style=stroke"> <g id="fullscreen"> <path id="vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M1.25 4C1.25 2.48122 2.48122 1.25 4 1.25H8C8.41421 1.25 8.75 1.58579 8.75 2C8.75 2.41421 8.41421 2.75 8 2.75H4C3.30964 2.75 2.75 3.30964 2.75 4V8C2.75 8.41421 2.41421 8.75 2 8.75C1.58579 8.75 1.25 8.41421 1.25 8V4Z" fill="#ffffff" /> <path id="vector (Stroke)_2" fillRule="evenodd" clipRule="evenodd" d="M20 1.25C21.5188 1.25 22.75 2.48122 22.75 4L22.75 8C22.75 8.41421 22.4142 8.75 22 8.75C21.5858 8.75 21.25 8.41421 21.25 8L21.25 4C21.25 3.30964 20.6904 2.75 20 2.75L16 2.75C15.5858 2.75 15.25 2.41421 15.25 2C15.25 1.58579 15.5858 1.25 16 1.25L20 1.25Z" fill="#ffffff" /> <path id="vector (Stroke)_3" fillRule="evenodd" clipRule="evenodd" d="M22.75 20C22.75 21.5188 21.5188 22.75 20 22.75L16 22.75C15.5858 22.75 15.25 22.4142 15.25 22C15.25 21.5858 15.5858 21.25 16 21.25L20 21.25C20.6904 21.25 21.25 20.6904 21.25 20L21.25 16C21.25 15.5858 21.5858 15.25 22 15.25C22.4142 15.25 22.75 15.5858 22.75 16L22.75 20Z" fill="#ffffff" /> <path id="vector (Stroke)_4" fillRule="evenodd" clipRule="evenodd" d="M1.25 20C1.25 21.5188 2.48122 22.75 4 22.75L8 22.75C8.41421 22.75 8.75 22.4142 8.75 22C8.75 21.5858 8.41421 21.25 8 21.25L4 21.25C3.30964 21.25 2.75 20.6904 2.75 20L2.75 16C2.75 15.5858 2.41421 15.25 2 15.25C1.58579 15.25 1.25 15.5858 1.25 16L1.25 20Z" fill="#ffffff" /> </g> </g> </g>
                        </svg>
                    }
                    {isFullscreen}
                </button>
                <div className="landscape-overlay">
                    <p className="landscape-overlay-text">
                        Please rotate phone or click on button
                    </p>
                </div>
            </div>
        );
    }
}