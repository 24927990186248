import React from "react";

class NewScrollBar extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.props.onChange(parseFloat(event.target.value));
  }

  render() {
    const value = this.props.value;
    const min = this.props.min;
    const max = this.props.max;
    const step = this.props.step;

    return (
      <input
        type="range"
        min={min}
        max={max}
        step={step}
        value={value}
        onChange={this.handleChange}
        style={{
          WebkitAppearance: "none",
          height: "100%",
          width: "10px",
          marginTop: "auto",
          marginBottom: "auto",
          marginRight: "auto",
          marginLeft: "auto",
          backgroundColor: "#ddd",
          borderRadius: "5px",
          outline: "none",
          opacity: 0.7,
          transition: "opacity 0.2s ease-in-out"
        }}
        />
    );
  }
}

export default NewScrollBar;
