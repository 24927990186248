// import React from "react";
// import { Link } from "react-router-dom";
// import "../style/research-and-development.scss";
// import rdAnimationMp4 from "../assets/videos/R&D.mp4";
// import rdAnimationMp4R from "../assets/videos/R&D_r.mp4";
// import { uiVisibility, scrollTo, handleSwipeVisibility, handleScrollIconVisibility } from "../util/UIHelper.js";
// import pix2pixPng from "./../assets/images/our-organisation-logos/Harmonity_Pixels2Pixels_Logo_White_Transparent.png";
// import Logo from "./Logo";
// import { OnceContext } from "../context/OnceContext";
// import { HorisontalScrollbar } from "./HorisontalScrollbar";
// import Swipe from "./swipe";
// import Hand from "./hand";
// import NewScrollbar from "./newscrollbar";
// import history from "../routes/history";
// import { isDesktop, isPhone } from "../util/DeviceType";
// import VerticalScrollIcon from "./verticalscrollicon";
import React from "react";

import "../style/business-consulting.scss";
import "../style/newPixels.scss";
import "../style/NewSlider.scss";

import Logo from "./Logo";

// import ResearchAndDevelopmentThree from "./ResearchAndDevelopmentThree";
import busin from "./ResearchAndDevelopment2Three";
import Pixels2PixelsBackThree from "./Pixels2PixelsBackThree";
import Pixels2PixelsFrontThree from "./Pixels2PixelsFrontThree";

//import { changeScene, pointerWheelEventPaused } from "./../scenes/ResearchAndDevelopmentScene";
import history from "../routes/history";

import { isDesktop, isLaptop, isPhone } from "../util/DeviceType";
import NewContact from "./NewContact";
import { pixels2pixelsPictograms } from "../copy/pictograms";
import { pixels2pixelsTexts } from "../copy/videotexts";
import Pictograms from "./Pictograms";
import TextBox from "./TextBox";
import { throttle } from "../util/UIHelper";

let setCanvasWidth = 1920 / 1.65;
let setCanvasHeight = 1080 / 1.65;

const VIDEO_PAUSE_TIME = {
  RD: 3,
  NFT: 4.6,
  IOT: 6.9,
  BS: 9.33,
  ES: 12,
  CC: 15.9,
};
const VIDEO_PAUSE_TIME_REVERT = {
  CC: 1.9,
  ES: 5.8,
  BS: 8.4,
  IOT: 10.9,
  NFT: 13.2,
  RD: 14.8,
};
const VIDEO_DELAY = 50;
let videoRD;
let videoRDRevert;
let scrollPaused = true;

export class Pixels2Pixels extends React.Component {
  texts = {
    1: `Pixels2Pixels is Harmonity Group’s creative part, which creates supreme visual content and digital products, helping you communicate your brand and work to clients.`,
    2: `We provide comprehensive digital solutions, including mixed reality, 3D visualization, 3D animation, graphic design, and digital marketing services.`,
    3: `Be sure you are one step ahead of everyone. Discover how we can give you a competitive edge.`,
  };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      activeText: 1,
      showContact: false,
      activeId: 0,
      isMobile: false,
      showSwipe: true,
      swipeStart: 0,
      swipeEnd: 0,
      currentScreenState: 1,
      value: 0,
      startY: null,
      direction: null,
      disableScrolling: null,
      pageId: "pixels",
    };

    this.handleScroll = (event) => {
      const direction = event.deltaY > 0 ? 1 : -1; // Positive value means scrolling down, negative means scrolling up

      if (direction > 0) {
        this.handleOpeningNextPage();
      } else if (direction < 0) {
        this.handleOpeningNextPageUP();
      }
    };

    this.throttledScroll = throttle(this.handleScroll, 1500);

    this.handleChange = (event) => {
      this.setState({ value: event.target.value });
    };
    if (!isPhone()) {
      window.addEventListener("wheel", this.throttledScroll);
      //window.addEventListener("scroll", this.handleScroll);
    }
    // window.addEventListener("touchmove", this.handleScroll, { passive: false });
  }

  switchOffPictogram = (value) => {
    this.setState({ switchOffPictogram: value });
  };

  toggleActiveText = (id) => {
    this.setState({ activeText: id });
  };

  handleShowContact = () => {
    this.setState(function (prevState, props) {
      return { showContact: !prevState.showContact };
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.disableScrolling !== this.state.disableScrolling) {
      console.log("trigered");
      setTimeout(() => {
        this.setState((prevState, prevProps) => {
          if (prevState.disableScrolling) {
            return { disableScrolling: false };
          }
          return null;
        });
      }, 1000);
    }

    if (prevState.value !== this.state.value) {
      if (this.state.value === 100) {
        this.setState({ activeText: 0 });
        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          // this.props.handleMaskFade(true);
          //window.removeEventListener("wheel", this.handleScroll);
          // window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

          setTimeout(() => {
            history.push("/selection-page");
          }, 1501);
        }, 1000);
      }
    }
  }
  handleMoveScroll(direction) {
    let nextScreenState = this.state.currentScreenState;
    if (direction) {
      nextScreenState--;
    } else {
      nextScreenState++;
    }
    if (nextScreenState > 3) {
      nextScreenState = 3;
    } else if (nextScreenState < 1) {
      nextScreenState = 1;
    }
    this.setState({
      currentScreenState: nextScreenState,
    });
  }

  componentDidMount() {
    this.setState({ isMobile: this.context.matches });
    //  this.playSound();
  }

  componentWillUnmount() {
    this.setState({
      disableScrolling: null,
    });

    window.removeEventListener("wheel", this.throttledScroll);
    //  window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("touchmove", this.handleScroll, { passive: false });
    // this.stopSound();
  }

  ///////////////
  handleMouseDown = (e) => {
    this.setState({
      startY: e.clientY,
    });
  };

  handleMouseMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down" && this.state.startY) {
        this.handleOpeningNextPage();
      } else if (direction == "up" && this.state.startY) {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleMouseUp = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };

  handleTouchStart = (e) => {
    this.setState({
      startY: e.touches[0].clientY,
    });
  };

  handleTouchMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.touches[0].clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down" && this.state.startY) {
        this.handleOpeningNextPage();
      } else if (direction == "up" && this.state.startY) {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleOpeningNextPage() {
    console.log(this.state.disableScrolling);
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 0) {
        this.setBulletValue(16.66);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 16.66) {
        this.setBulletValue(33.33);
        this.setState({
          activeText: 3,
        });
      } else if (this.state.value == 33.33) {
        this.setBulletValue(49.99);
        this.setState({
          activeText: 4,
        });
      } else if (this.state.value == 49.99) {
        this.setBulletValue(66.65);
        this.setState({
          activeText: 5,
        });
      } else if (this.state.value == 66.65) {
        this.setBulletValue(83.31);
        this.setState({
          activeText: 6,
        });
      } else if (this.state.value == 83.31) {
        this.setBulletValue(100);
        this.setState({
          activeText: 0,
        });

        window.removeEventListener("wheel", this.handleScroll);
        window.removeEventListener("scroll", this.handleScroll);

        setTimeout(() => {
          this.state.currentScreenState = 1;
        }, 1000);

        setTimeout(
          () => {
            history.push("/selection-page");
          },
          isPhone() ? 1200 : 1501
        );
      }
    }
  }

  handleOpeningNextPageUP() {
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 16.66) {
        this.setBulletValue(0);
        this.setState({
          value: 0,
          activeText: 1,
        });
      } else if (this.state.value == 33.33) {
        this.setBulletValue(16.66);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 49.99) {
        this.setBulletValue(33.33);
        this.setState({
          activeText: 3,
        });
      } else if (this.state.value == 66.65) {
        this.setBulletValue(49.99);
        this.setState({
          activeText: 4,
        });
      } else if (this.state.value == 83.31) {
        this.setBulletValue(66.65);
        this.setState({
          activeText: 5,
        });
      }
    }
  }

  // playSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("pixels");
  // }

  // stopSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("nan");
  //   setSounds.setStopSounds("pixels");
  // }

  handleTouchEnd = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };
  //////////
  handleRouteNextPage = () => {
    setTimeout(() => {
      history.push("/selection-page");
    }, 500);
  };

  setBulletValue = (val) => {
    this.setState((prevState, prevProps) => {
      if ((!prevState.value != val || val == 0) && !prevState.disableScrolling) {
        return { value: val };
      }
      return null;
    });

    this.setState((prevState, prevProps) => {
      if (!prevState.disableScrolling) {
        return { disableScrolling: true };
      }
      return null;
    });
  };

  handleClickCircle(bulletValue, activeText) {
    console.log(this.state.value);
    if (isPhone()) {
      if (!this.state.disableScrolling) {
        this.setBulletValue(bulletValue);
        this.setState({
          activeText: activeText,
        });
      }
    }
  }
  //
  render() {
    const { isMobile, activeId, value } = this.state;
    return (
      <div className="research-and-development" id="research-and-development">
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <div className="pipe-container">
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={value}
                onChange={this.handleChange}
                className="pipe-input"
              />
              <div className="pipe"></div>
              <div className="fill" style={{ height: `${value}%` }} />
              <div
                className="pulsating-circle"
                style={{ top: `${value}%` }}
                // onMouseDown={this.handleMouseDown}
                // onMouseMove={this.handleMouseMove}
                // onMouseUp={this.handleMouseUp}
                // onTouchStart={this.handleTouchStart}
                // onTouchMove={this.handleTouchMove}
                // onTouchEnd={this.handleTouchEnd}
              />
              <div className="input-start">
                <p>Start</p>
              </div>
              <div className="input-end">
                <p>Next</p>
              </div>
              <div className="line-zero-Pixels" onClick={() => this.handleClickCircle(0, 1)}></div>
              <div className="line-first-Pixels" onClick={() => this.handleClickCircle(16.66, 2)}></div>
              <div className="line-second-Pixels" onClick={() => this.handleClickCircle(33.33, 3)}></div>
              <div className="line-third-Pixels" onClick={() => this.handleClickCircle(49.99, 4)}></div>
              <div className="line-fourth-Pixels" onClick={() => this.handleClickCircle(66.65, 5)}></div>
              <div className="line-fifth-Pixels" onClick={() => this.handleClickCircle(83.31, 6)}></div>
              <div className="line-sixth-Pixels" onClick={() => this.handleClickCircle(100, 0)}></div>
            </div>
          </div>
        </div>
        <TextBox
          activeText={this.state.activeText}
          activeTextContent={pixels2pixelsTexts[this.state.activeText]}
          toggleActiveText={this.toggleActiveText}
        />
        <Pictograms
          toggleActiveText={this.toggleActiveText}
          activeText={this.state.activeText}
          pictograms={pixels2pixelsPictograms}
          bulletValue={this.setBulletValue}
          pageId={this.state.pageId}
        />

        <NewContact />

        <Logo
          url={"/assets/sajtLogos/Harmonity_Pixels2Pixels_Logo_White_Transparent.png"}
          passedFunction={this.handleRouteNextPage}
        />
        <Pixels2PixelsBackThree
          antialias
          id="my-canvas"
          handleMaskFade={this.props.handleMaskFade}
          textures={this.props.textures}
          sliderStatusValue={this.state.value}
        />
        {/* <div className="rndVideoDiv" style={{ position: "relative", left: "51%", top: "50%", position: "fixed", transform: "translate(-50%, -50%)" }}>
          <video id="video-player" playsInline muted loop autoPlay width={setCanvasWidth} height={setCanvasHeight} src={videoFile} />
        </div> */}
        <Pixels2PixelsFrontThree
          antialias
          id="my-canvas"
          handleMaskFade={this.props.handleMaskFade}
          textures={this.props.textures}
        />
        {/* <ResearchAndDevelopment2Three antialias id="my-canvas"/> */}
        {/* <TestPageGrass antialias id="my-canvas"/> */}
        {/* <div>
          <div className="pixelsNewN-texts">
            <div className="pixelsNewN-box">
            {this.state.currentScreenState == 1 && <p className="pixelsNewN-paragraph">{this.texts[1]}</p>}
            </div>
          </div>
          <div className="pixelsNewN-texts2">
            <div className="pixelsNewN-box2">
            {this.state.currentScreenState == 2 && <p className="pixelsNewN-paragraph2">{this.texts[2]}</p>}
            </div>
          </div>  
          <div className="pixelsNewN-texts3">
            <div className="pixelsNewN-box3">
            {this.state.currentScreenState == 3 && <p className="pixelsNewN-paragraph3">{this.texts[3]}</p>}
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

//ResearchAndDevelopment.contextType = OnceContext;

export function startScreen() {
  videoRD = document.getElementById("video-r-and-d");
  videoRDRevert = document.getElementById("video-r-and-d-r");

  if (!videoRD || !videoRDRevert || videoRD.readyState !== 4 || videoRDRevert.readyState !== 4) {
    throw Error("Problem with video resource");
  }
  if (isDesktop()) {
  }
}
