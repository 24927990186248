import { isDesktop } from "./DeviceType";

export function uiVisibility(elementId, visible) {
  const uiElement = document.getElementById(elementId);

  console.log("ui elem ", elementId);
  if (!uiElement) {
    console.error(`Can\`t change visibility of ${elementId} element.`);
    return;
  }

  if (visible) {
    uiElement.style.visibility = "visible";
    uiElement.style.opacity = 1;
  } else {
    uiElement.style.visibility = "hidden";
    uiElement.style.opacity = 0;
    uiElement.style.transition = "visibility 0.7s, opacity 0.7s linear";
  }
}

export function hideElement(element) {
  const uiElement = document.getElementById(element);
  uiElement.style.visibility = "hidden";
}

export function scrollTo(target) {
  if (isDesktop()) {
    const scroll = document.querySelector(".newscrollbar");
    scroll.style.top = target;
    scroll.style.transition = "top 0.5s linear";
    // window.scrollref.current.scroll({ top: target, left: 0 });
  }
}

export function scrollToHorisontal(target) {
  window.scrollref.current.scroll({ top: 0, left: target });
}

export function hideHtmlAnimations(animationElements) {
  let elements = Object.values(animationElements);
  elements.forEach((element) => {
    if (element) element.classList = ["animation"];
  });
}

export function startHtmlAnimation(animationName, isRevert, animationElements) {
  hideHtmlAnimations(animationElements);
  void animationElements[animationName].offsetWidth;
  animationElements[animationName].classList.add(animationName);
  animationElements[animationName].classList.add("visible");

  if (isRevert) animationElements[animationName].classList.add("revert-anim");
}

export function handleScrollIconVisibility() {
  uiVisibility("verticalscrollicon", false);
  Promise.resolve()
    .then(() => delay(1000))
    .then(() => uiVisibility("verticalscrollicon", true))
    .then(() => delay(9000))
    .then(() => uiVisibility("verticalscrollicon", false));
}

export async function handleSwipeVisibility(isHomepage) {
  uiVisibility("explore", false);

  if (isHomepage) {
    Promise.resolve()
      .then(() => uiVisibility("swipe", true))
      .then(() => delay(7000))
      .then(() => uiVisibility("swipe", false))
      .then(() => delay(1000))
      .then(() => uiVisibility("explore", true))
      .then(() => delay(4000))
      .then(() => uiVisibility("explore", false));
  } else {
    Promise.resolve()
      .then(() => uiVisibility("swipe", true))
      .then(() => delay(7000))
      .then(() => uiVisibility("swipe", false));
  }
}

function delay(duration) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration);
  });
}

export function throttle(fn, wait) {
  var time = Date.now();

  return function (event) {
    // we dismiss every wheel event with deltaY less than 4
    if (Math.abs(event.deltaY) < 4) return;

    if (time + wait - Date.now() < 0) {
      fn(event);
      time = Date.now();
    }
  };
}

// handleSwipeStart = (e) => {
//   this.setState({ swipeStart: e.targetTouches[0].clientX, swipeEnd: 0 });
// };

// handleSwipeEnd = (e) => {
//   this.setState({ swipeEnd: e.targetTouches[0].clientX });
// };

// handleTouchEnd = () => {
//   if (!this.state.swipeEnd || !this.state.swipeStart) return;
//   const isLeft = this.state.swipeEnd < this.state.swipeStart;
//   const isRight = this.state.swipeEnd > this.state.swipeStart;
//   if (isLeft) {
//     if (this.state.activeId === 2) {
//       this.setState({ activeId: 0 });
//       changeScene(0, 2);
//     } else {
//       this.setState({ activeId: this.state.activeId + 1 });
//       changeScene(this.state.activeId + 1, this.state.activeId);
//     }
//   }
//   if (isRight) {
//     if (this.state.activeId === 0) {
//       this.setState({ activeId: 2 });
//       changeScene(2, 0);
//     } else {
//       this.setState({ activeId: this.state.activeId - 1 });
//       changeScene(this.state.activeId - 1, this.state.activeId);
//     }
//   }
//   // todo - need to be improved
// };
