import { useEffect, useRef, useState, memo } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { gsap, Power1, Power2, Linear } from "gsap";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import * as dat from "dat.gui";
import * as TWEEN from "@tweenjs/tween.js";
import "../style/business-consulting.scss";
import history from "../routes/history";

import sceneBack from "../assets/newScenes/textures/biznisbg.webp";
import sceneBackMobile from "../assets/newScenes/textures/mobile/natinlepiBiznis.webp";
import sceneBackMobileLandscape from "../assets/newScenes/textures/mobile/nataBiznisLandscape.webp";

import { isPortrait, isLandscape, isLaptop, isDesktop, isPhone } from "../util/DeviceType";

export let scrollState = 1;
export let threeManager;
export default memo(
  ({
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    handleMaskFade,
    sliderStatusValue,
    textures,
    ...rest
  }) => {
    const reactCanvas = useRef(null);

    let texts = {
      1: `Our\u00A0Business\u00A0consulting\u00A0entity\u00A0provides\u00A0expert\u00A0guidance\u00A0in\u00A0enterprise\u00A0\nand\u00A0project\u00A0structure\u00A0setup\u00A0for\u00A0companies\u00A0seeking\u00A0to\u00A0improve\u00A0their\u00A0\nperformance,\u00A0operations,\u00A0and\u00A0profitability.`,
      2: `We\u00A0offer\u00A0Projects,\u00A0Consulting,\u00A0and\u00A0Digital\u00A0Transformation\u00A0\nservices\u00A0to\u00A0help\u00A0usinesses\u00A0achieve\u00A0their\u00A0goals\u00A0\nand\u00A0improve\u00A0their\u00A0operations.`,
      3: `With\u00A0knowledge\u00A0and\u00A0experience,\u00A0our\u00A0highly\u00A0skilled\u00A0consultants\u00A0\nwill\u00A0ensure\u00A0that\u00A0you\u00A0lay\u00A0the\u00A0foundations\u00A0for\u00A0a\u00A0thriving\u00A0enterprise.\u00A0\nTrust\u00A0us\u00A0with\u00A0your\u00A0success.`,
    };

    let textsForMobilePortrait = {
      1: `Our\u00A0Business\u00A0consulting\u00A0entity\u00A0provides\nexpert\u00A0guidance\u00A0in\u00A0enterprise\u00A0\nand\u00A0project\u00A0structure\u00A0setup\u00A0for\u00A0companies\nseeking\u00A0to\u00A0improve\u00A0their\u00A0\nperformance,\u00A0operations,\u00A0and\u00A0profitability.`,
      2: `We\u00A0offer\u00A0Projects,\u00A0Consulting,\u00A0and\nDigital\u00A0Transformation\u00A0\nservices\u00A0to\u00A0help\u00A0usinesses\u00A0achieve\u00A0their\u00A0goals\u00A0\nand\u00A0improve\u00A0their\u00A0operations.`,
      3: `With\u00A0knowledge\u00A0and\u00A0experience,\nour\u00A0highly\u00A0skilled\u00A0consultants\u00A0\nwill\u00A0ensure\u00A0that\u00A0you\u00A0lay\u00A0the\u00A0foundations\nfor\u00A0a\u00A0thriving\u00A0enterprise.\u00A0\nTrust\u00A0us\u00A0with\u00A0your\u00A0success.`,
    };

    let renderer;
    let scene;
    let camera;
    let width, height;
    let planet;
    let staticPlanet;
    let material;
    let materialStaticPlanet;
    let earthGlow;
    let cameraCtrl;
    let sphere;
    let wheelTrigered = false;
    let startScrollngScreen = false;
    let directionOfTheWheel;
    let mixer;
    let diverRoot;
    let cameraTargetSetUp;
    const clock = new THREE.Clock();
    const geometries = [];
    let fishOriginal;
    let fishMesh;
    let matrix;
    let show1Paragaph = false;
    let show2Paragaph = false;
    let show3Paragaph = false;
    let startY;
    let backgroundMesh;
    let backgroundMeshMobile;
    let backgroundMeshMobileLandscape;
    const mixerarr = [];
    const instances = [];
    let textureAtlas1,
      geometryGlow1,
      materialGlow1,
      textureAtlasMobile,
      geometryGlowMobile,
      materialGlowMobile,
      textureAtlasMobileLandscape,
      geometryGlowMobileLandscape,
      materialGlowMobileLandscape,
      pointLight,
      pointLight2;

    const [text1, setText1] = useState(isPortrait() ? textsForMobilePortrait[1] : texts[1]);
    const [text2, setText2] = useState(isPortrait() ? textsForMobilePortrait[2] : texts[2]);
    const [text3, setText3] = useState(isPortrait() ? textsForMobilePortrait[3] : texts[3]);
    const [isMoved1, setIsMoved1] = useState(null);
    const [isMoved2, setIsMoved2] = useState(null);
    const [isMoved3, setIsMoved3] = useState(null);
    const [isText1Animated, setText1Animated] = useState(false);
    const [isText2Animated, setText2Animated] = useState(false);
    const [isText3Animated, setText3Animated] = useState(false);
    let text2AnimationCheck;
    let text3AnimationCheck;
    let disableScrolling;
    let stateOfTheStage;

    const [cameras, setCamera] = useState(null);

    useEffect(() => {
      let step = -770;
      if (cameras) {
        if (sliderStatusValue == 0) {
          cameras.tween = gsap.to(cameras.position, 1, {
            y: 0,
            ease: Linear.easeNone,
          });
        } else if (sliderStatusValue == 25) {
          cameras.tween = gsap.to(cameras.position, 1, {
            y: step,
            ease: Linear.easeNone,
          });
        } else if (sliderStatusValue == 50) {
          // cameras.position.y = step;
          cameras.tween = gsap.to(cameras.position, 1, {
            y: step * 2,
            ease: Linear.easeNone,
          });
        } else if (sliderStatusValue == 75) {
          // cameras.position.y = step;
          cameras.tween = gsap.to(cameras.position, 1, {
            y: step * 3,
            ease: Linear.easeNone,
          });
        } else if (sliderStatusValue == 100) {
          cameras.position.y = step * 3;
        }
      }
      return () => {
        if (cameras) gsap.killTweensOf(cameras.position);
      };
    }, [sliderStatusValue]);

    useEffect(() => {
      const { current: canvas } = reactCanvas;

      sceneOptions = {
        useGeometryUniqueIdsMapSearch: true,
        useMaterialMeshMapSearch: true,
        useClonedMeshMap: true,
      };
      if (!canvas) return;

      // create a new WebGL renderer
      renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x00ff00, 0);
      renderer.alpha = true;
      renderer.antialias = true;

      // create a new scene
      scene = new THREE.Scene();
      // scene.background = null;

      // create a new camera
      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      camera.position.set(0, 0, 710);

      // add objects to the scene

      creatingAssets();
      cameraConfig();

      // const flyControls = new FlyControls(camera, renderer.domElement);
      // flyControls.dragToLook = false;
      // flyControls.autoForward = false;

      // const flyControls = new FlyControls(camera, renderer.domElement);
      // flyControls.movementSpeed = 100.005;
      // flyControls.dragToLook = true;
      // flyControls.autoForward = true;

      setCamera(camera); // Save the camera to the state variable

      threeManager = {
        activeScene: scene,
        transitionScene: null,
      };

      function render() {
        if (renderer) renderer.render(scene, camera);
      }

      function wheelOn(event) {
        if (!wheelTrigered) {
          wheelTrigered = true;
          if (event.type === "wheel") {
            directionOfTheWheel = event.deltaY > 0 ? "down" : "up";
          } else if (event.type === "touchmove") {
            const touch = event.touches[0];
            startY = startY || touch.pageY;
            const deltaY = touch.pageY - startY;
            directionOfTheWheel = deltaY > 0 ? "down" : "up";
          }
          if (directionOfTheWheel === "down" && camera.position.y === -2200) {
            if (event.type === "wheel") {
              window.removeEventListener("wheel", wheelOn, { passive: false });
            } else if (event.type === "touchmove") {
              window.removeEventListener("touchmove", wheelOn, { passive: false });
            }
            // handleMaskFade(true);
            // setTimeout(() => {
            //     history.push("/academy");
            // }, isPhone()? 600 : 1000);
          }
        }
      }

      if (isDesktop()) {
        window.addEventListener("wheel", wheelOn, { passive: false });
      } else {
        window.addEventListener("touchmove", wheelOn, { passive: false });
      }

      function animate() {
        // flyControls.update(133.2);
        if (renderer) {
          requestAnimationFrame(animate);
          render();
          const delta = clock.getDelta();
          if (mixer) mixer.update(delta);

          // if(diverRoot && !cameraTargetSetUp){
          //     // cameraTargetSetUp = true;
          //     if (cameraCtrl) cameraCtrl.update()
          //     cameraCtrl.enabled = true;
          //     cameraCtrl.target.copy(new THREE.Vector3(diverRoot.position.x, diverRoot.position.y+300,diverRoot.position.z) );
          // }

          // if (wheelTrigered && !startScrollngScreen && !disableScrolling) {
          //     startScrollngScreen = true;
          //     let step = -1100;
          //     let stepDiver = 300; //initial y axis of the diver

          //     if (directionOfTheWheel === "down") {

          //         if (scrollState >= 3) {
          //             scrollState = 3;
          //         } else {
          //             scrollState++;
          //         }

          //         if (camera.position.y === step) {
          //             setIsMoved2(true);
          //             setIsMoved3(false);
          //         } else if (camera.position.y === step * 2) {
          //         } else if (camera.position.y === 0) {
          //             setIsMoved1(true);
          //             setIsMoved2(false);
          //         }

          //         camera.tween = gsap.to(camera.position, 1, {
          //             y: camera.position.y == step ? step * 2 : camera.position.y == 0 ? step : camera.position.y, ease: Linear.easeNone, onComplete: () => {
          //                 startScrollngScreen = false;
          //                 wheelTrigered = false;

          //                 /**if (camera.position.y === step && !text2AnimationCheck) {
          //                     text2AnimationCheck = true;
          //                     disableScrolling = true;
          //                     setText2Animated(true);
          //                     const element2 = document.querySelector(".businessNewN-paragraph2");
          //                     animateLetters(element2, 0.03);
          //                     setTimeout(() => {
          //                         disableScrolling = false;
          //                         stateOfTheStage = 2;
          //                     }, 6000);

          //                 } else if (camera.position.y === step * 2 && !text3AnimationCheck) {
          //                     text3AnimationCheck = true;
          //                     setText3Animated(true);
          //                     disableScrolling = true;
          //                     const element3 = document.querySelector(".businessNewN-paragraph3");
          //                     animateLetters(element3, 0.03);
          //                     setTimeout(() => {
          //                         disableScrolling = false;
          //                         stateOfTheStage = 3;
          //                     }, 6000);
          //                 }*/
          //             }
          //         });
          //     } else if (directionOfTheWheel === "up") {

          //         if (scrollState <= 1) {
          //             scrollState = 1;
          //         } else {
          //             scrollState--;
          //         }

          //         if (camera.position.y === step) {
          //             setIsMoved1(false);
          //             setIsMoved2(null);
          //         } else if (camera.position.y === step * 2) {
          //             setIsMoved2(false);
          //             setIsMoved3(true);
          //         } else if (camera.position.y === 0) {

          //         }

          //         camera.tween = gsap.to(camera.position, 1, {
          //             y: camera.position.y == step ? 0 : camera.position.y == step * 2 ? step : camera.position.y, ease: Linear.easeNone, onComplete: () => {
          //                 startScrollngScreen = false;
          //                 wheelTrigered = false;
          //                 if (camera.position.y === step) {
          //                     setText2(texts[2]);
          //                 } else if (camera.position.y === step * 2) {
          //                     setText3(texts[3]);
          //                 } else if (camera.position.y === 0) {
          //                     setText1(texts[1]);
          //                 }
          //             }
          //         });
          //     }

          // }
        }

        // flyControls.update();
      }
      const animateLetters = (element, delay) => {
        if (element) {
          // Get the text content of the element and split it into an array of characters
          const text = element.textContent;
          const letters = text.split("");

          // Replace the text content of the element with an array of span elements, one for each letter
          const html = letters
            .map((letter) => (letter === "\n" ? `<br />` : `<span class="letter">${letter}</span>`))
            .join("");
          element.innerHTML = html;

          // Loop through the span elements and add the "animated" class with a delay to each one
          const letterElements = element.querySelectorAll(".letter");
          letterElements.forEach((letter, index) => {
            letter.style.animationDelay = `${index * delay}s`;
            letter.classList.add("animated");
          });
        }
      };
      animate();

      const resize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        if (isPhone()) {
          renderer.setSize(window.outerWidth, window.outerHeight);
        } else {
          renderer.setSize(width, height);
        }

        if (window.innerWidth > 2000) {
          backgroundMesh.scale.set(1000, 1000, 0);
        } else if (window.innerWidth > 1800) {
          backgroundMesh.scale.set(1100, 1000, 0);
        } else if (window.innerWidth > 1500) {
          backgroundMesh.scale.set(1150, 1000, 0);
        } else {
          backgroundMesh.scale.set(1200, 1000, 0);
        }

        const meshPC = scene.getObjectByName("backPCB");
        const meshPortrait = scene.getObjectByName("backPortraitB");
        const meshLandscape = scene.getObjectByName("backLandscapeB");

        if (isPhone()) {
          if (isPortrait()) {
            scene.add(backgroundMeshMobile);
            if (meshPC) scene.remove(backgroundMesh);
            if (meshLandscape) scene.remove(backgroundMeshMobileLandscape);
          } else {
            scene.add(backgroundMeshMobileLandscape);
            if (meshPortrait) scene.remove(backgroundMeshMobile);
            if (meshPC) scene.remove(backgroundMesh);
          }
        } else {
          scene.add(backgroundMesh);
          if (meshPortrait) scene.remove(backgroundMeshMobile);
          if (meshLandscape) scene.remove(backgroundMeshMobileLandscape);
        }
      };

      if (window) {
        window.addEventListener("resize", resize);
      }

      // let step = -1100;
      // if(camera){
      //     if (sliderStatusValue == 0) {
      //         camera.tween = gsap.to(camera.position, 1, {
      //             y: 0, ease: Linear.easeNone
      //         });

      //     } else if (sliderStatusValue == 33) {
      //         camera.tween = gsap.to(camera.position, 1, {
      //             y: step, ease: Linear.easeNone
      //         });

      //     } else if (sliderStatusValue == 66) {
      //         camera.position.y = step;
      //         camera.tween = gsap.to(camera.position, 1, {
      //             y: step*2, ease: Linear.easeNone
      //         });
      //     }else if(sliderStatusValue == 100){
      //         camera.position.y = step*2;
      //     }

      // }

      // disableScrolling = true;
      // setTimeout(() => {
      //     setText1Animated(true);
      //     const element = document.querySelector(".businessNewN-paragraph");
      //     animateLetters(element, 0.03);

      //     setTimeout(() => {
      //         disableScrolling = false;
      //     }, 6000);
      // }, 3500);

      function creatingAssets() {
        // textureAtlas1 = new THREE.TextureLoader().load(sceneBack);
        geometryGlow1 = new THREE.PlaneGeometry(2.048, 3.456);
        materialGlow1 = new THREE.MeshBasicMaterial({ map: textures.sceneBackBusinessP });
        backgroundMesh = new THREE.Mesh(geometryGlow1, materialGlow1);
        backgroundMesh.name = "backPCB";
        if (window.innerWidth > 2000) {
          backgroundMesh.scale.set(1000, 1000, 0);
        } else if (window.innerWidth > 1800) {
          backgroundMesh.scale.set(1100, 1000, 0);
        } else if (window.innerWidth > 1500) {
          backgroundMesh.scale.set(1150, 1000, 0);
        } else {
          backgroundMesh.scale.set(1200, 1000, 0);
        }
        backgroundMesh.position.set(0, -1150, -20);

        // textureAtlasMobile = new THREE.TextureLoader().load(sceneBackMobile);
        geometryGlowMobile = new THREE.PlaneGeometry(1.28, 8.533);
        materialGlowMobile = new THREE.MeshBasicMaterial({ map: textures.sceneBackMobileBusinessP });
        backgroundMeshMobile = new THREE.Mesh(geometryGlowMobile, materialGlowMobile);
        backgroundMeshMobile.scale.set(480, 480, 0);
        backgroundMeshMobile.position.set(0, -1150, -20);
        backgroundMeshMobile.name = "backPortraitB";

        // textureAtlasMobileLandscape = new THREE.TextureLoader().load(sceneBackMobileLandscape);
        geometryGlowMobileLandscape = new THREE.PlaneGeometry(3.0, 4.05);
        materialGlowMobileLandscape = new THREE.MeshBasicMaterial({ map: textures.sceneBackMobileLandscapeBusinessP });
        backgroundMeshMobileLandscape = new THREE.Mesh(geometryGlowMobileLandscape, materialGlowMobileLandscape);
        backgroundMeshMobileLandscape.scale.set(930, 930, 0);
        backgroundMeshMobileLandscape.position.set(0, -1150, -20);
        backgroundMeshMobileLandscape.name = "backLandscapeB";

        const meshPC = scene.getObjectByName("backPCB");
        const meshPortrait = scene.getObjectByName("backPortraitB");
        const meshLandscape = scene.getObjectByName("backLandscapeB");

        if (isPhone()) {
          if (isPortrait()) {
            scene.add(backgroundMeshMobile);
            if (meshPC) scene.remove(backgroundMesh);
            if (meshLandscape) scene.remove(backgroundMeshMobileLandscape);
          } else {
            scene.add(backgroundMeshMobileLandscape);
            if (meshPortrait) scene.remove(backgroundMeshMobile);
            if (meshPC) scene.remove(backgroundMesh);
          }
        } else {
          scene.add(backgroundMesh);
          if (meshPortrait) scene.remove(backgroundMeshMobile);
          if (meshLandscape) scene.remove(backgroundMeshMobileLandscape);
        }
      }

      function cameraConfig() {
        cameraCtrl = new OrbitControls(camera, renderer.domElement);
        cameraCtrl.enableDamping = false;
        cameraCtrl.dampingFactor = 0.1;
        cameraCtrl.autoRotate = false;
        cameraCtrl.autoRotateSpeed = 0.5;
        cameraCtrl.rotateSpeed = 0.5;
        cameraCtrl.enableZoom = false;
        cameraCtrl.enableRotate = true;
        cameraCtrl.enablePan = true;
        cameraCtrl.enabled = false;
        // cameraCtrl.minDistance = 10;
        // cameraCtrl.maxDistance = 100;

        //if (cameraCtrl) cameraCtrl.update();
        renderer.render(scene, camera);

        pointLight = new THREE.PointLight(0xffffff);
        pointLight.position.set(0, 0, 1000);
        scene.add(pointLight);

        pointLight2 = new THREE.PointLight(0xffffff);
        pointLight2.position.set(0, 0, -1000);
        scene.add(pointLight2);
        scene.add(new THREE.AmbientLight(0xffffff));
      }

      return () => {
        if (textureAtlas1) textureAtlas1.dispose();
        if (geometryGlow1) geometryGlow1.dispose();
        if (materialGlow1) materialGlow1.dispose();
        if (textureAtlasMobile) textureAtlasMobile.dispose();
        if (geometryGlowMobile) geometryGlowMobile.dispose();
        if (materialGlowMobile) materialGlowMobile.dispose();
        if (textureAtlasMobileLandscape) textureAtlasMobileLandscape.dispose();
        if (geometryGlowMobileLandscape) geometryGlowMobileLandscape.dispose();
        if (materialGlowMobileLandscape) materialGlowMobileLandscape.dispose();

        if (scene.current && camera.current) {
          scene.current.dispose();
          camera.current.dispose();
        }
        if (renderer.current) {
          renderer.current.dispose();
        }
        if (isDesktop()) {
          window.removeEventListener("wheel", wheelOn, { passive: false });
        } else {
          window.removeEventListener("touchmove", wheelOn, { passive: false });
        }

        if (window) {
          window.removeEventListener("resize", resize);
        }
        backgroundMeshMobileLandscape = null;
        backgroundMesh = null;
        backgroundMeshMobile = null;
        materialGlowMobileLandscape = null;
        materialGlowMobile = null;
        materialGlow1 = null;
        pointLight = null;
        renderer = null;
        scene = null;
        camera = null;
        pointLight = null;
        pointLight2 = null;
      };
    }, []);

    return (
      <div>
        <canvas ref={reactCanvas} {...rest}></canvas>

        {/* <div style={{
                position: "relative"
            }}>
                <div className="businessNewN-texts" style={{
                    transition: 'transform 1.1s ease-in-out',
                    transform: isMoved1 ? 'translateY(-1000px)' : 'none',
                }}>
                    <div className="businessNewN-box">
                        <p className="businessNewN-paragraph">
                            {text1.split("").map((char, index) => (
                                <span key={index} className={`letter ${isText1Animated ? 'animated' : ''}`}>{char}</span>
                            ))}
                        </p>
                    </div>
                </div>

                <div className="businessNewN-texts2" style={{
                    transition: 'transform 1.1s ease-in-out',
                    transform: isMoved2 ? 'translateY(-1000px)' : isMoved2 === false ? 'none' : 'translateY(1000px)',
                }}>
                    <div className="businessNewN-box2">
                        <p className="businessNewN-paragraph2">
                            {text2.split("").map((char, index) => (
                                <span key={index} className={`letter ${isText2Animated ? 'animated' : ''}`}>{char}</span>
                            ))}
                        </p>
                    </div>
                </div>

                <div className="businessNewN-texts3" style={{
                    transition: 'transform 1.1s ease-in-out',
                    transform: isMoved3 ? 'translateY(1000px)' : 'none',
                }}>
                    <div className="businessNewN-box3">
                        <p className="businessNewN-paragraph3">
                            {text3.split("").map((char, index) => (
                                <span key={index} className={`letter ${isText3Animated ? 'animated' : ''}`}>{char}</span>
                            ))}
                        </p>
                    </div>
                </div>
            </div> */}
      </div>
    );
  }
);
