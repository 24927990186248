// import React from "react";
// import { Link } from "react-router-dom";
// import "../style/research-and-development.scss";
// import rdAnimationMp4 from "../assets/videos/R&D.mp4";
// import rdAnimationMp4R from "../assets/videos/R&D_r.mp4";
// import { uiVisibility, scrollTo, handleSwipeVisibility, handleScrollIconVisibility } from "../util/UIHelper.js";
// import pix2pixPng from "./../assets/images/our-organisation-logos/Harmonity_Pixels2Pixels_Logo_White_Transparent.png";
// import Logo from "./Logo";
// import { OnceContext } from "../context/OnceContext";
// import { HorisontalScrollbar } from "./HorisontalScrollbar";
// import Swipe from "./swipe";
// import Hand from "./hand";
import NewScrollbar from "./NewScrollBar";
// import history from "../routes/history";
// import { isDesktop, isPhone } from "../util/DeviceType";
// import VerticalScrollIcon from "./verticalscrollicon";
import React from "react";
// import Splitting from 'splitting';

import "../style/solutions.scss";
import "../style/newFontAnimations.scss";
import "../style/NewSlider.scss";

import Logo from "./Logo";

// import ResearchAndDevelopmentThree from "./ResearchAndDevelopmentThree";

//import { changeScene, pointerWheelEventPaused } from "./../scenes/ResearchAndDevelopmentScene";
import history from "../routes/history";

import { isDesktop, isLaptop, isPhone } from "../util/DeviceType";
import SolutionsThree4, { userInteractingWithInputs, changeuserInteractingWithInputs } from "./SolutionThree4";
import SolutionsThree5 from "./SolutionThree5";
import NewContact from "./NewContact";
import TextBox from "./TextBox";
import Pictograms from "./Pictograms";
import { solutionsPictograms } from "../copy/pictograms";
import { solutionsTexts } from "../copy/videotexts";
import { throttle } from "../util/UIHelper";

const VIDEO_PAUSE_TIME = {
  RD: 3,
  NFT: 4.6,
  IOT: 6.9,
  BS: 9.33,
  ES: 12,
  CC: 15.9,
};
const VIDEO_PAUSE_TIME_REVERT = {
  CC: 1.9,
  ES: 5.8,
  BS: 8.4,
  IOT: 10.9,
  NFT: 13.2,
  RD: 14.8,
};
const VIDEO_DELAY = 50;
let videoRD;
let videoRDRevert;
let scrollPaused = true;

export class Solutions extends React.Component {
  // texts = {
  //   1: `Hramonity\u00A0Solutions\u00A0is\u00A0our\u00A0entity\u00A0providing\u00A0a\u00A0wide\u00A0range\u00A0of\u00A0IT\u00A0solutions\u00A0\nand\u00A0support\u00A0services,\u00A0highly\u00A0focused\u00A0on\u00A0innovation\u00A0\nand\u00A0customer\u00A0satisfaction.`,
  //   2: `We\u00A0provide\u00A0a\u00A0range\u00A0of\u00A0services\u00A0including\u00A0Managed\u00A0Services,\u00A0Cybersecurity\u00A0\nServices,\u00A0Professional\u00A0Services,\u00A0Web\u00A0Development,\u00A0Software\u00A0Engineering,\u00A0\nGame\u00A0Development,\u00A0and\u00A0Custom\u00A0Made\u00A0AR\u00A0and\u00A0VR\u00A0Solutions.`,
  //   3: `With\u00A0Harmonity\u00A0Solutions,\u00A0you'll\u00A0have\u00A0a\u00A0partner\u00A0committed\u00A0to\u00A0your\u00A0success\u00A0\nand\u00A0capable\u00A0of\u00A0providing\u00A0customized\u00A0solutions\u00A0perfect\u00A0for\u00A0you\u00A0\nand\u00A0your\u00A0goals. `,
  // };

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.state = {
      activeId: 0,
      activeText: 1,
      isMobile: false,
      showSwipe: true,
      swipeStart: 0,
      swipeEnd: 0,
      currentScreenState: 1,
      activeTextId: 0,
      contactButtonTop: 0,
      showContact: false,
      isText1Animated: false,
      isText2Animated: false,
      isText3Animated: false,
      text2AnimationCheck: false,
      text3AnimationCheck: false,
      disableScrolling: null,
      value: 0,
      startY: null,
      direction: null,
      pageId: "solutions",
      texts: {
        1: `Hramonity\u00A0Solutions\u00A0is\u00A0our\u00A0entity\u00A0providing\u00A0a\u00A0wide\u00A0range\u00A0of\u00A0IT\u00A0solutions\u00A0\nand\u00A0support\u00A0services,\u00A0highly\u00A0focused\u00A0on\u00A0innovation\u00A0\nand\u00A0customer\u00A0satisfaction.`,
        2: `We\u00A0provide\u00A0a\u00A0range\u00A0of\u00A0services\u00A0including\u00A0Managed\u00A0Services,\u00A0Cybersecurity\u00A0\nServices,\u00A0Professional\u00A0Services,\u00A0Web\u00A0Development,\u00A0Software\u00A0Engineering,\u00A0\nGame\u00A0Development,\u00A0and\u00A0Custom\u00A0Made\u00A0AR\u00A0and\u00A0VR\u00A0Solutions.`,
        3: `With\u00A0Harmonity\u00A0Solutions,\u00A0you'll\u00A0have\u00A0a\u00A0partner\u00A0committed\u00A0to\u00A0your\u00A0success\u00A0\nand\u00A0capable\u00A0of\u00A0providing\u00A0customized\u00A0solutions\u00A0perfect\u00A0for\u00A0you\u00A0\nand\u00A0your\u00A0goals. `,
      },
      shouldFadeOut1: false,
      shouldFadeOut2: false,
      shouldFadeOut3: false,
      isText1AnimatedTimeout: false,
    };

    this.handleMouseDown = this.handleMouseDown.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);

    this.handleChange = (event) => {
      this.setState({ value: event.target.value });
    };

    this.handleScroll = (event) => {
      const direction = event.deltaY > 0 ? 1 : -1; // Positive value means scrolling down, negative means scrolling up

      if (direction > 0) {
        this.handleOpeningNextPage();
      } else if (direction < 0) {
        this.handleOpeningNextPageUP();
      }
      // if (isDesktop()) {
      //   this.handleMoveScroll(event.wheelDelcta > 0);
      // } else {
      //   this.handleMoveScroll(event.touches[0].clientY < this.lastY);
      //   this.lastY = event.touches[0].clientY;
      // }
    };

    this.throttledScroll = throttle(this.handleScroll, 2000);

    if (!isPhone()) {
      window.addEventListener("wheel", this.throttledScroll);
      //window.addEventListener("scroll", this.handleScroll);
    }
    // window.addEventListener("touchmove", this.handleScroll, { passive: false });
  }
  // handleMoveScroll(direction) {
  //   //if (pointerWheelEventPaused) return

  //   // this.ref.current.changeScene(this.state.currentScreenState, direction)
  //   // changeScene(this.state.currentScreenState, direction);
  //   this.setState({
  //     currentScreenState: direction ? --this.state.currentScreenState : ++this.state.currentScreenState,
  //   });
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.disableScrolling !== this.state.disableScrolling) {
      setTimeout(() => {
        this.setState((prevState, prevProps) => {
          if (prevState.disableScrolling) {
            return { disableScrolling: false };
          }
          return null;
        });
      }, 1000);
    }

    if (prevState.value !== this.state.value) {
      if (this.state.value === 100) {
        this.setState({ activeText: 0 });
        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          //window.removeEventListener("wheel", this.handleScroll);
          // window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

          setTimeout(() => {
            history.push("/business-consulting");
          }, 1501);
        }, 1000);
      }
    }
  }
  fadeOutText = (whichOne) => {
    if (whichOne == 1) {
      this.setState({ shouldFadeOut1: true });
    } else if (whichOne == 2) {
      this.setState({ shouldFadeOut2: true });
    } else if (whichOne == 3) {
      this.setState({ shouldFadeOut3: true });
    }
  };

  handleMoveScroll(direction) {
    if (!this.state.disableScrolling && !userInteractingWithInputs) {
      let nextScreenState = this.state.currentScreenState;
      if (direction) {
        nextScreenState--;
      } else {
        nextScreenState++;
      }
      if (nextScreenState > 5) {
        nextScreenState = 5;
      } else if (nextScreenState < 1) {
        nextScreenState = 1;
      }
      if (nextScreenState > this.state.currentScreenState) {
        if (nextScreenState == 2 && !this.state.isText1Animated) {
        } else if (nextScreenState == 5) {
          this.handleOpeningNextPage();
        }
      }
      this.state.currentScreenState = nextScreenState;
    }
    if (userInteractingWithInputs) {
      changeuserInteractingWithInputs(false);
    }
  }

  animateLetters(element, delay) {
    // Get the text content of the element and split it into an array of characters
    if (element) {
      const text = element.textContent;
      const letters = text.split("");

      // Replace the text content of the element with an array of span elements, one for each letter
      const html = letters
        .map((letter) => (letter === "\n" ? `<br />` : `<span class="letter">${letter}</span>`))
        .join("");
      element.innerHTML = html;

      // Loop through the span elements and add the "animated" class with a delay to each one
      const letterElements = element.querySelectorAll(".letter");
      letterElements.forEach((letter, index) => {
        letter.style.animationDelay = `${index * delay}s`;
        letter.classList.add("animated");
      });
    }
  }

  createWheelStopListener = (element, callback, timeout) => {
    var handle = null;
    var onScroll = function (e) {
      if (handle) {
        clearTimeout(handle);
      }
      handle = setTimeout(() => callback(e), timeout || 200); // default 200 ms
    };
    element.addEventListener("wheel", onScroll);
    return function () {
      element.removeEventListener("wheel");
    };
  };

  setBulletValue = (val) => {
    this.setState((prevState, prevProps) => {
      if ((!prevState.value != val || val == 0) && !prevState.disableScrolling) {
        return { value: val };
      }
      return null;
    });

    this.setState((prevState, prevProps) => {
      if (!prevState.disableScrolling) {
        return { disableScrolling: true };
      }
      return null;
    });
  };

  modifyTextsForMobile() {
    // change the text for mobile portrait mode
    const newTexts = {
      1: `Hramonity\u00A0Solutions\u00A0is\u00A0our\u00A0entity\u00A0providing\u00A0a\u00A0wide\u00A0range\u00A0of\u00A0IT\u00A0solutions\u00A0\nand\u00A0support\u00A0services,\u00A0highly\u00A0focused\u00A0on\u00A0innovation\u00A0\nand\u00A0customer\u00A0satisfaction.`,
      2: `We\u00A0provide\u00A0a\u00A0range\u00A0of\u00A0services\u00A0including\u00A0Managed\u00A0Services,\u00A0Cybersecurity\u00A0\nServices,\u00A0Professional\u00A0Services,\u00A0Web\u00A0Development,\u00A0Software\u00A0Engineering,\u00A0\nGame\u00A0Development,\u00A0and\u00A0Custom\u00A0Made\u00A0AR\u00A0and\u00A0VR\u00A0Solutions.`,
      3: `With\u00A0Harmonity\u00A0Solutions,\u00A0you'll\u00A0have\u00A0a\u00A0partner\u00A0committed\u00A0to\u00A0your\u00A0success\u00A0\nand\u00A0capable\u00A0of\u00A0providing\u00A0customized\u00A0solutions\u00A0perfect\u00A0for\u00A0you\u00A0\nand\u00A0your\u00A0goals. `,
    };
    this.setState({ texts: newTexts });
  }

  // playSolutionSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("solutions");
  // }

  // stopSolutionSound() {
  //   const { setSounds } = this.props;
  //   setSounds.setSounds("nan");
  //   setSounds.setStopSounds("solutions");
  // }

  componentDidMount() {
    if (window.matchMedia("(max-width: 767px)").matches) {
      this.setState({ isMobile: true });
      this.modifyTextsForMobile();
    }
    // this.playSolutionSound();

    const top = this.calculateFormTop();
    this.setState({ contactButtonTop: top });
    const text = document.querySelector(".stream");

    // if(!this.state.isText1Animated && !this.state.isText1AnimatedTimeout){
    //     this.state.isText1AnimatedTimeout=true;
    //     setTimeout(() => {
    //       this.state.isText1Animated = true;
    //       const element = document.querySelector(".solutions-paragraph");
    //       this.animateLetters(element, 0.03);
    //       this.state.disableScrolling = true;
    //       setTimeout(() => {
    //         this.state.disableScrolling = false;
    //       }, 6000);
    //     }, 3000);
    //   }

    // Splitting({ target: text });

    let paused = false;

    // const box = document.querySelector(".solutions-box");

    // const page = document.querySelector(".solutions-texts");
    // page.addEventListener("mousemove", (e) => {
    //   if (!paused) {
    //     if (e.clientX + 100 > window.innerWidth) {
    //       box.style.left = window.innerHeight - 102;
    //     } else {
    //       box.style.left = `${e.clientX}px`;
    //     }
    //     if (e.clientY + 100 > window.innerHeight) {
    //       box.style.top = window.innerHeight - 102;
    //     } else {
    //       box.style.top = `${e.clientY}px`;
    //     }
    //   }
    // });

    // this.createWheelStopListener(page, (e) => {
    //   if (e.wheelDelta < 0) {
    //     if (this.state.activeTextId === 3) {
    //       history.push("/business-consulting");
    //     } else {
    //       if (window.innerWidth - e.target.getBoundingClientRect().right >= 500) {
    //         box.classList.add("scale");

    //         this.setState((prevsState, prevProps) => {
    //           return { activeTextId: prevsState.activeTextId + 1 };
    //         });
    //         paused = true;
    //       } else {
    //         paused = true;
    //         this.setState((prevsState, prevProps) => {
    //           return { activeTextId: prevsState.activeTextId + 1 };
    //         });
    //         box.style.right = `${window.innerWidth - e.target.getBoundingClientRect().right}px`;
    //         box.style.left = "unset";
    //         box.classList.add("scale");
    //       }
    //     }
    //   } else {
    //     if (this.state.activeTextId <= 1) {
    //       return;
    //     } else {
    //       if (window.innerWidth - e.target.getBoundingClientRect().right >= 500) {
    //         box.classList.add("scale");
    //         this.setState((prevsState, prevProps) => {
    //           return { activeTextId: prevsState.activeTextId - 1 };
    //         });
    //         paused = true;
    //       } else {
    //         paused = true;
    //         this.setState((prevsState, prevProps) => {
    //           return { activeTextId: prevsState.activeTextId - 1 };
    //         });
    //         box.style.right = `${window.innerWidth - e.target.getBoundingClientRect().right}px`;
    //         box.style.left = "unset";
    //         box.classList.add("scale");
    //       }
    //     }
    //   }
    // });

    // box.addEventListener("animationend", (e) => {
    //   const text = document.querySelector(".sulutions-description");
    //   if (e.animationName === "scale") {
    //     e.target.style.height = `${text.getBoundingClientRect().height}px`;

    //     setTimeout(() => {
    //       text.style.visibility = "visible";
    //       text.style.opacity = "1";
    //     }, 120);

    //     setTimeout(() => {
    //       // text.style.visibility = "hidden";
    //       text.style.opacity = "0";
    //       setTimeout(() => {
    //         e.target.style.height = "100px";
    //       }, 1000);

    //       setTimeout(() => {
    //         box.classList.add("unscale");
    //       }, 1100);
    //     }, 3200);
    //   } else if (e.animationName === "unscale") {
    //     box.classList.remove("scale");
    //     box.classList.remove("unscale");
    //     paused = false;
    //   }
    // });
  }
  handleShowContact = () => {
    this.setState(function (prevState, props) {
      return { showContact: !prevState.showContact };
    });
  };

  componentWillUnmount() {
    this.setState({
      disableScrolling: null,
    });

    //this.stopSolutionSound();
    window.removeEventListener("wheel", this.throttledScroll);
    // window.removeEventListener("scroll", this.handleScroll);
    // window.removeEventListener("touchmove", this.handleScroll, { passive: false });
  }
  calculateFormTop() {
    const form = document.querySelector(".new-contact");
    const showFormButton = document.querySelector(".show-contact-button");

    const top = form.getBoundingClientRect().top;
    return top;
  }

  showContact = () => {
    this.setState((prevState) => ({
      showContact: !prevState.showContact,
    }));
  };

  ///////////////
  handleMouseDown = (e) => {
    this.setState({
      startY: e.clientY,
    });
  };

  handleMouseMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";

      this.setState({
        direction: direction,
      });
      if (direction == "down") {
        this.handleOpeningNextPage();
      }
      if (direction == "up") {
        this.handleOpeningNextPageUP();
      }
    }
  };

  handleMouseUp = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };

  handleTouchStart = (e) => {
    this.setState({
      startY: e.touches[0].clientY,
    });
  };

  handleTouchMove = (e) => {
    if (this.state.startY !== null) {
      const deltaY = e.touches[0].clientY - this.state.startY;
      const direction = deltaY > 0 ? "down" : "up";
      this.setState({
        direction: direction,
      });
      if (direction == "down") {
        this.handleOpeningNextPage();
      }
      if (direction == "up") {
        this.handleOpeningNextPageUP();
      }
    }
  };

  /*handleOpeningNextPage() {
    if (this.state.value != 100 && !this.state.disableScrolling) {
      this.setState({
        value: 100,
        disableScrolling: true,
      });

      setTimeout(() => {
        this.state.currentScreenState = 1;

        this.setState({
          currentScreenState: 1,
        });

        this.props.handleMaskFade(true);
        if (isDesktop() || isLaptop()) {
          window.removeEventListener("wheel", this.handleScroll);
        }
        setTimeout(
          () => {
            history.push("/business-consulting");
            this.setState({
              disableScrolling: false,
            });
          },
          isPhone() ? 600 : 1000
        );
      }, 2500);
    }
  }*/
  handleOpeningNextPage() {
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 0) {
        this.setBulletValue(12.5);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 12.5) {
        this.setBulletValue(25);
        this.setState({
          activeText: 3,
        });
      } else if (this.state.value == 25) {
        this.setBulletValue(37.5);
        this.setState({
          activeText: 4,
        });
      } else if (this.state.value == 37.5) {
        this.setBulletValue(50);
        this.setState({
          activeText: 5,
        });
      } else if (this.state.value == 50) {
        this.setBulletValue(62.5);
        this.setState({
          activeText: 6,
        });
      } else if (this.state.value == 62.5) {
        this.setBulletValue(75);
        this.setState({
          activeText: 7,
        });
      } else if (this.state.value == 75) {
        this.setBulletValue(87.5);
        this.setState({
          activeText: 8,
        });
      } else if (this.state.value == 87.5) {
        this.setBulletValue(100);
        this.setState({
          activeText: 0,
        });

        setTimeout(() => {
          this.state.currentScreenState = 1;

          // this.props.handleMaskFade(true);
          this.props.handleMaskFade(true);
          window.removeEventListener("wheel", this.throttledScroll);
          window.removeEventListener("scroll", this.handleScroll);
          // window.removeEventListener("touchmove", this.handleScroll, { passive: false });
        }, 1000);
        setTimeout(() => {
          history.push("/business-consulting");
        }, 2501);
      }
    }
  }

  handleOpeningNextPageUP() {
    if (!this.state.disableScrolling) {
      this.setState({
        startY: null,
      });

      if (this.state.value == 12.5) {
        this.setBulletValue(0);
        this.setState({
          activeText: 1,
        });
      } else if (this.state.value == 25) {
        this.setBulletValue(12.5);
        this.setState({
          activeText: 2,
        });
      } else if (this.state.value == 37.5) {
        this.setBulletValue(25);
        this.setState({
          activeText: 3,
        });
      } else if (this.state.value == 50) {
        this.setBulletValue(37.5);
        this.setState({
          activeText: 4,
        });
      } else if (this.state.value == 62.5) {
        this.setBulletValue(50);
        this.setState({
          activeText: 5,
        });
      } else if (this.state.value == 75) {
        this.setBulletValue(62.5);
        this.setState({
          activeText: 6,
        });
      } else if (this.state.value == 87.5) {
        this.setBulletValue(75);
        this.setState({
          activeText: 7,
        });
      }
    }
  }

  handleTouchEnd = () => {
    this.setState({
      startY: null,
      direction: null,
    });
  };
  //////////

  handleRouteNextPage = () => {
    setTimeout(() => {
      history.push("/selection-page");
    }, 500);
  };

  switchOffPictogram = (value) => {
    this.setState({ switchOffPictogram: value });
  };

  toggleActiveText = (id) => {
    this.setState({ activeText: id });
  };

  handleClickCircle(bulletValue, activeText) {
    if (isPhone()) {
      if (!this.state.disableScrolling) {
        this.setBulletValue(bulletValue);
        this.setState({
          activeText: activeText,
        });
        // if (this.state.value === 87.5) {
        //   this.setState({ activeText: 0 });
        //   setTimeout(() => {
        //     this.state.currentScreenState = 1;

        //     // this.props.handleMaskFade(true);
        //     this.props.handleMaskFade(true);
        //     //window.removeEventListener("wheel", this.handleScroll);
        //     // window.removeEventListener("scroll", this.handleScroll);
        //     // window.removeEventListener("touchmove", this.handleScroll, { passive: false });

        //     setTimeout(() => {
        //       history.push("/business-consulting");
        //     }, 1501);
        //   }, 1000);
        // }
      }
    }
  }

  //semibold
  render() {
    const { text2, isText2Animated, shouldFadeOut1, shouldFadeOut2, shouldFadeOut3, value } = this.state;
    return (
      <div className="research-and-development text-center" id="research-and-development">
        {/* <div className="input-pipe">
          <div className="pipe-container">
            <div className="pipe"></div>
            <div className="fill"></div>
          </div>
        </div> */}
        <div style={{ position: "relative" }}>
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            <div className="pipe-container">
              <input
                type="range"
                min="0"
                max="100"
                step="1"
                value={value}
                onChange={this.handleChange}
                className="pipe-input"
              />
              <div className="pipe"></div>
              <div className="fill" style={{ height: `${value}%` }} />
              <div
                className="pulsating-circle"
                style={{ top: `${value}%` }}
                // onMouseDown={this.handleMouseDown}
                // onMouseMove={this.handleMouseMove}
                // onMouseUp={this.handleMouseUp}
                // onTouchStart={this.handleTouchStart}
                // onTouchMove={this.handleTouchMove}
                // onTouchEnd={this.handleTouchEnd}
              />
              <div className="input-start">
                <p>Start</p>
              </div>
              <div className="input-end">
                <p>Next</p>
              </div>
              <div className="line-zero-Solution" onClick={() => this.handleClickCircle(0, 1)}></div>
              <div className="line-first-Solution" onClick={() => this.handleClickCircle(12.5, 2)}></div>
              <div className="line-second-Solution" onClick={() => this.handleClickCircle(25, 3)}></div>
              <div className="line-fourth-Solution" onClick={() => this.handleClickCircle(37.5, 4)}></div>
              <div className="line-fifth-Solution" onClick={() => this.handleClickCircle(50, 5)}></div>
              <div className="line-sixth-Solution" onClick={() => this.handleClickCircle(62.5, 6)}></div>
              <div className="line-seventh-Solution" onClick={() => this.handleClickCircle(75, 7)}></div>
              <div className="line-eighth-Solution" onClick={() => this.handleClickCircle(87.5, 8)}></div>
              <div className="line-third-Solution" onClick={() => this.handleClickCircle(100, 0)}></div>
            </div>
          </div>
        </div>
        {/* <div className="scroll-container">
          <div className="scroll-content">
          </div>
          <div className="scroll-bar">
            <div className="scroll-bar-track"></div>
            <div className="scroll-bar-thumb"></div>
          </div>
        </div> */}
        <TextBox
          activeText={this.state.activeText}
          activeTextContent={solutionsTexts[this.state.activeText]}
          toggleActiveText={this.toggleActiveText}
        />
        <Pictograms
          toggleActiveText={this.toggleActiveText}
          activeText={this.state.activeText}
          pictograms={solutionsPictograms}
          bulletValue={this.setBulletValue}
          pageId={this.state.pageId}
        />

        <Logo
          passedFunction={this.handleRouteNextPage}
          url={"/assets/sajtLogos/TalasonLogo.png"}
        />

        <NewContact />
        <SolutionsThree5 antialias id="my-canvas" textures={this.props.textures} />
        <SolutionsThree4
          antialias
          id="my-canvas"
          handleMaskFade={this.props.handleMaskFade}
          textures={this.props.textures}
        />
        {/* 
        <div style={{
          position: "relative"
        }}>
          <div className="solutions-texts">
            <div className="solutions-box">
              <p className={`solutions-paragraph ${shouldFadeOut1 ? 'fade-out-paragraph' : ''}`}>
                {this.state.texts[1].split("").map((char, index) => (
                  <span key={index} className={`letter ${this.state.isText1Animated ? 'animated' : ''}`}>{char}</span>
                ))}
              </p>
            </div>
          </div>
          <div className="solutions-texts2">
            <div className="solutions-box2">
              <p className={`solutions-paragraph2 ${shouldFadeOut2 ? 'fade-out-paragraph' : ''}`}>{
                this.state.texts[2].split("").map((char, index) => (
                  <span key={index} className={`letter ${this.state.isText2Animated ? 'animated' : ''}`}>{char}</span>
                ))}</p>
            </div>
          </div>
          <div className="solutions-texts3">
            <div className="solutions-box3">
              <p className={`solutions-paragraph3 ${shouldFadeOut3 ? 'fade-out-paragraph' : ''}`}>{
                this.state.texts[3].split("").map((char, index) => (
                  <span key={index} className={`letter ${this.state.isText3Animated ? 'animated' : ''}`}>{char}</span>
                ))}</p>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

//ResearchAndDevelopment.contextType = OnceContext;

export function startScreen() {
  videoRD = document.getElementById("video-r-and-d");
  videoRDRevert = document.getElementById("video-r-and-d-r");

  if (!videoRD || !videoRDRevert || videoRD.readyState !== 4 || videoRDRevert.readyState !== 4) {
    throw Error("Problem with video resource");
  }
  if (isDesktop()) {
  }
}
