import React, { useEffect, useState } from "react";
import "../style/pictograms.scss";
import "../style/style.css";

export default function Pictograms({ toggleActiveText, activeText, pictograms, bulletValue, pageId }) {
  const [activePictogram, setActivePictogram] = useState(null);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    if (activePictogram) {
      setButtonDisabled(true);
      setTimeout(() => {
        setButtonDisabled(false);
      }, 10750);
    }
  }, [activePictogram]);

  useEffect(() => {
    if (!activeText) {
      setActivePictogram(0);
    } else if (activeText) {
      setActivePictogram(activeText);
    }

    if (bulletValue && pageId) {
      switch (pageId) {
        case "solutions":
          switch (activeText) {
            case 1:
              bulletValue(0);
              break;
            case 2:
              bulletValue(12.5);
              break;
            case 3:
              bulletValue(25);
              break;
            case 4:
              bulletValue(37.5);
              break;
            case 5:
              bulletValue(50);
              break;
            case 6:
              bulletValue(62.5);
              break;
            case 7:
              bulletValue(75);
              break;
            case 8:
              bulletValue(87.5);
              break;
          }
          break;
        case "business":
          switch (activeText) {
            case 1:
              bulletValue(0);
              break;
            case 2:
              bulletValue(25);
              break;
            case 3:
              bulletValue(50);
              break;
            case 4:
              bulletValue(75);
          }
          break;
        case "academy":
          switch (activeText) {
            case 1:
              bulletValue(0);
              break;
            case 2:
              bulletValue(50);
              break;
          }
          break;
        case "who-we-are":
          switch (activeText) {
            case 1:
              bulletValue(0);
              break;
            case 2:
              bulletValue(33);
              break;
            case 3:
              bulletValue(66);
              break;
          }
          break;
        case "pixels":
          switch (activeText) {
            case 1:
              bulletValue(0);
              break;
            case 2:
              bulletValue(16.66);
              break;
            case 3:
              bulletValue(33.33);
              break;
            case 4:
              bulletValue(49.99);
              break;
            case 5:
              bulletValue(66.65);
              break;
            case 6:
              bulletValue(83.31);
              break;
          }
          break;
      }
    }
  }, [activeText]);

  function handleSetActivePictogram(id) {
    if (id === activePictogram) {
      toggleActiveText(0);
      setActivePictogram(0);
    } else {
      setActivePictogram(id);
      toggleActiveText(id);
    }
  }

  return (
    <div className="buttons-container">
      <ul className="pictograms-list">
        {pictograms &&
          pictograms.map((pict) => {
            return (
              <li
                key={pict.id}
                className={activePictogram === pict.id ? "pictogram-icon active " : "pictogram-icon"}
                onClick={() => {
                  handleSetActivePictogram(pict.id);
                }}
              >
                <img
                  src={pict.url}
                  style={{ width: pict.id === 1 ? "50%" : "60%" }}
                  className={activePictogram === pict.id ? "active-text" : ""}
                ></img>
              </li>
            );
          })}
      </ul>
    </div>
  );
}
