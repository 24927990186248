import React from "react";
import "../style/draggablecard.scss";

import { OnceContext, TextureContext } from "../context/OnceContext";

export class Card extends React.Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
    this.paused = false;
  }

  delay = (duration) => {
    return new Promise((resolve) => {
      setTimeout(resolve, duration);
    });
  };

  onHover = (e) => {
    if (!this.props.active) {
      const evt = e.type === "touchmove" ? e.changedTouches[0] : e;
      if (evt.target.id === "card1" && evt.target.classList.contains("glass")) {
        const bcr = evt.target.getBoundingClientRect();
        const x = Math.min(1, Math.max(0, (evt.clientX - bcr.left) / bcr.width));
        const y = Math.min(1, Math.max(0, (evt.clientY - bcr.top) / bcr.height));
        const tiltX = 75 / 2 - x * 75;
        const tiltY = y * 75 - 75 / 2;
        evt.target.style.transform = `rotateX(${tiltY}deg) rotateY(${tiltX}deg) `;
      }
    }
  };

  render() {
    return (
      <div
        id="card1"
        className="dragger-element card"
        onMouseDown={(e) => this.props.onStart(e)}
        onTouchStart={this.props.onStart}
        onMouseEnter={this.props.onEnter}
        onMouseLeave={this.props.onLeave}
        onMouseMove={this.onHover}
        onTouchMove={this.onHover}
        onClick={(e) => (e.target.style.transform = "rotateY(30deg)")}
      >
        <div className="layers">
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
        </div>
        {this.props.children}
      </div>
    );
  }
}
export class Card1 extends React.Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
    this.paused = false;
    this.left = 20;
  }
  componentDidMount() {
    if (this.cardRef.current) {
      setInterval(() => {
        if (!this.paused) {
          this.moveCard();
        }
      }, 300);
    }
  }
  delay = (duration) => {
    return new Promise((resolve) => {
      setTimeout(resolve, duration);
    });
  };
  moveCard = () => {
    Promise.resolve()
      .then(() => {
        //  this.cardRef.current.style.transform = `scale(102%)`;
      })
      .then(() => this.delay(150))
      .then(() => {
        //this.cardRef.current.style.transform = `scale(100%)`;
      })
      .then(() => this.delay(150));
  };

  onHover = (e) => {
    if (!this.props.active) {
      console.log("hovering");
      const evt = e.type === "touchmove" ? e.changedTouches[0] : e;
      if (evt.target.id === "card2" && evt.target.classList.contains("glass")) {
        const bcr = evt.target.getBoundingClientRect();
        const x = Math.min(1, Math.max(0, (evt.clientX - bcr.left) / bcr.width));
        const y = Math.min(1, Math.max(0, (evt.clientY - bcr.top) / bcr.height));
        const tiltX = 75 / 2 - x * 75;
        const tiltY = y * 75 - 75 / 2;
        evt.target.style.transform = `rotateX(${tiltY}deg) rotateY(${tiltX}deg) `;
      }
    }
  };
  render() {
    return (
      <div
        id="card2"
        className="dragger-element1 card"
        onClick={(e) => (e.target.style.transform = "rotateY(30deg)")}
        onMouseDown={(e) => this.props.onStart(e)}
        onTouchStart={this.props.onStart}
        onMouseEnter={this.props.onEnter}
        onMouseLeave={this.props.onLeave}
        onMouseMove={this.onHover}
        onTouchMove={this.onHover}
      >
        <div className="layers">
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export class Card2 extends React.Component {
  constructor(props) {
    super(props);
    this.cardRef = React.createRef();
    this.paused = false;
    this.left = 20;
  }
  componentDidMount() {
    if (this.cardRef.current) {
      setInterval(() => {
        if (!this.paused) {
          this.moveCard();
        }
      }, 300);
    }
  }
  delay = (duration) => {
    return new Promise((resolve) => {
      setTimeout(resolve, duration);
    });
  };
  moveCard = () => {
    Promise.resolve()
      .then(() => {
        //this.cardRef.current.style.transform = `scale(102%)`;
      })
      .then(() => this.delay(150))
      .then(() => {
        // this.cardRef.current.style.transform = `scale(100%)`;
      })
      .then(() => this.delay(150));
  };

  onHover = (e) => {
    if (!this.props.active) {
      console.log("hovering");
      const evt = e.type === "touchmove" ? e.changedTouches[0] : e;
      if (evt.target.id === "card3" && evt.target.classList.contains("glass")) {
        const bcr = evt.target.getBoundingClientRect();
        const x = Math.min(1, Math.max(0, (evt.clientX - bcr.left) / bcr.width));
        const y = Math.min(1, Math.max(0, (evt.clientY - bcr.top) / bcr.height));
        const tiltX = 75 / 2 - x * 75;
        const tiltY = y * 75 - 75 / 2;
        evt.target.style.transform = `rotateX(${tiltY}deg) rotateY(${tiltX}deg) `;
      }
    }
  };
  render() {
    return (
      <div
        id="card3"
        className="dragger-element2 card"
        onClick={(e) => (e.target.style.transform = "rotateY(30deg)")}
        onMouseDown={(e) => this.props.onStart(e)}
        onTouchStart={this.props.onStart}
        onMouseEnter={this.props.onEnter}
        onMouseLeave={this.props.onLeave}
        onMouseMove={this.onHover}
        onTouchMove={this.onHover}
      >
        <div className="layers">
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
          <div className="layer"></div>
        </div>
        {this.props.children}
      </div>
    );
  }
}

export class Dragger extends React.Component {
  constructor(props) {
    super(props);
    this.onStart = this.onStart.bind(this);
    this.onMove = this.onMove.bind(this);
    this.onEnd = this.onEnd.bind(this);
    // this.onEnter = this.onEnter.bind(this);
    this.onLeave = this.onLeave.bind(this);
    this.target = null;
    this.initialX = 0;
    this.distX = 0;
    this.distY = 0;
    this.active = false;
    this.initialY = 0;
    this.initialHeight = 0;
    this.initialDescriptionfontSize = 0;
    this.initialTitleFontSize = 0;
  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      const cards = document.querySelector(".dragger-area").children;
      const box = document.querySelector(".who-we-are-block");
      Array.from(cards).forEach((el) => {
        if (el.id !== "who-we-are-block") {
          el.style.left = "unset";
          if (this.context.landscapePhoneQuery) {
            el.style.height = `18vh`;
          } else if (this.context.phoneQuery) {
            el.style.height = `200px`;
          }
        }
      });
    });
  }

  onStart(e, callback) {
    this.target = e.target;

    const children = document.querySelector(".dragger-area").children;
    for (const child of children) {
      if (child.id !== this.target.id) {
        child.style.pointerEvents = "none";
      }
    }
    const box = document.getElementById("who-we-are-block");
    box.style.transform = "none";
    box.style.zIndex = -10;

    box.classList.remove("glass");
    box.querySelector(".layers").style.display = "none";
    const cards = document.querySelector(".dragger-area").children;

    if (!this.initialHeight) {
      this.initialHeight = e.target.getBoundingClientRect().height;
    }

    if (!this.initialDescriptionfontSize) {
      this.initialDescriptionfontSize = window.getComputedStyle(e.target).fontSize.split("p")[0];
    }

    Array.from(cards).forEach((el) => {
      if (el.id !== e.target.id) {
        if (el.id !== "who-we-are-block") {
          el.style.left = "unset";
          el.style.height = this.context.tabletQuery ? `18vh` : `200px`;
          el.style.fontSize = `${+this.initialDescriptionfontSize}px`;
        }
        el.style.transform = "none";
        el.style.zIndex = -10;

        el.classList.remove("glass");
        el.querySelector(".layers").style.display = "none";
      } else {
        el.style.zIndex = 100;
        el.classList.add("glass");
        el.querySelector(".layers").style.display = "block";
      }
    });

    const evt = e.type === "touchstart" ? e.changedTouches[0] : e;
    this.distX = Math.abs(this.target.offsetLeft - evt.clientX);
    this.distY = Math.abs(this.target.offsetTop - evt.clientY);
    this.initialY = evt.clientY;

    this.active = true;
  }

  onEnd(e) {
    this.active = false;
    if (this.target) {
      this.target.style.height = this.context.tabletQuery ? `18vh` : `200px`;

      this.target.querySelector(".card-title").style.fontSize = this.context.tabletQuery ? "20px" : "30px";
      this.target.style.fontSize = `${+this.initialDescriptionfontSize}px`;
    }
    this.initialHeight = 0;
  }

  onMove(e) {
    if (this.target) {
      if (this.active) {
        const evt = e.type === "touchmove" ? e.changedTouches[0] : e;

        if (evt.clientY > this.initialY) {
          if (window.innerHeight - this.target.getBoundingClientRect().bottom < 10) {
            this.onLeave();
            return;
          }
          this.target.style.height = this.context.tabletQuery
            ? `calc(${evt.clientY - this.initialY}px + 18vh)`
            : `${evt.clientY - this.initialY + 200}px`;

          this.target.querySelector(".card-title").style.fontSize = this.context.tabletQuery
            ? `${(evt.clientY - this.initialY) / 20 + 20}px`
            : `${(evt.clientY - this.initialY) / 20 + 32}px`;

          this.target.style.fontSize = `${(evt.clientY - this.initialY) / 19 + +this.initialDescriptionfontSize}px`;
        }

        if (evt.clientX < this.initialX) {
          if (this.target.offsetLeft < 10) {
            return;
          } else {
            this.target.style.left = `${evt.clientX - this.distX}px`;
          }
        } else {
          if (window.innerWidth - this.target.getBoundingClientRect().right < 10) {
            return;
          } else {
            this.target.style.left = `${evt.clientX - this.distX}px`;
          }
        }

        this.initialX = evt.clientX;
      }
    }
  }

  onLeave(e, callback) {
    this.active = false;

    const children = document.querySelector(".dragger-area").children;
    for (const child of children) {
      child.style.pointerEvents = "initial";
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div
        id="dragger-area"
        className="dragger-area"
        onMouseMove={this.onMove}
        onTouchMove={this.onMove}
        onMouseUp={this.onEnd}
        onTouchEnd={this.onEnd}
        role="presentation"
      >
        {children(this.onStart, this.onEnter, this.onLeave, this.active)}
      </div>
    );
  }
}
Dragger.contextType = TextureContext;
