
import { useEffect, useRef, memo } from "react";
import * as THREE from 'three';
import planetGlowTextureTestCastle from '../assets/newScenes/textures/maskazamak.webp';
import { createNoise4D } from 'simplex-noise';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { BokehPass } from 'three/examples/jsm/postprocessing/BokehPass.js'
import { PlanePanel } from "react-babylonjs";
import { isLandscape, isLaptop, isPhone, isPortrait } from "../util/DeviceType";

export let userInteractingWithInputs;
let inputsTimeout;
export function changeuserInteractingWithInputs(value) {
    
    inputsTimeout = setTimeout(() => {
        userInteractingWithInputs = value;
    }, 2000);
  }
export let threeManager;
export default memo (({ antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady, handleMaskFade, textures, ...rest }) => {
    const reactCanvas = useRef(null);

    let setCanvasWidth = 1920 / 1.65;
    let setCanvasHeight = 1080 / 1.65;

    let renderer;
    let scene;
    let camera;
    const mouse = new THREE.Vector2();
    const raycaster = new THREE.Raycaster();
    let width, height;
    let planet;
    let staticPlanet;
    let mX = 0, mY = 0;
    let counterRays = 0;
    let c, w, h, mtn, trackmouse = false;
    let animateRaysIndicator = false;
    let per;
    let composer;
    let bokehPass;
    let mousePos = { x: 0, y: 0 };
    let dancingCube1;
    let dancingCube2;
    let dancingCube3;

    let startMovingPlanet = true;
    let endMovingPlanet = false;
    let isdragging = false;
    let letUserInteractWithPlanet = false;
    let assetsCreated = false;
    let meshBuildings;
    let meshBuildingsArray = [];
    let sphere;
    let videoTexture;
    const geometries = [];
    let pointLight2, pointLight, mat, geo;
    let textureLoaderCastle, geometryCastle, materialCastle, meshCastle;
    let geometryB, materialB, depthTexture;
    let unmounted = true;

    useEffect(() => {
        const { current: canvas } = reactCanvas;

        let conf = {
            fov: 75,
            cameraZ: 75,
            xyCoef: 50,
            zCoef: 10,
            lightIntensity: 0.9,
            ambientColor: 0x000000,
            light1Color: 0x0E09DC,
            light2Color: 0x1CD1E1,
            light3Color: 0x18C02C,
            light4Color: 0xee3bcf,
        };
        let width, height, cx, cy, wWidth, wHeight;
        const TMath = Math;
        let light1, light2, light3, light4;
        let gArray = [];

        let plane;

        const simplex = createNoise4D();
        const mouse = new THREE.Vector2();
        const mousePlane = new THREE.Plane(new THREE.Vector3(0, 0, 1), 0);
        const mousePosition = new THREE.Vector3();
        const raycaster = new THREE.Raycaster();

        const noiseInput = document.getElementById('noiseInput');
        const heightInput = document.getElementById('heightInput');

        sceneOptions = {
            useGeometryUniqueIdsMapSearch: true,
            useMaterialMeshMapSearch: true,
            useClonedMeshMap: true,
        };
        if (!canvas) return;

        unmounted = false;

        // create a new WebGL renderer
        renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(window.innerWidth, window.innerHeight);
        renderer.setClearColor(0x00ff00, 0);

        // create a new scene
        scene = new THREE.Scene();
        scene.background = null;

        // create a new camera
        camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
        camera.position.set(0, 0, 5);

        creatingAssets();

        threeManager = {
            activeScene: scene,
            transitionScene: null
        };

        const resize = () => {
            const width = window.innerWidth;
            const height = window.innerHeight;
            camera.aspect = width / height;
            camera.updateProjectionMatrix();

            if(isPhone()){
                renderer.setSize(window.outerWidth, window.outerHeight);
            }else{
                renderer.setSize(width, height);
            }


            camera.aspect = window.innerWidth / window.innerHeight;
            composer.setSize(window.innerWidth, window.innerHeight);
            bokehPass.width = window.innerWidth;
            bokehPass.height = window.innerHeight;
        };

        if (window) {
            window.addEventListener("resize", resize);
        }

        function creatingAssets() {
    

            init();
    
            function init() {
                // renderer = new THREE.WebGLRenderer({ canvas: document.getElementById(conf.el), antialias: true, alpha: true });
                camera = new THREE.PerspectiveCamera(conf.fov);
                camera.position.z = conf.cameraZ;
    
                updateSize();
                window.addEventListener('resize', updateSize, false);
    
                document.addEventListener('mousemove', ihandleMouseMovesss);
    
                initScene();
                initGui();
                animate();
            }

            function initGui() {
                noiseInput.value = 101 - conf.xyCoef;
                heightInput.value = conf.zCoef * 100 / 25;
    
                noiseInput.addEventListener('input', e => {
                    conf.xyCoef = 101 - noiseInput.value;
                });
                heightInput.addEventListener('input', e => {
                    conf.zCoef = heightInput.value * 25 / 100;
                });
    
                // document.getElementById('trigger').addEventListener('click', e => {
                //     updateLightsColors();
                // });
            }
            function initScene() {
                // scene = new THREE.Scene();
                initLights();
    
                mat = new THREE.MeshLambertMaterial({ color: 0xffffff, side: THREE.DoubleSide });
                // let mat = new THREE.MeshPhongMaterial({ color: 0xffffff });
                // let mat = new THREE.MeshStandardMaterial({ color: 0x808080, roughness: 0.5, metalness: 0.8 });
                geo = new THREE.PlaneGeometry(wWidth * 3, wHeight * 3, wWidth / 2, wHeight / 2);
                plane = new THREE.Mesh(geo, mat);
                scene.add(plane);
    
                plane.rotation.x = -Math.PI / 2 - 0.2;
                plane.position.y = -25;
                camera.position.z = 60;
                // camera.position.z = 360;
                camera.rotation.x = -Math.PI / 18;
    
    
    
                // Load the texture
                // textureLoaderCastle = new THREE.TextureLoader();
                // const textureCastle = textureLoaderCastle.load(planetGlowTextureTestCastle);
    
                // Create a PlaneGeometry with the same aspect ratio as the texture
                geometryCastle = new THREE.PlaneGeometry(35, 10);
                // Create a MeshBasicMaterial with the texture
                materialCastle = new THREE.MeshBasicMaterial({ map: textures.planetGlowTextureTestCastleSolutionsP, transparent: true, opacity: 1 });
                // Create a mesh and add it to the scene
                meshCastle = new THREE.Mesh(geometryCastle, materialCastle);
                meshCastle.scale.set(35, 35, 0);
                meshCastle.position.set(0, 70, -200);
                scene.add(meshCastle);
                geometryB = new THREE.BoxGeometry(1, 1, 1);
                materialB = new THREE.MeshBasicMaterial({ color: 0xff00ff, wireframe: true });
                dancingCube1 = new THREE.Mesh(geometryB, materialB);
                dancingCube1.position.set(10, 0, 0);
                //scene.add(dancingCube1);
                dancingCube2 = new THREE.Mesh(geometryB, materialB);
                dancingCube2.position.set(-45, 2, 5);
                //scene.add(dancingCube2);
                dancingCube3 = new THREE.Mesh(geometryB, materialB);
                dancingCube3.position.set(50, 5, 0);
                //scene.add(dancingCube3);
    
    
                // Create a depth texture to pass to the BokehPass
                depthTexture = new THREE.DepthTexture();
                depthTexture.type = THREE.UnsignedShortType;
                depthTexture.minFilter = THREE.NearestFilter;
                depthTexture.maxFilter = THREE.NearestFilter;
    
                // Create an EffectComposer to manage the post-processing effects
                composer = new EffectComposer(renderer);
                composer.setSize(window.innerWidth, window.innerHeight);
                // Create a RenderPass to render the scene to the composer's render target
                const renderPass = new RenderPass(scene, camera);
                composer.addPass(renderPass);
                // Create a BokehPass and set its focus point
                bokehPass = new BokehPass(scene, camera, {
                    focus: 0,
                    aperture: 0.005,
                    maxBlur: 0.01,
                    width: window.innerWidth,
                    height: window.innerHeight,
                });
                composer.addPass(bokehPass);
            }

            function initLights() {
                const r = 30;
                const y = 10;
                const lightDistance = 500;
    
                light1 = new THREE.PointLight(conf.light1Color, conf.lightIntensity, lightDistance);
                light1.position.set(0, y, r);
                scene.add(light1);
                light2 = new THREE.PointLight(conf.light2Color, conf.lightIntensity, lightDistance);
                light2.position.set(0, -y, -r);
                scene.add(light2);
                light3 = new THREE.PointLight(conf.light3Color, conf.lightIntensity, lightDistance);
                light3.position.set(r, y, 0);
                scene.add(light3);
                light4 = new THREE.PointLight(conf.light4Color, conf.lightIntensity, lightDistance);
                light4.position.set(-r, y, 0);
                scene.add(light4);
    
                handleMaskFade(false);
            }

            function animate() {
                if(renderer){
                    requestAnimationFrame(animate);
                    composer.render();
        
                    animatePlane();
                    animateLights();
        
                    renderer.render(scene, camera);
                }
    
            }

            function animatePlane() {
                gArray = plane.geometry.attributes.position.array;
                const time = Date.now() * 0.0002;
    
                for (let i = 0; i < gArray.length; i += 3) {
                    const noiseValue = simplex(gArray[i] / conf.xyCoef, gArray[i + 1] / conf.xyCoef, time, mousePos.x + mousePos.y) * conf.zCoef;
                    gArray[i + 2] = noiseValue;
                }
                plane.geometry.attributes.position.needsUpdate = true;
                // plane.geometry.computeBoundingSphere();
            }

            function animateLights() {
                const time = Date.now() * 0.001;
                const d = 50;
                light1.position.x = Math.sin(time * 0.1) * d;
                light1.position.z = Math.cos(time * 0.2) * d;
                light2.position.x = Math.cos(time * 0.3) * d;
                light2.position.z = Math.sin(time * 0.4) * d;
                light3.position.x = Math.sin(time * 0.5) * d;
                light3.position.z = Math.sin(time * 0.6) * d;
                light4.position.x = Math.sin(time * 0.7) * d;
                light4.position.z = Math.cos(time * 0.8) * d;
            }
            
            function updateLightsColors() {
                //   conf.light1Color = chroma.random().hex();
                //   conf.light2Color = chroma.random().hex();
                //   conf.light3Color = chroma.random().hex();
                //   conf.light4Color = chroma.random().hex();
                //   light1.color = new THREE.Color(conf.light1Color);
                //   light2.color = new THREE.Color(conf.light2Color);
                //   light3.color = new THREE.Color(conf.light3Color);
                //   light4.color = new THREE.Color(conf.light4Color);
                light1.color = new THREE.Color(0xffffff);
                light2.color = new THREE.Color(0xffffff);
                light3.color = new THREE.Color(0xffffff);
                light4.color = new THREE.Color(0xffffff);
            }

            function updateSize() {
                width = window.innerWidth; cx = width / 2;
                height = window.innerHeight; cy = height / 2;
                if (renderer && camera) {
    
                    if(isPhone()){
                        renderer.setSize(window.outerWidth, window.outerHeight);
                    }else{
                        renderer.setSize(width, height);
                    }
        
                    camera.aspect = width / height;
                    camera.updateProjectionMatrix();
                    const wsize = getRendererSize();
                    wWidth = wsize[0];
                    wHeight = wsize[1];
                }
    
                if (unmounted){
                    window.removeEventListener('resize', updateSize, false);
                }
    
            }

            function getRendererSize() {
                const cam = new THREE.PerspectiveCamera(camera.fov, camera.aspect);
                const vFOV = cam.fov * Math.PI / 180;
                const height = 2 * Math.tan(vFOV / 2) * Math.abs(conf.cameraZ);
                const width = height * cam.aspect;
                return [width, height];
            }
    
    
        }
            
        function ihandleMouseMovesss(e) {
            const v = new THREE.Vector3();
            camera.getWorldDirection(v);
            v.normalize();
            mousePlane.normal = v;
            mouse.x = (e.clientX / width) * 2 - 1;
            mouse.y = - (e.clientY / height) * 2 + 1;
            raycaster.setFromCamera(mouse, camera);
            raycaster.ray.intersectPlane(mousePlane, mousePosition);

            mousePos.x = (e.clientX / width) * 2 - 1;
            mousePos.y = - (e.clientY / height) * 2 + 1;
        }
        
        return () => {
            document.removeEventListener('mousemove', ihandleMouseMovesss);
            if (mat) mat.dispose();
            if (geo) geo.dispose();
            if (depthTexture) depthTexture.dispose();
            if (materialB) materialB.dispose();
            if (geometryB) geometryB.dispose();

            if (scene.current && camera.current && pointLight2.current && pointLight.current) {
                scene.current.dispose();
                camera.current.dispose();
                pointLight.current.dispose();
                pointLight2.current.dispose();
            }
            if (renderer.current) {
                renderer.current.dispose();
            }

            if (window) {
                window.removeEventListener("resize", resize);
            }

            unmounted = true; 

            materialCastle = null; meshCastle = null; geometryCastle = null;
            textureLoaderCastle = null; dancingCube1 = null; dancingCube2 = null;
            mat = null; materialB = null; dancingCube3 = null;
            renderer = null; scene = null; camera = null; pointLight = null; pointLight2 = null;
        };
    }, [antialias, engineOptions, adaptToDeviceRatio, sceneOptions, onRender, onSceneReady]);

    return (
        <div>
            <div id="page" className="enableClicks">
                <div className="margin-N-right">
                    <main role="main" className="inner cover">
                        <form style={{ width: '50%', margin: '10px' }}>
                            <div >
                                <div >
                                    <label htmlFor="noiseInput" className="form-label custom-label-sol">Turbulence</label>
                                    <input type="range" min="1" max="100" className="custom-range custom-input-sol" id="noiseInput" onInput={()=>{userInteractingWithInputs = true; if (inputsTimeout)clearTimeout(inputsTimeout);}}/>
                                </div>
                                <div >
                                    <label htmlFor="heightInput" className="form-label custom-label-sol">Elevation</label>
                                    <input type="range" min="1" max="50" className="custom-range custom-input-sol" id="heightInput" onInput={()=>{userInteractingWithInputs = true; if (inputsTimeout)clearTimeout(inputsTimeout);}}/>
                                </div>
                            </div>

                            {/* <div className="form-group col-md-12">
                                <a href="#" id="trigger" className="btn btn-sm btn-secondary">Random Colors</a>
                            </div> */}

                            <div id="container" touch-action="none"></div>
                        </form>
                    </main>
                </div>
                <div id="background"></div>
                {/* <canvas id="containerShader" style={{ marginTop: '0px', marginLeft: '0px', zIndex: 0, position: 'absolute', backgroundColor: 'transparent' }}></canvas> */}
            </div>

            <canvas ref={reactCanvas} {...rest}>
            </canvas>
        </div>
    );

})
