import React, { useEffect, useRef, useState } from "react";
import ReactAudioPlayer from "react-audio-player";
import baseSound from "../assets/newScenes/audio/base_track.mp3";
import solutionSound from "../assets/newScenes/audio/Stage01Solution.mp3";
import businessSound from "../assets/newScenes/audio/Stage02Biznis.mp3";
import pixelsSound from "../assets/newScenes/audio/Stage05Pixels2Pixels.mp3";
import academySound from "../assets/newScenes/audio/Stage03Academy2.mp3";
import rnd1Sound from "../assets/newScenes/audio/Stage04R&D_1.mp3";
import { useLocation } from "react-router-dom";

export default function TestAudioPlayer({ playSound }) {
  const [activeSound, setActiveSound] = useState(baseSound);
  const location = useLocation();

  const playerRef = useRef();

  function calculateActiveSound(pathname) {
    if (pathname === "/" || pathname === "/selection-page" || pathname === "/who-we-are") {
      setActiveSound(baseSound);
    } else if (pathname === "/solutions") {
      setActiveSound(solutionSound);
    } else if (pathname === "/business-consulting") {
      setActiveSound(businessSound);
    } else if (pathname === "/pixels-2-pixels") {
      setActiveSound(pixelsSound);
    } else if (pathname === "/academy") {
      setActiveSound(academySound);
    } else if (pathname === "/research-and-development") {
      setActiveSound(rnd1Sound);
    }
  }

  useEffect(() => {
    calculateActiveSound(location.pathname);
  }, [location.pathname]);

  return <div></div>;
  // return <div></div><ReactAudioPlayer src={activeSound} autoPlay loop muted={!playSound} ref={playerRef} />;
}
