import { useState, useEffect } from "react";
import "../style/soundButton.scss";
import { isDesktop, isPhone, isPortrait } from "../util/DeviceType";

export default function SoundButton({ handlePlaySound, activate }) {
  const [isPlaying, setIsPlaying] = useState(false);

  //  useEffect(() => {
  //      if (isPlaying) {
  //         setSounds.setSoundsVolume(0.1);
  //      } else {
  //         setSounds.setSoundsVolume(0);
  //      }
  //  }, [isPlaying]);

  useEffect(() => {
    if (activate) setIsPlaying(true);
  }, [activate]);

  const handleClick = () => {
    handlePlaySound();
    setIsPlaying(!isPlaying);
  };

  return (
    // <div style={{
    //     bottom: "6vh",
    //     left: "3vh",
    //     width: "6vh",
    //     height: "6vh",
    //     // backgroundColor: 'red',
    //     position: "absolute",
    //     zIndex: "303",
    //     display: 'flex', alignItems: 'center', justifyContent: 'center'
    // }}
    //     onClick={handleClick}>
    //     <div
    //         style={{
    //             width: 60,
    //             height: 2,
    //             borderRadius: 10,
    //             backgroundColor: isPlaying ? 'blue' : 'gray',
    //             transition: 'background-color 0.2s ease',
    //             display: 'inline-block'
    //         }}
    //     />
    // </div>
    <>
      {/* <div className="soundBtn" onClick={handleClick}>
        <div className="sound-icon">
          <div className="sound-wave">
            <i className={isPlaying ? "bar" : "barStop"}></i>
            <i className={isPlaying ? "bar" : "barStop"}></i>
            <i className={isPlaying ? "bar" : "barStop"}></i>
            <i className={isPlaying ? "bar" : "barStop"}></i>
            <i className={isPlaying ? "bar" : "barStop"}></i>
            <i className={isPlaying ? "bar" : "barStop"}></i>
            {!isPhone() && <i className={isPlaying ? "bar" : "barStop"}></i>}
            {!isPhone() && <i className={isPlaying ? "bar" : "barStop"}></i>}
            {!isPhone() && <i className={isPlaying ? "bar" : "barStop"}></i>}
            {!isPhone() && <i className={isPlaying ? "bar" : "barStop"}></i>}
          </div>
        </div>
      </div> */}
    </>
  );
}
