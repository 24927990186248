import { useMediaQuery } from "@react-hook/media-query";

const devices = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;

export const DeviceType = {
  PHONE: "Phone",
  DESKTOP: "Desktop",
  LAPTOP: "Laptop",
};

export const DeviceOrientation = {
  PORTRAIT: "Portrait",
  LANDSCAPE: "Landscape",
};

export function getDeviceType() {
  if (devices.test(navigator.userAgent)) {
    // true for mobile device
    const isPortrait = window.innerHeight > window.innerWidth;
    return { deviceType: DeviceType.PHONE, deviceOrientation: isPortrait ? DeviceOrientation.PORTRAIT : DeviceOrientation.LANDSCAPE };
  // } else if (/Macintosh|Windows/.test(navigator.userAgent)) {
  //   // true for desktop/laptop device
  //   return { deviceType: DeviceType.DESKTOP, deviceOrientation: DeviceOrientation.LANDSCAPE };
  } else {
    const width = window.innerHeight > window.innerWidth;
    if (window.innerWidth > window.innerHeight) {
      const diagonalSize = Math.sqrt(Math.pow(window.innerWidth, 2) + Math.pow(window.innerHeight, 2));
      if (diagonalSize < 19) {
        return { deviceType: DeviceType.DESKTOP, deviceOrientation: DeviceOrientation.LANDSCAPE };
      } else {
        const aspectRatio = window.innerWidth / window.innerHeight;
        if (aspectRatio > 1.5) {
          return { deviceType: DeviceType.DESKTOP, deviceOrientation: DeviceOrientation.LANDSCAPE };
        } else {
          return { deviceType: DeviceType.LAPTOP, deviceOrientation: DeviceOrientation.LANDSCAPE };
        }
      }
    } else {
      return { deviceType: DeviceType.PHONE, deviceOrientation: DeviceOrientation.PORTRAIT };
    }
  }
}

export function isDesktop() {
  return getDeviceType().deviceType == DeviceType.DESKTOP;
}

export function isPhone() {
  return getDeviceType().deviceType == DeviceType.PHONE;
}

export function isPortrait() {
  return getDeviceType().deviceOrientation == DeviceOrientation.PORTRAIT;
}

export function isLandscape() {
  return getDeviceType().deviceOrientation == DeviceOrientation.LANDSCAPE;
}

export function isLaptop() {
  return getDeviceType().deviceType == DeviceType.LAPTOP;
}
