import React, { useEffect, useRef, useState } from "react";
import Carousel from "./Carousel";
import Logo from "./Logo";
import "../style/ourwork.scss";
import OurWorkContact from "./ourworkcontsct";
import history from "../routes/history";
import { useMediaQuery } from "@react-hook/media-query";
import MobileOurWorkLogos from "./mobileOurworkLogos";
import Mask from "./mask";
import { firstCarouselList, mobileVieoList, secondCarouselList, thirdCarouselList } from "../copy/videotexts";
import MobileCarousel from "./MobileCarousel";

export default function OurWork() {
  const [activeVideo, setActiveVideo] = useState(null);
  const [hideUI, setHideUI] = useState(false);
  const [showUI, setShowUI] = useState(false);
  const pageRef = useRef();
  const phoneQuery = useMediaQuery("only screen and (max-width: 481px)");

  const handleRouteNextPage = () => {
    setHideUI(true);
    setTimeout(() => {
      history.push("/selection-page");
    }, 1200);
  };

  const handleShowUI = () => {
    setShowUI(true);
  };

  const ourPartnersList = [
    { id: 1, url: "/assets/logos/AZModeling200x200.png" },
    { id: 2, url: "/assets/logos/Hint3rland_200x200.png" },
    { id: 3, url: "/assets/logos/Cosmic_Factions_300x200.png", width: 60 },
    { id: 4, url: "/assets/logos/Dorier_300x200.png", width: 70 },
    { id: 5, url: "/assets/logos/PageUP_IT_350x200.png", width: 70 },
    { id: 6, url: "/assets/logos/BLOXICO_200x200.png" },
    { id: 7, url: "/assets/logos/IngressSecurity.png" },
    { id: 8, url: "/assets/logos/InfinityGames_200x200.png" },
    { id: 9, url: "/assets/logos/HTC_Partner_200x200.png" },
    { id: 10, url: "/assets/logos/BritishSerbian_350x200.png", width: 70 },
  ];

  const handleSetActiveVideo = (name) => {
    if (!phoneQuery) {
      if (pageRef.current) {
        const rect = pageRef.current.getBoundingClientRect();

        if (rect.top !== 0) {
          pageRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
    }

    setActiveVideo(name);
  };

  useEffect(() => {
    handleShowUI();
    document.body.style.overflow = "visible";
    // const vignette = document.querySelector(".vignette");
    // vignette.style.display = "none";
    return () => {
      document.body.style.overflow = "hidden";
      //vignette.style.display = "block";
    };
  }, []);

  useEffect(() => {
    if (!phoneQuery) {
      const marquis = document.querySelector("#slider1");
      const marquis1 = document.querySelector("#slider2");
      const marquis2 = document.querySelector("#slider3");
      if (activeVideo) {
        marquis.style.animationPlayState = "paused";
        marquis1.style.animationPlayState = "paused";
        marquis2.style.animationPlayState = "paused";
      } else {
        marquis.style.animationPlayState = "running";
        marquis1.style.animationPlayState = "running";
        marquis2.style.animationPlayState = "running";
      }
    } else {
      const marquis = document.querySelector("#mobile-slider");
      if (activeVideo) {
        marquis.style.animationPlayState = "paused";
      } else {
        marquis.style.animationPlayState = "running";
      }
    }
  }, [activeVideo]);
  return (
    <div className={showUI && !hideUI ? "fadein ourwork" : hideUI ? "fadeout" : ""} id="ourwork">
      <div className="ParallaxVideo">
        <Logo passedFunction={() => handleRouteNextPage()} url={"/assets/sajtlogos/Interactive.png"} />
        <video autoPlay muted loop>
          <source src="https://harmonity.com/videos/MainHeaderVIdeo_VP8.webm" type="video/webm" />
        </video>
      </div>
      <div className="videos-header">
        <h1 className="videos-header-title"> our works</h1>

        <p className="videos-header-description">
          Dive into a realm of boundless possibilities where realism and fantasy
        </p>

        <p className="videos-header-description"> coexist - with our solutions, the only limit is your imagination.</p>
      </div>

      <div className="ParallaxMainContent" ref={pageRef}>
        {!activeVideo && <div className="white-mask"></div>}
        {!phoneQuery && (
          <>
            <Carousel id="slider1" list={firstCarouselList} handleSetActiveVideo={handleSetActiveVideo} />
            <Carousel id="slider2" list={secondCarouselList} handleSetActiveVideo={handleSetActiveVideo} />
            <Carousel id="slider3" list={thirdCarouselList} handleSetActiveVideo={handleSetActiveVideo} />
          </>
        )}
        {phoneQuery && (
          <MobileCarousel id="mobile-slider" list={mobileVieoList} handleSetActiveVideo={handleSetActiveVideo} />
        )}
        {activeVideo && (
          <div
            className="video-item-page"
            onClick={(e) => {
              setActiveVideo(null);
            }}
          >
            <div className="video-block" name="video-block">
              <div className="video-card">
                <video className="single-video-item" muted loop autoPlay>
                  <source src={activeVideo.url} type="video/webm" />
                </video>
              </div>
              <div className="video-info">
                <h3 className="video-title">{activeVideo.name}</h3>
                {activeVideo.text.map((paragraf) => {
                  return <p className="video-description"> {paragraf}</p>;
                })}
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="our-partners">
        <div className="our-partners-header">
          <h1 className="our-partners-title">our partners</h1>

          <p className="videos-header-description">
            We believe in the power of partnerships, so we work closely with industry leaders
          </p>
          <p className="videos-header-description">
            to ensure we're up-to-date with the latest trends and technologies.
          </p>
        </div>
        {!phoneQuery && (
          <ul className="logos-list">
            {ourPartnersList.map((item) => {
              return (
                <li className="logo-wrapper" key={item.id}>
                  <img className="logo-img" src={item.url} style={{ width: item.width ? `${item.width}%` : "50%" }} />
                </li>
              );
            })}
          </ul>
        )}
        {phoneQuery && <MobileOurWorkLogos />}
      </div>
      <OurWorkContact />
    </div>
  );
}
