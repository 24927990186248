import React from "react";
import "../style/mailsent.scss";

export default function MailSent({ removePopup, error }) {
  return (
    <div className="popup">
      <div className="popup-message">
        {!error && (
          <>
            <p>Message succesfully sent</p>
            <p>Expect your answer soon</p>
          </>
        )}
        {error && (
          <>
            <p className="server-error">Server error</p>
            <p className="server-error">Try again</p>
          </>
        )}
      </div>
      <button type="button" onClick={removePopup}>
        Confirm
      </button>
    </div>
  );
}
