import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const ourPartnersList1 = [
  { id: 1, url: "/assets/logos/AZModeling200x200.png" },
  { id: 2, url: "/assets/logos/BLOXICO_200x200.png" },
];
const ourPartnersList2 = [
  { id: 1, url: "/assets/logos/Hint3rland_200x200.png" },
  { id: 2, url: "/assets/logos/IngressSecurity.png" },
];
const ourPartnersList3 = [
  { id: 1, url: "/assets/logos/Cosmic_Factions_300x200.png", width: 60 },
  { id: 2, url: "/assets/logos/InfinityGames_200x200.png" },
];
const ourPartnersList4 = [
  { id: 1, url: "/assets/logos/HTC_Partner_200x200.png" },
  { id: 2, url: "/assets/logos/Dorier_300x200.png", width: 70 },
];
const ourPartnersList5 = [
  { id: 1, url: "/assets/logos/PageUP_IT_350x200.png", width: 70 },
  { id: 2, url: "/assets/logos/BritishSerbian_350x200.png", width: 70 },
];

export default function MobileOurWorkLogos() {
  return (
    <div className="mobile-ourwork-logos">
      <Carousel showStatus={false} showArrows={false} showThumbs={false}>
        <div>
          <ul className="mobile-ourwork-logos-list">
            {ourPartnersList1.map((logo) => {
              return (
                <li key={logo.id} className="mobile-ourwork-logo">
                  <img src={logo.url} className="mobile-ourwork-logo-image" />
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <ul className="mobile-ourwork-logos-list">
            {ourPartnersList2.map((logo) => {
              return (
                <li key={logo.id} className="mobile-ourwork-logo">
                  <img src={logo.url} className="mobile-ourwork-logo-image" />
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <ul className="mobile-ourwork-logos-list">
            {ourPartnersList3.map((logo) => {
              return (
                <li key={logo.id} className="mobile-ourwork-logo">
                  <img src={logo.url} className="mobile-ourwork-logo-image" />
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <ul className="mobile-ourwork-logos-list">
            {ourPartnersList4.map((logo) => {
              return (
                <li key={logo.id} className="mobile-ourwork-logo">
                  <img src={logo.url} className="mobile-ourwork-logo-image" />
                </li>
              );
            })}
          </ul>
        </div>
        <div>
          <ul className="mobile-ourwork-logos-list">
            {ourPartnersList5.map((logo) => {
              return (
                <li key={logo.id} className="mobile-ourwork-logo">
                  <img src={logo.url} style={{ width: "100%" }} className="mobile-ourwork-logo-image" />
                </li>
              );
            })}
          </ul>
        </div>
      </Carousel>
    </div>
  );
}
