import React from "react";
import "../style/carousel.scss";

export default function Carousel({ id, list, handleSetActiveVideo }) {
  return (
    <div className="marquee">
      <ul className="marquee-content" id={id}>
        {list.map((item) => {
          return (
            <li onClick={() => handleSetActiveVideo(item)} key={item.id}>
              <img src={item.previewUrl} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}
