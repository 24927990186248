import { useEffect, useRef, useState, memo } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { gsap, Power1, Power2, Linear } from "gsap";
import fbxPathBuilding from "../assets/newScenes/buildings.fbx";
import planetTextureTest from "../assets/newScenes/textures/planetar-d.webp";
import planetGlowTextureTestAtlas from "../assets/newScenes/textures/earthGlow.webp";
import sceneBack from "../assets/newScenes/textures/pixels2pixels.webp";
import sceneBackPORTRAIT from "../assets/newScenes/textures/mobile/pixeliPortrait.webp";
import sceneBackLANDSCAPE from "../assets/newScenes/textures/mobile/pixeliLandscape.webp";
// import moonBack from '../assets/newScenes/textures/2k_moon.jpg';
import { FlyControls } from "three/examples/jsm/controls/FlyControls";
import { Line } from "react-babylonjs";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import * as SkeletonUtils from "three/addons/utils/SkeletonUtils.js";
import { womanMaskMesh } from "../components/Pixels2PixelsFrontThree";
import "../style/business-consulting.scss";
import "../style/newPixels.scss";
import { SmartArrayNoDuplicate } from "@babylonjs/core";
import { isDesktop, isPhone, isPortrait } from "../util/DeviceType";
import history from "../routes/history";

export let threeManager;
export default memo(
  ({
    antialias,
    engineOptions,
    adaptToDeviceRatio,
    sceneOptions,
    onRender,
    onSceneReady,
    handleMaskFade,
    textures,
    sliderStatusValue,
    ...rest
  }) => {
    const reactCanvas = useRef(null);

    let texts = {
      1: `Pixels2Pixels \u00A0 is \u00A0 Harmonity \u00A0 Group’s \u00A0 creative \u00A0 part, \u00A0 which \u00A0 creates \u00A0 supreme \u00A0\n visual \u00A0 content \u00A0 and \u00A0 digital \u00A0 products,\u00A0 helping\u00A0 you\u00A0 communicate\u00A0 your\u00A0 brand\u00A0 \nand\u00A0 work\u00A0 to\u00A0 clients.`,
      2: `We\u00A0 provide\u00A0 comprehensive\u00A0 digital\u00A0 solutions,\u00A0 including \u00A0mixed\u00A0 reality,\u00A0 \n3D\u00A0 visualization,\u00A0 3D\u00A0 animation,\u00A0 graphic\u00A0 design,\u00A0 \nand\u00A0 digital\u00A0 marketing\u00A0 services.`,
      3: `Be \u00A0sure \u00A0you\u00A0 are\u00A0 one \u00A0step\u00A0 ahead\u00A0 of \u00A0everyone.\u00A0 \nDiscover\u00A0 how\u00A0 we\u00A0 can \u00A0give\u00A0 you\u00A0 a\u00A0 competitive\u00A0 edge.`,
    };

    const [text1, setText1] = useState(texts[1]);
    const [text2, setText2] = useState(texts[2]);
    const [text3, setText3] = useState(texts[3]);
    const [isMoved1, setIsMoved1] = useState(null);
    const [isMoved2, setIsMoved2] = useState(null);
    const [isMoved3, setIsMoved3] = useState(null);
    const [isText1Animated, setText1Animated] = useState(false);
    const [isText2Animated, setText2Animated] = useState(false);
    const [isText3Animated, setText3Animated] = useState(false);

    const [cameras, setCamera] = useState(null);

    let text2AnimationCheck;
    let text3AnimationCheck;
    let disableScrolling;

    let renderer;
    let scene;
    let camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    camera.position.set(0, 0, 710);
    let width, height, ambientL;
    let planet;
    let staticPlanet;
    let material;
    let materialStaticPlanet;
    let earthGlow;
    let cameraCtrl;
    let sphere;
    let wheelTrigered = false;
    let startScrollngScreen = false;
    let directionOfTheWheel;
    let mixer;
    let diverRoot;
    let cameraTargetSetUp;
    let stateOfTheStage = 0;
    let startY;
    let clock = new THREE.Clock();
    let geometries = [];
    let textureAtlas1;
    let geometryGlow1;
    let materialGlow1;
    let backgroundMesh;
    let pointLight, pointLight2;
    let textureAtlas1PORTRAIT,
      geometryGlow1PORTRAIT,
      materialGlow1PORTRAIT,
      backgroundMeshPORTRAIT,
      textureAtlas1LANDSCAPE,
      geometryGlow1LANDSCAPE,
      materialGlow1LANDSCAPE,
      backgroundMeshLANDSCAPE;

    useEffect(() => {
      let step = -1100;
      if (cameras) {
        if (sliderStatusValue == 0 || sliderStatusValue == 16.66) {
          womanMaskMesh.tween = gsap.to(womanMaskMesh.position, 1, { y: -90, ease: Linear.easeNone });
          cameras.tween = gsap.to(cameras.position, 1, {
            y: 0,
            ease: Linear.easeNone,
          });
        } else if (sliderStatusValue == 33.33 || sliderStatusValue == 49.99) {
          womanMaskMesh.tween = gsap.to(womanMaskMesh.position, 1, { y: -90, ease: Linear.easeNone });
          cameras.tween = gsap.to(cameras.position, 1, {
            y: step,
            ease: Linear.easeNone,
          });
        } else if (sliderStatusValue == 66.65 || sliderStatusValue == 83.31) {
          // cameras.position.y = step;
          womanMaskMesh.tween = gsap.to(womanMaskMesh.position, 1, { y: 60, ease: Linear.easeNone });
          cameras.tween = gsap.to(cameras.position, 1, {
            y: step * 2,
            ease: Linear.easeNone,
          });
        } else if (sliderStatusValue == 100) {
          cameras.position.y = step * 2;
        }
      }
      return () => {
        if (cameras) gsap.killTweensOf(cameras.position);
        if (womanMaskMesh) gsap.killTweensOf(womanMaskMesh.position);
      };
    }, [sliderStatusValue]);

    useEffect(() => {
      const { current: canvas } = reactCanvas;

      sceneOptions = {
        useGeometryUniqueIdsMapSearch: true,
        useMaterialMeshMapSearch: true,
        useClonedMeshMap: true,
      };
      if (!canvas) return;

      // create a new WebGL renderer
      renderer = new THREE.WebGLRenderer({ canvas, antialias, ...engineOptions });
      renderer.setPixelRatio(window.devicePixelRatio);
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setClearColor(0x00ff00, 0);
      renderer.alpha = true;
      renderer.antialias = true;

      // create a new scene
      scene = new THREE.Scene();
      // scene.background = null;

      // create a new camera

      // add objects to the scene

      creatingAssets();
      cameraConfig();
      setCamera(camera);
      // const flyControls = new FlyControls(camera, renderer.domElement);
      // flyControls.dragToLook = false;
      // flyControls.autoForward = false;

      // const flyControls = new FlyControls(camera, renderer.domElement);
      // flyControls.movementSpeed = 100.005;
      // flyControls.dragToLook = true;
      // flyControls.autoForward = true;

      threeManager = {
        activeScene: scene,
        transitionScene: null,
      };

      function render() {
        if (renderer) renderer.render(scene, camera);
      }

      function wheelOn(event) {
        if (!wheelTrigered && camera) {
          wheelTrigered = true;
          if (event.type === "wheel") {
            directionOfTheWheel = event.deltaY > 0 ? "down" : "up";
          } else if (event.type === "touchmove") {
            const touch = event.touches[0];
            startY = startY || touch.pageY;
            const deltaY = touch.pageY - startY;
            directionOfTheWheel = deltaY > 0 ? "down" : "up";
          }
          if (directionOfTheWheel === "down" && camera.position.y === -2200) {
            if (event.type === "wheel") {
              window.removeEventListener("wheel", wheelOn, { passive: false });
            } else if (event.type === "touchmove") {
              window.removeEventListener("touchmove", wheelOn, { passive: false });
            }
            history.push("/selection-page");
          }
        }
      }

      if (isDesktop()) {
        window.addEventListener("wheel", wheelOn, { passive: false });
      } else {
        window.addEventListener("touchmove", wheelOn, { passive: false });
      }

      function animate() {
        // flyControls.update(133.2);
        if (renderer) {
          requestAnimationFrame(animate);
          render();
          const delta = clock.getDelta();
          if (mixer) mixer.update(delta);

          // if(diverRoot && !cameraTargetSetUp){
          //     // cameraTargetSetUp = true;
          //     if (cameraCtrl) cameraCtrl.update()
          //     cameraCtrl.enabled = true;
          //     cameraCtrl.target.copy(new THREE.Vector3(diverRoot.position.x, diverRoot.position.y+300,diverRoot.position.z) );
          // }
        }

        // flyControls.update();
      }

      const animateLetters = (element, delay) => {
        // Get the text content of the element and split it into an array of characters
        if (element) {
          const text = element.textContent;
          const letters = text.split("");

          // Replace the text content of the element with an array of span elements, one for each letter
          const html = letters
            .map((letter) => (letter === "\n" ? `<br />` : `<span class="letter">${letter}</span>`))
            .join("");
          element.innerHTML = html;

          // Loop through the span elements and add the "animated" class with a delay to each one
          const letterElements = element.querySelectorAll(".letter");
          letterElements.forEach((letter, index) => {
            letter.style.animationDelay = `${index * delay}s`;
            letter.classList.add("animated");
          });
        }
      };

      animate();

      const resize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();

        if (isPhone()) {
          renderer.setSize(window.outerWidth, window.outerHeight);
        } else {
          renderer.setSize(width, height);
        }

        if (backgroundMesh && backgroundMeshLANDSCAPE && backgroundMeshPORTRAIT) {
          const meshPC = scene.getObjectByName("backPC");
          const meshPortrait = scene.getObjectByName("backPortrait");
          const meshLandscape = scene.getObjectByName("backLandscape");

          if (isPhone()) {
            if (isPortrait()) {
              if (meshLandscape) scene.remove(backgroundMeshLANDSCAPE);
              if (meshPC) scene.remove(backgroundMesh);
              scene.add(backgroundMeshPORTRAIT);
            } else {
              if (meshPC) scene.remove(backgroundMesh);
              if (meshPortrait) scene.remove(backgroundMeshPORTRAIT);
              scene.add(backgroundMeshLANDSCAPE);
            }
          } else {
            if (meshLandscape) scene.remove(backgroundMeshLANDSCAPE);
            if (meshPortrait) scene.remove(backgroundMeshPORTRAIT);
            if (window.innerWidth > 2000) {
              backgroundMesh.scale.set(1000, 1000, 0);
            } else if (window.innerWidth > 1800) {
              backgroundMesh.scale.set(1100, 1000, 0);
            } else if (window.innerWidth > 1500) {
              backgroundMesh.scale.set(1150, 1000, 0);
            } else {
              backgroundMesh.scale.set(1200, 1000, 0);
            }
            scene.add(backgroundMesh);
          }
        }
      };

      if (window) {
        window.addEventListener("resize", resize);
      }

      function creatingAssets() {
        // const textureAtlas2 = new THREE.TextureLoader().load(moonBack);
        // const geometrySs = new THREE.SphereGeometry(150, 320, 160);

        // const materiaSsl = new THREE.MeshBasicMaterial({ map: textureAtlas2 });
        // let ccsscc = new THREE.MeshNormalMaterial({
        //     transparent: true,
        //     opacity: 0.3
        // })

        // const sphereSs = new THREE.Mesh(geometrySs, ccsscc);
        // sphereSs.scale.set(0.5, 0.5, 0.5);
        // sphereSs.position.set(-480, 250, 0);
        // scene.add(sphereSs);

        // const sphereSs2 = new THREE.Mesh(geometrySs, ccsscc);
        // sphereSs2.scale.set(0.3, 0.3, 0.3);
        // sphereSs2.position.set(380, 250, 0);
        // scene.add(sphereSs2);

        // let ccsscc2 = new THREE.MeshNormalMaterial({
        //     transparent: true,
        //     opacity: 0.8
        // })
        // const sphereSs3 = new THREE.Mesh(geometrySs, ccsscc2);
        // sphereSs3.scale.set(0.2, 0.2, 0.2);
        // sphereSs3.position.set(310, 250, 30);
        // scene.add(sphereSs3);

        // textureAtlas1 = new THREE.TextureLoader().load(textures.texturePixelsBack);
        geometryGlow1 = new THREE.PlaneGeometry(2.048, 3.456);
        materialGlow1 = new THREE.MeshBasicMaterial({ map: textures.texturePixelsBack });
        backgroundMesh = new THREE.Mesh(geometryGlow1, materialGlow1);
        backgroundMesh.name = "backPC";

        // textureAtlas1PORTRAIT = new THREE.TextureLoader().load(textures.texturePixelsPortrait);
        geometryGlow1PORTRAIT = new THREE.PlaneGeometry(1.28, 8.533);
        materialGlow1PORTRAIT = new THREE.MeshBasicMaterial({ map: textures.texturePixelsPortrait });
        backgroundMeshPORTRAIT = new THREE.Mesh(geometryGlow1PORTRAIT, materialGlow1PORTRAIT);
        backgroundMeshPORTRAIT.name = "backPortrait";
        backgroundMeshPORTRAIT.scale.set(460, 460, 0);
        backgroundMeshPORTRAIT.position.set(0, -1050, -20);

        // textureAtlas1LANDSCAPE = new THREE.TextureLoader().load(textures.texturePixelsLandscape);
        geometryGlow1LANDSCAPE = new THREE.PlaneGeometry(3.0, 4.05);
        materialGlow1LANDSCAPE = new THREE.MeshBasicMaterial({ map: textures.texturePixelsLandscape });
        backgroundMeshLANDSCAPE = new THREE.Mesh(geometryGlow1LANDSCAPE, materialGlow1LANDSCAPE);
        backgroundMeshLANDSCAPE.scale.set(890, 890, 0);
        backgroundMeshLANDSCAPE.position.set(0, -1050, -20);
        backgroundMeshLANDSCAPE.name = "backLandscape";

        const meshPC = scene.getObjectByName("backPC");
        const meshPortrait = scene.getObjectByName("backPortrait");
        const meshLandscape = scene.getObjectByName("backLandscape");
        if (isPhone()) {
          if (isPortrait()) {
            if (meshLandscape) scene.remove(backgroundMeshLANDSCAPE);
            if (meshPC) scene.remove(backgroundMesh);
            scene.add(backgroundMeshPORTRAIT);
          } else {
            if (meshPC) scene.remove(backgroundMesh);
            if (meshPortrait) scene.remove(backgroundMeshPORTRAIT);
            scene.add(backgroundMeshLANDSCAPE);
          }
        } else {
          if (meshLandscape) scene.remove(backgroundMeshLANDSCAPE);
          if (meshPortrait) scene.remove(backgroundMeshPORTRAIT);
          scene.add(backgroundMesh);
          if (window.innerWidth > 2000) {
            backgroundMesh.scale.set(1000, 1000, 0);
          } else if (window.innerWidth > 1800) {
            backgroundMesh.scale.set(1000, 1000, 0);
          } else if (window.innerWidth > 1500) {
            backgroundMesh.scale.set(1150, 1150, 0);
          } else {
            backgroundMesh.scale.set(1200, 1200, 0);
          }
          backgroundMesh.position.set(0, -1150, -20);
        }
      }

      function cameraConfig() {
        cameraCtrl = new OrbitControls(camera, renderer.domElement);
        cameraCtrl.enableDamping = false;
        cameraCtrl.dampingFactor = 0.1;
        cameraCtrl.autoRotate = false;
        cameraCtrl.autoRotateSpeed = 0.5;
        cameraCtrl.rotateSpeed = 0.5;
        cameraCtrl.enableZoom = false;
        cameraCtrl.enableRotate = true;
        cameraCtrl.enablePan = true;
        cameraCtrl.enabled = false;
        // cameraCtrl.minDistance = 10;
        // cameraCtrl.maxDistance = 100;

        //if (cameraCtrl) cameraCtrl.update();
        if (renderer) {
          renderer.render(scene, camera);
        }

        pointLight = new THREE.PointLight(0xffffff);
        pointLight.position.set(0, 0, 1000);
        scene.add(pointLight);

        pointLight2 = new THREE.PointLight(0xffffff);
        pointLight2.position.set(0, 0, -1000);
        scene.add(pointLight2);

        ambientL = new THREE.AmbientLight(0xffffff);
        scene.add(ambientL);
        if (renderer) renderer.render(scene, camera);
      }
      // disableScrolling = true;
      // setTimeout(() => {
      //     setText1Animated(true);
      //     const element = document.querySelector(".pixelsNewN-paragraph");
      //     animateLetters(element, 0.03);
      //     setTimeout(() => {
      //         disableScrolling = false;
      //     }, 6000);
      // }, 3500);

      return () => {
        const meshPC = scene.getObjectByName("backPC");
        const meshPortrait = scene.getObjectByName("backPortrait");
        const meshLandscape = scene.getObjectByName("backLandscape");
        if (meshPortrait) scene.remove(backgroundMeshPORTRAIT);
        if (meshLandscape) scene.remove(backgroundMeshLANDSCAPE);
        if (meshPC) scene.remove(backgroundMesh);

        scene.remove(pointLight);
        scene.remove(pointLight2);
        scene.remove(ambientL);

        if (geometryGlow1) geometryGlow1.dispose();
        if (materialGlow1) materialGlow1.dispose();
        if (textureAtlas1) textureAtlas1.dispose();
        if (materialGlow1PORTRAIT) materialGlow1PORTRAIT.dispose();
        if (materialGlow1LANDSCAPE) materialGlow1LANDSCAPE.dispose();

        if (scene.current && camera.current) {
          scene.current.dispose();
          camera.current.dispose();
        }
        if (renderer.current) {
          renderer.current.dispose();
        }

        if (isDesktop()) {
          window.removeEventListener("wheel", wheelOn, { passive: false });
        } else {
          window.removeEventListener("touchmove", wheelOn, { passive: false });
        }

        if (window) {
          window.removeEventListener("resize", resize);
        }

        renderer = null;
        scene = null;
        camera = null;
        width = null;
        height = null;
        ambientL = null;
        planet = null;
        staticPlanet = null;
        material = null;
        materialStaticPlanet = null;
        earthGlow = null;
        cameraCtrl = null;
        sphere = null;
        wheelTrigered = null;
        startScrollngScreen = null;
        directionOfTheWheel = null;
        mixer = null;
        diverRoot = null;
        cameraTargetSetUp = null;
        stateOfTheStage = null;
        startY = null;
        clock = null;
        geometries = null;
        textureAtlas1 = null;
        geometryGlow1 = null;
        materialGlow1 = null;
        backgroundMesh = null;
        pointLight = null;
        pointLight2 = null;
        textureAtlas1PORTRAIT = null;
        geometryGlow1PORTRAIT = null;
        materialGlow1PORTRAIT = null;
        backgroundMeshPORTRAIT = null;
        textureAtlas1LANDSCAPE = null;
        geometryGlow1LANDSCAPE = null;
        materialGlow1LANDSCAPE = null;

        textureAtlas1PORTRAIT = null;
        geometryGlow1PORTRAIT = null;
        materialGlow1PORTRAIT = null;
        backgroundMeshPORTRAIT = null;
        textureAtlas1LANDSCAPE = null;
        geometryGlow1LANDSCAPE = null;
        materialGlow1LANDSCAPE = null;
        backgroundMeshLANDSCAPE = null;

        reactCanvas.current = null;

        // Set the mesh and material to null to release memory
        backgroundMesh = null;
        materialGlow1 = null;
        renderer = null;
        scene = null;
        camera = null;
        pointLight = null;
        pointLight2 = null;
      };
    }, []);

    return (
      <div>
        <canvas ref={reactCanvas} {...rest}></canvas>
        {/* <div>
                <div className="pixelsNewN-texts" style={{
                    transition: 'transform 1.1s ease-in-out',
                    transform: isMoved1 ? 'translateY(-1000px)' : 'none',
                }}>
                    <div className="pixelsNewN-box">
                        <p className="pixelsNewN-paragraph">
                            {text1.split("").map((char, index) => (
                                <span key={index} className={`letter ${isText1Animated ? 'animated' : ''}`}>{char}</span>
                            ))}
                        </p>
                    </div>
                </div>

                <div className="pixelsNewN-texts2" style={{
                    transition: 'transform 1.1s ease-in-out',
                    transform: isMoved2 ? 'translateY(-1000px)' : isMoved2 === false ? 'none' : 'translateY(1000px)',
                }}>
                    <div className="pixelsNewN-box2">
                        <p className="pixelsNewN-paragraph2">
                            {text2.split("").map((char, index) => (
                                <span key={index} className={`letter ${isText2Animated ? 'animated' : ''}`}>{char}</span>
                            ))}
                        </p>
                    </div>
                </div>

                <div className="pixelsNewN-texts3" style={{
                    transition: 'transform 1.1s ease-in-out',
                    transform: isMoved3 ? 'translateY(1000px)' : 'none',
                }}>
                    <div className="pixelsNewN-box3">
                        <p className="pixelsNewN-paragraph3">
                            {text3.split("").map((char, index) => (
                                <span key={index} className={`letter ${isText3Animated ? 'animated' : ''}`}>{char}</span>
                            ))}
                        </p>
                    </div>
                </div>



            </div> */}
      </div>
    );
  }
);
