import React, { createContext, useState } from "react";
import * as PIXI from "pixi.js";
import { useMediaQuery } from "@react-hook/media-query";

export const TextureContext = createContext({
  textures: [],
  texturesSelectionPage: [],
  load: () => {},
  loadSelectionAssets: () => {},
  phoneQuery: "",
});

const TextureProvider = ({ children }) => {
  const [textures, setTextures] = useState([]);
  const [texturesSelectionPage, setTexturesSelectionPage] = useState([]);
  const phoneQuery = useMediaQuery("only screen and (max-width: 481px)");
  const landscapePhoneQuery = useMediaQuery("only screen and (max-height: 480px)");
  const tabletQuery = useMediaQuery("only screen and (max-width: 600px)");

  const load = async () => {
    const kocka = await PIXI.Assets.load("/assets/kockaHomePage/kockahomepage-0.json");
    let a = [];

    kocka.linkedSheets.forEach((element) => {
      a = [...a, ...Object.values(element.textures)];
    });

    setTextures([...Object.values(kocka.textures), ...a]);
  };
  const loadSelectionAssets = async () => {
    const kocka = await PIXI.Assets.load("/assets/kockaSelectionPage/kockaselectionpage-0.json");
    let a = [];

    kocka.linkedSheets.forEach((element) => {
      a = [...a, ...Object.values(element.textures)];
    });

    setTexturesSelectionPage([...Object.values(kocka.textures), ...a]);
  };

  return (
    <TextureContext.Provider
      value={{
        //  textures,
        //   load,
        // texturesSelectionPage,
        //loadSelectionAssets,
        phoneQuery,
        tabletQuery,

        landscapePhoneQuery,
      }}
    >
      {children}
    </TextureContext.Provider>
  );
};

// async function loadAssets() {
//   const kocka = await PIXI.Assets.load("/assets/kockaHomePage/kockahomepage-0.json");
//   let a = [];

//   kocka.linkedSheets.forEach((element) => {
//     a = [...a, ...Object.values(element.textures)];
//   });

//   const textures = [...Object.values(kocka.textures), ...a];
// }

export default TextureProvider;

export const OnceContext = React.createContext({ load: "", val: "" });
